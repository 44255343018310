import { Divider, makeStyles } from '@material-ui/core';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import React from 'react';
import LoadDetail from '../LoadDetail';
import LoadDocument from '../LoadDocument';
import LoadStatusTimeline from '../LoadStatusTimeline';

type Props = {
  load: OrderLeg;
};

const TrackerLoadDetail: React.FC<Props> = ({ load }) => {
  const classes = useStyles();

  return (
    <div className={classes.loadDetailArea}>
      <LoadStatusTimeline load={load} />
      <Divider />
      <LoadDetail load={load} />
      <Divider />
      <LoadDocument load={load} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadArea: {
    background: 'white',
    position: 'absolute',
    top: '70%',
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      flex: 1,
      height: '100%',
      overflow: 'auto',
      maxWidth: 480,
      minWidth: 480,
      position: 'static',
    },
  },
  loadDetailArea: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
  refresh: {
    background: theme.palette.grey[100],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}));

export default TrackerLoadDetail;
