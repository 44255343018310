import { LOAD_STOP_TYPE } from 'doc-mate-store/lib/constants/load';
import { usaStates } from 'typed-usa-states';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import {
  AccessorialFormData,
  BillingFormData,
  LoadStopFormData,
  ManifestFormData,
  OrderFormData,
  OrderLegFormData,
} from './types';

export const dataEntryFields = [
  'Broker',
  'Seal Number',
  'Trip Number',
  'PO # Shipper',
  'PO # Consignee',
  'Partner Reference #',
  'Other',
];

export const initialManifestData: ManifestFormData = {
  billToOptions: '',
  billToThirdParty: '',
  revenueTerminal: null,
  billAs: '',
  billAsUnit: 'lb',
  billRevenue: '',
  as400ManifestNumber: '',
};

export const initialBillingState: { [id: string]: BillingFormData } = {
  '1': {
    idW: '1',
    legW: '1',
    percentage: '',
    notes: '',
  },
};

export const initialOrderLegsState: { [id: string]: OrderLegFormData } = {
  1: {
    idW: '1',
    orderW: '1',
    legNumber: 1,
    pickupInstructionsInherit: false,
    pickupInstructionsText: '',
    pickupHasNoDamages: true,
    dropoffInstructionsInherit: false,
    dropoffInstructionsText: '',
    dropoffHasNoDamages: true,
    driver: '',
  },
};

export const initialLoadStopState: { [id: string]: LoadStopFormData } = {
  1: {
    idW: '1',
    isCustomer: true,
    stopType: LOAD_STOP_TYPE.PICKUP,
    stopNumber: 1,
    pickupNumber: '',
    pickupWindow: {
      bounds: '[)',
      lower: null,
      upper: null,
    },
    dropoffWindow: {
      bounds: '[)',
      lower: null,
      upper: null,
    },
    appointmentRequired: false,
    notes: '',
    contactName: '',
    phoneNumber: '',
    street1: '',
    street2: '',
    city: '',
    state: null,
    zipCode: '',
    canSelfLoad: false,
    canSelfUnload: false,
  },
  2: {
    idW: '2',
    stopType: LOAD_STOP_TYPE.DROPOFF,
    isCustomer: true,
    stopNumber: 2,
    pickupNumber: '',
    pickupWindow: {
      bounds: '[)',
      lower: null,
      upper: null,
    },
    dropoffWindow: {
      bounds: '[)',
      lower: null,
      upper: null,
    },
    appointmentRequired: false,
    notes: '',
    contactName: '',
    phoneNumber: '',
    street1: '',
    street2: '',
    city: '',
    state: null,
    zipCode: '',
    canSelfLoad: false,
    canSelfUnload: false,
  },
};

export const initialOrderState: { [id: string]: OrderFormData } = {
  '1': {
    idW: '1',
    shipperW: '1',
    consigneeW: '2',
    loadType: 'Truckload',
    loadDescription: '',
    loadNotes: '',
    loadQuantity: '',
    loadQuantityUnit: 'pallet',
    loadWeight: '',
    loadWeightUnit: 'lb',
    estimatedTravelDistance: '',
    estimatedTravelDistanceUnit: 'mi',
    sealNumber: '',
    orderNumber: '1',
    trailerNumber: '',
    pickupNumber: '',
    customerOrderNumber: '',
    tripNumber: '',
    broker: '',
    mode: 'Drop and Hook',
    expedite: false,
    specialEquipment: '',
    loadedOnSameTrailer: false,
    dropoffNumber: '',
    other: '',
    puNumber: '',
    stops: [
      {
        idW: '1',
        stopType: LOAD_STOP_TYPE.PICKUP,
        stopNumber: 1,
        pickupNumber: '',
        isCustomer: true,
        appointmentRequired: false,
        notes: '',
        contactName: '',
        phoneNumber: '',
        street1: '',
        street2: '',
        city: '',
        state: null,
        zipCode: '',
        canSelfLoad: false,
        canSelfUnload: false,
        pickupWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
        dropoffWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
      },
      {
        idW: '2',
        stopType: LOAD_STOP_TYPE.DROPOFF,
        stopNumber: 2,
        isCustomer: true,
        pickupNumber: '',
        appointmentRequired: false,
        notes: '',
        contactName: '',
        phoneNumber: '',
        street1: '',
        street2: '',
        city: '',
        state: null,
        zipCode: '',
        canSelfLoad: false,
        canSelfUnload: false,
        pickupWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
        dropoffWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
      },
    ],
  },
};

export const initialOrderStateBroker: { [id: string]: OrderFormData } = {
  '1': {
    idW: '1',
    shipperW: '1',
    consigneeW: '2',
    loadType: 'Truckload',
    loadDescription: '',
    loadNotes: '',
    loadQuantity: '',
    loadQuantityUnit: 'pallet',
    loadWeight: '',
    loadWeightUnit: 'lb',
    estimatedTravelDistance: '',
    estimatedTravelDistanceUnit: 'mi',
    sealNumber: '',
    orderNumber: '1',
    trailerNumber: '',
    pickupNumber: '',
    customerOrderNumber: '',
    tripNumber: '',
    broker: '',
    mode: 'Live Load',
    expedite: false,
    specialEquipment: '',
    loadedOnSameTrailer: false,
    dropoffNumber: '',
    other: '',
    puNumber: '',
    stops: [
      {
        idW: '1',
        stopType: LOAD_STOP_TYPE.PICKUP,
        stopNumber: 1,
        pickupNumber: '',
        isCustomer: true,
        appointmentRequired: false,
        notes: '',
        contactName: '',
        phoneNumber: '',
        street1: '',
        street2: '',
        city: '',
        state: null,
        zipCode: '',
        canSelfLoad: false,
        canSelfUnload: false,
        pickupWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
        dropoffWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
      },
      {
        idW: '2',
        stopType: LOAD_STOP_TYPE.DROPOFF,
        stopNumber: 2,
        isCustomer: true,
        pickupNumber: '',
        appointmentRequired: false,
        notes: '',
        contactName: '',
        phoneNumber: '',
        street1: '',
        street2: '',
        city: '',
        state: null,
        zipCode: '',
        canSelfLoad: false,
        canSelfUnload: false,
        pickupWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
        dropoffWindow: {
          bounds: '[)',
          lower: null,
          upper: null,
        },
      },
    ],
  },
};

export const stateChoices: AutocompleteFieldOption[] = usaStates.map(state => ({
  name: state.name,
  value: state.abbreviation,
}));

export const initialAccessorialState: {
  [id: string]: AccessorialFormData;
} = {};

export const initialAccessorialValue = {
  idW: '1',
  description: '',
  quantity: '',
  notes: '',
  revenue: '',
  legs: [],
};

export const initialLoadStopValue = {
  idW: '1',
  isCustomer: true,
  stopType: LOAD_STOP_TYPE.PICKUP,
  stopNumber: 1,
  pickupNumber: '',
  pickupWindow: {
    bounds: '[)',
    lower: null,
    upper: null,
  },
  dropoffWindow: {
    bounds: '[)',
    lower: null,
    upper: null,
  },
  appointmentRequired: false,
  notes: '',
  contactName: '',
  phoneNumber: '',
  street1: '',
  street2: '',
  city: '',
  state: null,
  zipCode: '',
  canSelfLoad: false,
  canSelfUnload: false,
};

export const initialOrderLegValue = {
  idW: '1',
  orderW: '1',
  legNumber: 1,
  pickupInstructionsInherit: false,
  pickupInstructionsText: '',
  pickupHasNoDamages: true,
  dropoffInstructionsInherit: false,
  dropoffInstructionsText: '',
  dropoffHasNoDamages: true,
  driver: '',
};

export const initialBillingValue = {
  idW: '1',
  legW: '1',
  percentage: '',
  notes: '',
};
