import { Checkbox, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import DragHandle from './DragHandle';

type Props = {
  column: any;
  draggable?: boolean;
  index: number;
  onMoveUp?: (index: number) => void;
  onMoveDown?: (index: number) => void;
  onToggle?: (
    event: React.ChangeEvent<HTMLInputElement>,
    columnId: string,
  ) => void;
};

const Item: React.FC<Props> = ({
  column,
  draggable,
  index,
  onMoveUp,
  onMoveDown,
  onToggle,
}) => {
  const classes = useStyles();

  const handleMoveUp = useCallback(() => {
    if (onMoveUp) {
      onMoveUp(index);
    }
  }, [index, onMoveUp]);

  const handleMoveDown = useCallback(() => {
    if (onMoveDown) {
      onMoveDown(index);
    }
  }, [index, onMoveDown]);

  const renderItem = useCallback(
    (provided?: DraggableProvided) => {
      const { innerRef, draggableProps, dragHandleProps } = provided || {
        innerRef: undefined,
        draggableProps: {},
        dragHandleProps: {},
      };

      return (
        <li ref={innerRef} {...draggableProps}>
          <DragHandle
            {...dragHandleProps}
            disabled={isEmpty(dragHandleProps) || column.isAlwaysVisible}
          />
          <label className={classes.label}>
            <Checkbox
              color="primary"
              disabled={column.isAlwaysVisible}
              className={classes.checkbox}
              {...column.getToggleHiddenProps({
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  column.toggleHidden(!e.target.checked);
                  if (onToggle) onToggle(e, column.id);
                },
              })}
            />{' '}
            {column.Header}
          </label>

          {draggable && !column.isAlwaysVisible && (
            <div className={classes.reorder}>
              <ArrowDropUpIcon onClick={handleMoveUp} />
              <ArrowDropDownIcon onClick={handleMoveDown} />
            </div>
          )}
        </li>
      );
    },
    [
      classes.checkbox,
      classes.label,
      classes.reorder,
      column,
      draggable,
      handleMoveDown,
      handleMoveUp,
      onToggle,
    ],
  );

  if (!draggable) {
    return renderItem();
  }

  return (
    <Draggable key={column.id} draggableId={column.id!} index={index}>
      {(provided, snapshot) => renderItem(provided)}
    </Draggable>
  );
};

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: theme.spacing(0.75),
  },
  label: {
    flex: 1,
  },
  reorder: {
    display: 'flex',
    width: 8,
    flexDirection: 'column',

    '& > *': {
      cursor: 'pointer',
      margin: theme.spacing(-0.5, -1.5),
    },
  },
}));

export default observer(Item);
