import { makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { colors } from '../../themes';
import ActiveColumns from '../ActiveColumns';
import ActiveFilters from '../ActiveFilters';
import SavedTableForm from '../SavedTableForm';

type Props = {
  className: string;
  defaultVisible: string[];
};

const FilterDrawer: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.drawer, className].join(' ')}>
      <Typography component="h2" variant="h4" className={classes.header}>
        Filters on this table
      </Typography>

      <div className={classes.filters}>
        <SavedTableForm />
        <ActiveFilters />
        <ActiveColumns />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  drawer: {
    backgroundColor: theme.palette.grey[100],
    borderRight: '1px solid #828282',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -4, // = right border of statuses in the TrackerStatusBar
    position: 'relative',
    transition: theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: 315,
    minWidth: 315,
    whiteSpace: 'nowrap',
  },
  filters: {
    flex: 1,
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  header: {
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    backgroundColor: colors.primary[600],
    color: 'white',
    flexGrow: 0,
    lineHeight: 1,
    margin: theme.spacing(1, 0),
    marginBottom: 0,
    marginRight: -1,
    padding: theme.spacing(1.25),
    textTransform: 'uppercase',
  },
}));

export default observer(FilterDrawer);
