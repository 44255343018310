import {
  FetchLoadQueryString,
  fromAPI,
  toAPI,
} from 'doc-mate-store/lib/models/LoadQuery';
import { defaultRootStore } from 'doc-mate-store/lib/models/Store';
import * as api from 'doc-mate-store/lib/services/api';
import { asDate } from 'doc-mate-store/lib/utils/models';
import { omit, without } from 'lodash';
import { computed, observable } from 'mobx';
import {
  getRootStore,
  model,
  Model,
  modelAction,
  modelFlow,
  prop,
  rootRef,
  _async,
} from 'mobx-keystone';
import { FilterValue } from '../types/filtering';
import { WebStore } from './WebStore';

@model('doc-mate-web/SavedTable')
export class SavedTable extends Model({
  id: prop<number>(),
  name: prop<string>(),
  results: prop<number>(),
  createdOn: prop<string>(),
  modifiedOn: prop<string>(),
  filters: prop<FetchLoadQueryString>(),
  column_ordering: prop<string[]>(),
}) {
  visible = observable<string>([]);
  prevName?: string | undefined;
  prevFilters?: FetchLoadQueryString;

  getRefId = () => `${this.id}`;

  onAttachedToRootStore() {
    this.visible.replace(Object.keys(this.filtersFromAPI));
  }

  @asDate('createdOn')
  createdOnDate?: Date;

  @asDate('modifiedOn')
  modifiedOnDate?: Date;

  @computed
  get filtersFromAPI() {
    return fromAPI(this.filters);
  }

  @modelFlow
  update = _async(function*(this: SavedTable) {});

  @modelFlow
  delete = _async(function*(this: SavedTable) {
    const root = getRootStore<WebStore>(this);
    if (root) {
      yield root.deleteSavedTable(this);
    }
  });

  @modelFlow
  save = _async(function*(this: SavedTable) {
    const { token } = defaultRootStore;
    let savedTable: SavedTable;
    try {
      ({
        response: { entities: savedTable },
      } = yield api.updateSavedTables(token!, this.id, {
        name: this.name || this.prevName,
        filters: this.filters,
      }));
    } catch (error) {
      console.log('[DEBUG] error', error);
      if (this.prevFilters) {
        this.filters = this.prevFilters;
      }
      if (this.prevName) {
        this.name = this.prevName;
      }
      return false;
    }

    this.prevFilters = undefined;
    this.prevName = undefined;
    this.results = savedTable.results;

    return true;
  });

  @modelAction
  setFilters(
    name: string,
    newFilters: FetchLoadQueryString,
    visible: string[],
  ) {
    this.prevName = this.name;
    this.prevFilters = this.filters;

    this.name = name;
    this.filters = newFilters;
    this.visible.replace(visible);
  }

  @modelAction
  setFilter(key: string, value: FilterValue) {
    const newVisible = [...this.visible];
    if (newVisible.indexOf(key) < 0) {
      newVisible.push(key);
    }
    this.setFilters(
      this.name,
      { ...this.filters, ...toAPI({ [key]: value }) },
      newVisible,
    );
  }

  @modelAction
  deleteFilter(key: string) {
    this.setFilters(
      this.name,
      omit(this.filters, key),
      without(this.visible, key),
    );
  }

  @modelAction
  clearFilters() {
    this.setFilters(this.name, {}, []);
  }

  @modelFlow
  setName = _async(function*(this: SavedTable, name: string) {
    yield this.setFilters(name, this.filters, this.visible);
  });

  @modelFlow
  setColumnOrdering = _async(function*(
    this: SavedTable,
    columnOrdering: string[],
  ) {
    const prevColumnOrdering = this.column_ordering;
    this.column_ordering = columnOrdering;

    const { token } = defaultRootStore;
    try {
      yield api.updateSavedTables(token!, this.id, {
        column_ordering: this.column_ordering,
      });
    } catch (error) {
      console.log('[DEBUG] error', error);
      this.column_ordering = prevColumnOrdering;
    }
  });
}

export const savedTableRef = rootRef<SavedTable>('doc-mate-web/SavedTableRef', {
  getId: target => {
    if (target instanceof SavedTable) {
      return `${target.id}`;
    }
  },
});
