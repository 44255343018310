import { Button, Divider, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DataTableContext } from '../../contexts';
import { colors } from '../../themes';

type Props = {};

const SavedTableForm: React.FC<Props> = () => {
  const classes = useStyles();
  const { savedTable, setValues } = useContext(DataTableContext);
  const [name, setName] = useState('');

  useEffect(() => {
    if (savedTable) {
      setName(savedTable.name);
    } else {
      setName('');
    }
  }, [savedTable]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const commitName = useCallback(
    debounce(async (value: string) => {
      if (savedTable) {
        savedTable.setName(value);
      }
    }, 500),
    [savedTable],
  );

  const handleSavedTableNameChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (savedTable) {
        const { value } = event.target;
        setName(value);
        await commitName(value);
      }
    },
    [commitName, savedTable],
  );

  const handleSavedTableNameSubmit = useCallback(async () => {
    if (savedTable) {
      await savedTable.setName(savedTable.name);
    }
  }, [savedTable]);

  const handleSavedTableDelete = useCallback(async () => {
    if (savedTable) {
      // eslint-disable-next-line no-restricted-globals
      const confirmation = confirm(
        `Are you sure you want to delete ${savedTable.name}?`,
      );
      if (confirmation) {
        await savedTable.delete();
        setValues();
      }
    }
  }, [savedTable, setValues]);

  if (!savedTable) {
    return null;
  }

  return (
    <>
      <ValidatorForm
        className={classes.filterRow}
        onSubmit={handleSavedTableNameSubmit}
      >
        <TextValidator
          id="id-name"
          autoFocus
          name="name"
          fullWidth
          label="Table Name"
          onChange={handleSavedTableNameChange}
          validators={['required']}
          errorMessages={['Please give this saved table a name.']}
          size="small"
          type="text"
          value={name}
          variant="outlined"
        />
      </ValidatorForm>
      <div
        className={[classes.filterRow, classes.filterRowDeleteTable].join(' ')}
      >
        <Button
          size="small"
          onClick={handleSavedTableDelete}
          className={[
            classes.filterRowAddFilterButton,
            classes.clearFilters,
          ].join(' ')}
        >
          Delete Table
        </Button>
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  clearFilters: {
    color: colors.danger[500],
  },
  divider: {
    margin: theme.spacing(2, -3),
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterRowAddFilter: {
    marginBottom: -theme.spacing(1),
    justifyContent: 'space-between',
  },
  filterRowAddFilterButton: {},
  filterRowDeleteTable: {
    justifyContent: 'flex-end',
  },
  filterRowButton: {
    flexGrow: 0,
  },
  filterRowControl: {
    flexGrow: 1,
  },
}));

export default observer(SavedTableForm);
