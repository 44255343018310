import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  // IconButton,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { MODE_OPTION_TEXT } from 'doc-mate-store/lib/constants/load';
// import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { colors } from '../../themes';
import SimpleAutocompleteValidator from '../AutocompleteField/SimpleAutocompleteValidator';
import { dataEntryFields } from './constants';
import { OrderFormData } from './types';

type Props = {
  idW: string;
  isLoading: boolean;
  load: OrderFormData;
  loadTypes: string[];
  quantityUnits: string[];
  weightUnits: string[];
  distanceUnits: string[];
  onOrderChange: (id: string, name: string, value: any) => void;
};

const dataFieldEntryFieldToAPIField = new Map([
  ['Broker', 'broker'],
  ['Trip Number', 'tripNumber'],
  ['PO # Shipper', 'poNumberShipper'],
  ['PO # Consignee', 'poNumberConsignee'],
  ['Seal Number', 'sealNumber'],
  ['Other', 'other'],
]);

const OrderForm: React.FC<Props> = ({
  idW,
  isLoading,
  load,
  loadTypes,
  quantityUnits,
  weightUnits,
  distanceUnits,
  onOrderChange,
}) => {
  const classes = useStyles();
  const [dataEntryFieldOpen, setDataEntryFieldOpen] = useState(false);
  const [selDataEntryFields, setSelDataEntryFields] = useState([] as any);
  const [oldSelDataEntryFields, setOldSelDataEntryFields] = useState([] as any);

  const handleDataEntryClose = () => {
    setDataEntryFieldOpen(false);
  };

  const handleDataEntryOpen = () => {
    setDataEntryFieldOpen(true);
  };

  useEffect(() => {
    let initialDataEntryField = [] as any;
    if (load.broker) {
      initialDataEntryField.push('Broker');
    }
    if (load.tripNumber) {
      initialDataEntryField.push('Trip Number');
    }
    if (load.sealNumber) {
      initialDataEntryField.push('Seal Number');
    }
    if (load.poNumberShipper) {
      initialDataEntryField.push('PO # Shipper');
    }
    if (load.poNumberConsignee) {
      initialDataEntryField.push('PO # Consignee');
    }
    if (load.partnerRefNumber) {
      initialDataEntryField.push('Partner Reference #');
    }
    if (load.other) {
      initialDataEntryField.push('Other');
    }

    setSelDataEntryFields(initialDataEntryField);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const removedFields = oldSelDataEntryFields.filter(
      (x: any) => !selDataEntryFields.includes(x),
    );
    removedFields.forEach((field: any) => {
      onOrderChange(idW, dataFieldEntryFieldToAPIField.get(field)!, ''); //Clear unchecked value
    });
  }, [idW, oldSelDataEntryFields, onOrderChange, selDataEntryFields]);

  const handleDataEntryChange = useCallback(
    (
      event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
      setOldSelDataEntryFields(selDataEntryFields);
      setSelDataEntryFields(event.target.value);
    },
    [selDataEntryFields],
  );

  const handleAutoCompleteChange = useCallback(
    (name: string, value: string) => {
      onOrderChange(idW, name, value);
    },
    [idW, onOrderChange],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onOrderChange(idW, name, value);
    },
    [idW, onOrderChange],
  );

  const handleBooleanChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onOrderChange(idW, name, value === 'true');
    },
    [idW, onOrderChange],
  );

  return (
    <div className={classes.loadContainer}>
      <div className={classes.loadDetail}>
        {load.orderNumber !== '1' && (
          <div className={classes.loadDetailRow}>
            <FormControl className={classes.radioInput}>
              <FormLabel className={classes.inputLabel}>
                Loaded on the same trailer as order #1??
              </FormLabel>
              <RadioGroup
                className={classes.radioGroup}
                name="loadedOnSameTrailer"
                onChange={handleBooleanChange}
                value={load.loadedOnSameTrailer}
              >
                <FormControlLabel
                  disabled={isLoading}
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  disabled={isLoading}
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <div className={classes.loadDetailRow}>
          <SimpleAutocompleteValidator
            className={classes.input}
            isLoading={isLoading}
            label="Order Type"
            name="loadType"
            onChange={handleAutoCompleteChange}
            options={loadTypes}
            required
            value={load.loadType || ''}
          />
          <TextValidator
            name="loadQuantity"
            className={classes.inputStart}
            disabled={isLoading}
            inputProps={{ min: '0', step: '1', type: 'number' }}
            label="Quantity"
            onChange={handleChange}
            required
            validators={['required']}
            errorMessages={['This field is required.']}
            size="small"
            value={load.loadQuantity}
            variant="outlined"
          />
          <SimpleAutocompleteValidator
            className={classes.inputEnd}
            isLoading={isLoading}
            label="Unit"
            name="loadQuantityUnit"
            onChange={handleAutoCompleteChange}
            options={quantityUnits}
            required
            value={load.loadQuantityUnit || ''}
          />
          <TextValidator
            name="loadWeight"
            className={classes.inputStart}
            disabled={isLoading}
            inputProps={{ min: '0', step: '1', type: 'number' }}
            label="Weight"
            onChange={handleChange}
            required
            validators={['required']}
            errorMessages={['This field is required.']}
            size="small"
            value={load.loadWeight}
            variant="outlined"
          />
          <SimpleAutocompleteValidator
            className={classes.inputEnd}
            isLoading={isLoading}
            label="Unit"
            name="loadWeightUnit"
            onChange={handleAutoCompleteChange}
            options={weightUnits}
            required
            value={load.loadWeightUnit || ''}
          />
          <TextValidator
            name="estimatedTravelDistance"
            className={classes.inputStart}
            disabled={isLoading}
            inputProps={{ min: '0', step: '0.01', type: 'number' }}
            label="Distance"
            onChange={handleChange}
            size="small"
            value={load.estimatedTravelDistance}
            variant="outlined"
          />
          <SimpleAutocompleteValidator
            className={classes.inputEnd}
            isLoading={isLoading}
            label="Unit"
            name="estimatedTravelDistanceUnit"
            onChange={handleAutoCompleteChange}
            options={distanceUnits}
            value={load.estimatedTravelDistanceUnit || ''}
          />
        </div>
        <div className={classes.loadDetailRow}>
          <SimpleAutocompleteValidator
            className={classes.input}
            isLoading={isLoading}
            label="Mode"
            name="mode"
            required
            onChange={handleAutoCompleteChange}
            options={Object.values(MODE_OPTION_TEXT)}
            value={load.mode || ''}
          />
          <TextValidator
            name="trailerNumber"
            className={classes.input}
            disabled={isLoading}
            label="Trailer #"
            onChange={handleChange}
            size="small"
            validators={['maxStringLength:32']}
            errorMessages={[
              'Ensure this field has no more than 32 characters.',
            ]}
            value={load.trailerNumber}
            variant="outlined"
          />
          <TextValidator
            name="pickupNumber"
            className={classes.input}
            disabled={isLoading}
            label="PU #"
            onChange={handleChange}
            size="small"
            validators={['maxStringLength:32']}
            errorMessages={[
              'Ensure this field has no more than 32 characters.',
            ]}
            value={load.pickupNumber}
            variant="outlined"
          />
          <TextValidator
            name="customerOrderNumber"
            className={classes.input}
            disabled={isLoading}
            label="Cust. Order #"
            onChange={handleChange}
            size="small"
            validators={['maxStringLength:32']}
            errorMessages={[
              'Ensure this field has no more than 32 characters.',
            ]}
            value={load.customerOrderNumber}
            variant="outlined"
          />
          <FormControl className={classes.radioInput}>
            <FormLabel className={classes.inputLabel}>Expedite?</FormLabel>
            <RadioGroup
              className={classes.radioGroup}
              name="expedite"
              onChange={handleBooleanChange}
              value={load.expedite}
            >
              <FormControlLabel
                disabled={isLoading}
                value={true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                disabled={isLoading}
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.loadDetailRow}>
          <TextValidator
            name="loadDescription"
            className={classes.input}
            disabled={isLoading}
            label="Description"
            onChange={handleChange}
            size="small"
            fullWidth
            value={load.loadDescription}
            variant="outlined"
          />
        </div>
      </div>
      <div className={classes.dataEntryRow}>
        <FormControl className={classes.addDataEntryFieldButton}>
          <Select
            id="data-entry-field-checkbox"
            disabled={isLoading}
            multiple
            value={selDataEntryFields}
            onChange={handleDataEntryChange}
            open={dataEntryFieldOpen}
            onClose={handleDataEntryClose}
            onOpen={handleDataEntryOpen}
            startAdornment={<AddBoxOutlinedIcon className={classes.addIcon} />}
            autoWidth
            IconComponent={() => <div></div>}
            renderValue={selected => 'DATA ENTRY FIELD'}
            displayEmpty
            disableUnderline
          >
            {dataEntryFields.map(field => (
              <MenuItem key={field} value={field}>
                <Checkbox checked={selDataEntryFields.includes(field)} />
                <ListItemText primary={field} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selDataEntryFields.includes('Broker') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="broker"
              disabled={isLoading}
              label="Broker"
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              value={load.broker}
              variant="outlined"
            />
          </FormControl>
        )}
        {selDataEntryFields.includes('Seal Number') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="sealNumber"
              disabled={isLoading}
              label="Seal #"
              value={load.sealNumber}
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              variant="outlined"
            />
          </FormControl>
        )}
        {selDataEntryFields.includes('Trip Number') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="tripNumber"
              disabled={isLoading}
              label="Trip #"
              value={load.tripNumber}
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              variant="outlined"
            />
          </FormControl>
        )}
        {selDataEntryFields.includes('PO # Shipper') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="poNumberShipper"
              disabled={isLoading}
              label={'P.O. # Shipper'}
              value={load.poNumberShipper}
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              variant="outlined"
            />
          </FormControl>
        )}
        {selDataEntryFields.includes('PO # Consignee') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="poNumberConsignee"
              disabled={isLoading}
              label={'P.O. # Consignee'}
              value={load.poNumberConsignee}
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              variant="outlined"
            />
          </FormControl>
        )}
        {selDataEntryFields.includes('Partner Reference #') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="partnerRefNumber"
              disabled={isLoading}
              label="Partner Reference #"
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              value={load.partnerRefNumber}
              variant="outlined"
            />
          </FormControl>
        )}
        {selDataEntryFields.includes('Other') && (
          <FormControl className={classes.dataEntryInput}>
            <TextValidator
              name="other"
              disabled={isLoading}
              label={'Other'}
              value={load.other}
              onChange={handleChange}
              size="small"
              validators={['maxStringLength:32']}
              errorMessages={[
                'Ensure this field has no more than 32 characters.',
              ]}
              variant="outlined"
            />
          </FormControl>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadContainer: {
    flex: 1,
  },
  loadHeader: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#fcf0d4',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
  },
  loadName: {
    color: colors.textPrimary[600],
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  loadId: {
    color: 'black',
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
  },
  loadDetail: {
    padding: theme.spacing(2, 1, 1),
  },
  loadDetailRow: {
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  dataEntryRow: {
    padding: theme.spacing(1, 1, 0),
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
  },
  inputStart: {
    margin: theme.spacing(0, 0, 1.5, 1),
    maxWidth: 120,

    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputEnd: {
    margin: theme.spacing(0, 1, 1.5, 0),

    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  input: {
    margin: theme.spacing(0, 1, 1.5),
    minWidth: 110,
  },
  dataEntryInput: {
    padding: theme.spacing(0, 1, 1.5),
    flexBasis: '25%',
  },
  radioInput: {
    margin: theme.spacing(0, 2.5, 1.5, 1),
  },
  inputLabel: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
  },
  radioGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
  addDataEntryFieldButton: {
    flexBasis: '25%',

    '& > div': {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      backgroundColor: 'white',
      borderRadius: 0,
      color: '#4f4f4f',
      fontSize: 16,
      fontWeight: 700,
      justifyContent: 'flex-start',

      '&:hover': {
        backgroundColor: 'white',
      },
    },
  },
  expandButton: {
    borderRadius: 3,
    color: colors.primary[600],
    fontSize: theme.typography.h6.fontSize,
    justifyContent: 'flex-end',
  },
  tripNumberInput: {
    margin: theme.spacing(0, 0, 0, 5),
    minWidth: 110,
  },
}));

export default React.memo(OrderForm);
