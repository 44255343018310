import { Button, darken, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useCallback } from 'react';
import { colors } from '../../themes';
import FilterIcon from './FilterIcon';

type Props = {
  open: boolean;
  onOpen: (open: boolean) => void;
};

const FilterButton: React.FC<Props> = ({ open, onOpen }) => {
  const classes = useStyles();

  const handleToggleDrawer = useCallback(() => {
    onOpen(!open);
  }, [onOpen, open]);

  return (
    <Button
      className={classes.filterToggle}
      onClick={handleToggleDrawer}
      startIcon={<FilterIcon className={classes.filterToggleIcon} />}
      size="large"
      endIcon={
        open ? (
          <ChevronLeftIcon className={classes.filterToggleIcon} />
        ) : (
          <ChevronRightIcon className={classes.filterToggleIcon} />
        )
      }
    />
  );
};

const useStyles = makeStyles(theme => ({
  filterToggle: {
    backgroundColor: colors.primary[600],
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'white',
    fontSize: theme.typography.h6.fontSize,
    '&:hover': {
      backgroundColor: darken(colors.primary[600], 0.15),
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  filterToggleIcon: {
    color: 'white',
  },
}));

export default FilterButton;
