import { observer } from 'mobx-react-lite';
import React from 'react';
import { ColumnInstance } from 'react-table';
import { FilterValue, IFilterText } from '../../types/filtering';
import TextFilterWrapper from './TextFilterWrapper';

interface IFilterTextProps extends IFilterText {
  className?: string;
  disabled?: boolean;
  value: FilterValue;
  setValue(value: FilterValue): void;
  column?: ColumnInstance;
}

const TextFilter: React.FC<IFilterTextProps> = ({
  accessor,
  disabled,
  className,
  label,
  value,
  type,
  setValue,

  column,
}) => {
  return (
    <>
      <TextFilterWrapper
        accessor={accessor}
        disabled={disabled}
        label={label}
        className={className}
        value={value}
        setValue={setValue}
        type={type}
        column={column}
      />
    </>
  );
};

export default React.memo(observer(TextFilter));
