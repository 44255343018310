import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStores } from 'doc-mate-store/lib/hooks';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';

type Props = {
  className?: string;
  freeSolo?: boolean;
  fullWidth?: boolean;
  isLoading: boolean;
  isClearable?: boolean;
  label?: string;
  name: string;
  onChange: (name: string, value: string) => void;
  required?: boolean;
  value: string;
};

const LocationAutocompleteField: React.FC<Props> = ({
  className,
  freeSolo = false,
  fullWidth = false,
  isLoading,
  isClearable = false,
  label,
  name,
  onChange,
  required = false,
  value,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const { rootStore } = useStores();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetValue = useCallback(debounce(setInputValue, 500), []);

  useEffect(() => {
    const fetchOptions = async () => {
      if (inputValue) {
        const results = await rootStore.fetchSiteLocationNames(inputValue);
        setOptions(results);
      }
    };
    fetchOptions();
  }, [inputValue, rootStore]);

  const handleChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    if (value || isClearable) {
      onChange(name, value || '');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    debouncedSetValue(value);
  };

  const validators = required ? ['required'] : [];
  const handleRenderInput = (params: any) => (
    <TextValidator
      {...params}
      errorMessages={['This field is required.']}
      label={label}
      name={name}
      size="small"
      validators={validators}
      value={value}
      variant="outlined"
      required={required}
    />
  );

  return (
    <Autocomplete
      autoComplete
      autoSelect
      disableClearable={!isClearable}
      className={className}
      disabled={isLoading}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      renderInput={handleRenderInput}
      value={value || null}
    />
  );
};

export default LocationAutocompleteField;
