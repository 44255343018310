import { makeStyles, Typography } from '@material-ui/core';
import { LoadStop, Location } from 'doc-mate-store/lib/models';
import DateTimeRange from 'doc-mate-store/lib/models/DateTimeRange';
import React from 'react';

type Props = {
  label: string;
  location: Location;
  loadStop: LoadStop;
  showLabel?: boolean;
  isTimestampHidden?: boolean;
  isTimestampEta?: boolean;
  timestamp?: string;
  window?: DateTimeRange;
};

const LocationWidget: React.FC<Props> = props => {
  const {
    label,
    location,
    loadStop,
    showLabel,
    // isTimestampHidden,
    // isTimestampEta,
    // timestamp,
    // window,
  } = props;
  const classes = useStyles();
  // const momentTimestamp = timestamp ? moment(timestamp) : undefined;

  // let isLate = false;
  // if (
  //   isTimestampEta &&
  //   momentTimestamp &&
  //   window &&
  //   momentTimestamp > moment(window.upper)
  // ) {
  //   isLate = true;
  // }

  // let etaText = null;
  // if (!isTimestampHidden) {
  //   if (momentTimestamp) {
  //     etaText = (
  //       <Typography
  //         className={[classes.eta, isLate ? classes.late : ''].join(' ')}
  //         variant="body2"
  //       >
  //         {isTimestampEta ? 'ETA: ' : ''}
  //         {momentTimestamp.format('MM-DD HH:mm')}
  //         <br />({momentTimestamp.fromNow()})
  //       </Typography>
  //     );
  //   }
  // } else {
  //   etaText = (
  //     <Typography className={classes.eta} variant="body2">
  //       ETA: calculating&hellip;
  //       <br />
  //       The ETA will be updated once driver is in route to drop-off.
  //     </Typography>
  //   );
  // }

  return (
    <div className={classes.container}>
      {showLabel && (
        <Typography
          variant="overline"
          component="label"
          className={classes.locationLabel}
        >
          {label}
        </Typography>
      )}
      <Typography className={classes.locationName} variant="h6">
        {location.name}
      </Typography>
      <Typography className={classes.locationAddress} variant="body1">
        {loadStop.fullAddress}
      </Typography>
      {/* {etaText} */}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  eta: {
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    marginTop: theme.spacing(0.5),
  },
  late: {
    color: theme.palette.error.main,
  },
  locationLabel: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.fontSize,
    userSelect: 'none',
  },
  locationAddress: {
    color: theme.palette.grey[900],
  },
  locationNameButton: {
    background: 'transparent',
    border: 0,
    padding: 0,
    margin: 0,
  },
  locationName: {
    color: theme.palette.primary.main,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
  },
}));

export default LocationWidget;
