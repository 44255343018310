import { Button, makeStyles, Paper } from '@material-ui/core';
import React from 'react';

type Props = {
  onRefresh(): void;
};

const RefreshNow: React.FC<Props> = ({ onRefresh }) => {
  const classes = useStyles();

  const handleRefresh = () => {
    onRefresh();
  };

  return (
    <Paper className={classes.refresh}>
      <Button className={classes.button} onClick={handleRefresh}>
        Refresh load
      </Button>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  refresh: {
    background: theme.palette.grey[100],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    justifyContent: 'center',
    flex: 'row',
    display: 'flex',
  },
  button: {
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
    background: '#0d374b',
    color: 'white',
    opacity: 90,
    '&:hover': {
      color: 'black',
      opacity: 100,
    },
  },
}));

export default RefreshNow;
