import MomentUtils from '@date-io/moment';
import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { useWebStores } from '../../hooks';
import { DateFilterValue, IFilterDate } from '../../types/filtering';
import TimeRangePicker from '../TimeRangePicker';

interface IFilterDateProps extends IFilterDate {
  className?: string;
  disabled?: boolean;
  value: DateFilterValue;
  setValue(value?: DateFilterValue): void;

  column?: {
    filterValue: DateFilterValue;
    filteredRows: Row[];
    id: string;
    prefilteredRows: Row[];
    setFilter(value?: DateFilterValue): void;
  };
}

const TimeFilterWrapper: React.FC<IFilterDateProps> = ({
  accessor,
  disabled,
  label,
  className,
  value,
  setValue,
  column,
}) => {
  const { webStore } = useWebStores();
  const { columnFilters } = webStore;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters = {
    ...columnFilters!.filters,
  };

  const defaultValue = columnFilters && column ? filters[column.id] : undefined;

  const classes = useStyles();
  const id = column ? `column-filter-${column.id}-id` : `filter-${accessor}-id`;

  const { from, to } = (column && column.filterValue) ||
    value ||
    (!!defaultValue && {
      from: moment(defaultValue.from),
      to: moment(defaultValue.to),
    }) || { from: undefined, to: undefined };

  const [onBegin, setOnBegin] = useState<boolean>(true);
  const [shouldClose, setShouldClose] = useState<boolean>(false);
  const [begin, setBegin] = useState<MaterialUiPickersDate>(from || null);
  const [end, setEnd] = useState<MaterialUiPickersDate>(to || null);
  const [prevBegin, setPrevBegin] = useState<MaterialUiPickersDate>(null);
  const [prevEnd, setPrevEnd] = useState<MaterialUiPickersDate>(null);

  const toggleBegin = useCallback(
    (toggle: boolean | ((prevState: boolean) => boolean)) => {
      if (toggle) {
        setEnd(null);
      }
      setOnBegin(toggle);
    },
    [],
  );

  const handleChange = useCallback(
    ({ date }: { date: any }) => {
      if (onBegin) {
        setOnBegin(false);
        setShouldClose(false);
        setEnd(null);

        setBegin(date);
      } else {
        setOnBegin(true);
        setEnd(date);
        setShouldClose(true);

        if (column) {
          column.setFilter({
            from: moment(begin!).startOf('hour'),
            to: moment(date!).endOf('hour'),
          });
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: {
              from: moment(begin!)
                .startOf('hour')
                .toString(),
              to: moment(date!)
                .endOf('hour')
                .toString(),
            },
          });
        } else {
          setValue({
            from: moment(begin!).startOf('hour'),
            to: moment(date!).endOf('hour'),
          });
        }
      }
    },
    [onBegin, begin, columnFilters, column, setValue],
  );

  useEffect(() => {
    if (!!begin && !!end) {
      setPrevBegin(begin);
      setPrevEnd(end);
    }
  }, [begin, end]);

  const reset = useCallback(() => {
    column ? column.setFilter(undefined) : setValue(undefined);
    columnFilters!.setFilters({
      ...filters,
      [`${column!.id}`]: undefined,
    });
    setBegin(null);
    setEnd(null);
    setPrevEnd(null);
    setPrevBegin(null);
    setShouldClose(false);
  }, [column, columnFilters, setValue, filters]);

  // useEffect(() => {
  //   if (!canFilter) {
  //     reset();
  //   }
  // }, [canFilter]); //eslint-disable-line react-hooks/exhaustive-deps

  const revertValue = useCallback(() => {
    setBegin(prevBegin);
    setEnd(prevEnd);
    setOnBegin(true);
  }, [prevBegin, prevEnd]);

  useEffect(() => {
    if (!to && !from) {
      setBegin(null);
      setEnd(null);
    }
  }, [to, from]);

  const handleOpen = useCallback(() => {
    setBegin(from || moment().startOf('day'));
    setEnd(to);
    setOnBegin(true);
    setShouldClose(false);
  }, [to, from]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <TimeRangePicker
          key={[from, to].toString()}
          className={className}
          id={id}
          inputVariant="outlined"
          label={label}
          margin="normal"
          onChange={handleChange}
          onOpen={handleOpen}
          size="small"
          style={{ margin: 0 }}
          value={[begin as Moment, end as Moment]}
          toggleBegin={toggleBegin}
          onBegin={onBegin}
          shouldClose={shouldClose}
          revertValue={revertValue}
        />
        {column && (
          <IconButton
            className={classes.clear}
            onClick={reset}
            size="small"
            title="Clear"
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      minWidth: '100%',
    },
  },
  clear: {
    flexShrink: 0,
    marginLeft: theme.spacing(0.5),
  },
}));

export default React.memo(observer(TimeFilterWrapper));
