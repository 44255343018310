import {
  FetchLoadQueryString,
  fromAPI,
} from 'doc-mate-store/lib/models/LoadQuery';

type PresetTables = {
  all: {
    results: number;
    filters: FetchLoadQueryString;
    column_ordering?: string[];
  };
  active: {
    results: number;
    filters: FetchLoadQueryString;
    column_ordering?: string[];
  };
  completed: {
    results: number;
    filters: FetchLoadQueryString;
    column_ordering?: string[];
  };
  [k: string]: {
    results: number;
    filters: FetchLoadQueryString;
    column_ordering?: string[];
  };
};

export function presetFiltersFromAPI(
  presetTables: PresetTables,
  preset: string,
) {
  if (presetTables[preset]) {
    return fromAPI(presetTables[preset].filters);
  }
  return {};
}

export default PresetTables;
