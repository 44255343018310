"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    debug: true,
    firebaseConfig: {
        apiKey: 'AIzaSyD1iojXJ61RaiyTAPt5RomiS7R3krm-8_o',
        authDomain: 'docmate-dev.firebaseapp.com',
        databaseURL: 'https://docmate-dev.firebaseio.com',
        projectId: 'docmate-dev',
        storageBucket: 'docmate-dev.appspot.com',
        messagingSenderId: '881844801391',
        appId: '1:881844801391:web:74d0bc899afa361817a29a',
        measurementId: 'G-JGFKLGK434',
    },
};
