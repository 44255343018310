import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { useWebStores } from '../../hooks';

type Props = {
  children: React.ReactNode;
};

const AuthenticationRequired: React.FC<Props> = ({ children }) => {
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const classes = useStyles();
  const location = useLocation();
  const { token } = rootStore;
  const { loaded } = webStore;

  if (!loaded) {
    return (
      <Backdrop
        transitionDuration={1500}
        invisible
        open
        className={classes.root}
      >
        <img src="/196.png" alt="Doc-Mate Logo" className={classes.logo} />
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // Render actual page if authenticated.
  if (loaded && token) {
    return <>{children}</>;
  }

  // Redirect to login page if not authenticated.
  const next = `/login?next=${location.pathname}${location.search}`;
  return <Redirect to={next} />;
};

const useStyles = makeStyles(theme => ({
  logo: {
    display: 'block',
  },
  root: {
    flexDirection: 'column',
  },
}));

export default observer(AuthenticationRequired);
