import { Badge, FormControl, IconButton, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import {
  AS400_STATUS_DISPLAY,
  AS400_STATUS_DISPLAY_NAME,
  TRACKER_VISIBLE_STATUSES,
  getDisplayStatus,
} from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import uniqBy from 'lodash/uniqBy';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { useWebStores } from '../../hooks';
import {
  FilterValue,
  IFilterSelectMultiple,
  SingleFilterValue,
  StatusFilterValue,
} from '../../types/filtering';
import { DriverOption } from '../DataTable/cells/types';
import TextAndSelectFilterMenu from './TextAndSelectFilterMenu';

interface IFilterSelectMultipleProps extends IFilterSelectMultiple {
  className?: string;
  disabled?: boolean;
  value: FilterValue;
  setValue(value: FilterValue): void;
  column?: ColumnInstance;
}

const isTypeString = (val: FilterValue) => typeof val === 'string';

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  minWidth: '70%',
  '& .MuiOutlinedInput-root': {
    minWidth: '70%',
  },
});

const StyledTextField = styled(TextField)({
  flex: 1,
  '& .MuiOutlinedInput-input': {
    paddingRight: 5,
    paddingLeft: 5,
    marginRight: 0,
    marginLeft: 0,
  },
});

const StyledDisabledField = styled(TextField)({
  flex: 1,
  '& .MuiFilledInput-inputMarginDense': {
    paddingTop: 12,
  },
});

const StyledIconButton = styled(IconButton)({
  borderRadius: '4px',
  position: 'absolute',
  right: 0,
  height: '100%',
  top: 0,
});

const TextAndSelectFilterForm: React.FC<IFilterSelectMultipleProps> = ({
  accessor,
  disabled,
  label,
  className,
  values,
  value,
  setValue,
  column,
}) => {
  const { webStore } = useWebStores();
  const { rootStore } = useStores();
  const { columnFilters } = webStore;
  const filters = {
    ...columnFilters!.filters,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultTextValue = [] as string[];

  const defaultValue =
    columnFilters && column && filters[column.id] ? filters[column.id] : null;

  const labelId = column
    ? `column-filter-${column.id}-label`
    : `filter-${accessor}-label`;
  const id = column ? `column-filter-${column.id}-id` : `filter-${accessor}-id`;
  const isTextValue = column
    ? isTypeString(column.filterValue)
    : isTypeString(value);

  const [tempTextValue, setTempTextValue] = useState<string>(
    column && defaultValue !== null && isTypeString(defaultValue)
      ? defaultValue
      : '',
  );

  const [
    menuAnchorEl,
    setMenuAnchorEl,
  ] = React.useState<HTMLButtonElement | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let val: string[] | string = isTextValue ? '' : [];
  let valLength: number = 0;
  if (column) {
    if (isTextValue) {
      val = (column.filterValue || '').toString();
    } else {
      val = (column.filterValue || []).map((v: any) => v.toString());
      valLength = val.length;
    }
  } else {
    if (isTextValue) {
      val = (value || '').toString();
    } else {
      val = (value as SingleFilterValue[]).map((v: any) => v.toString());
      valLength = val.length;
    }
  }

  useEffect(() => {
    if (column && defaultValue !== null && isTypeString(defaultValue)) {
      column.setFilter(defaultValue);
      setTempTextValue(defaultValue);
    } else if (column && defaultValue !== null) {
      column.setFilter(defaultValue);
      setTempTextValue('');
    }
  }, [defaultValue, column]);

  useEffect(() => {
    if (isTextValue) {
      setTempTextValue(val as string);
    }
  }, [isTextValue, val]);

  const options = useMemo(() => {
    if (column) {
      // Calculate the options for filtering
      // using the preFilteredRows
      const options = new Set<any>();
      column.preFilteredRows.forEach(row => {
        if (column.filter === 'textSelectFirstElement') {
          if (row.values[column.id][0]) {
            options.add(row.values[column.id][0]);
          }
        } else {
          if (row.values[column.id]) {
            options.add(row.values[column.id]);
          }
        }
      });
      if (val && Array.isArray(val)) {
        val.forEach(item => {
          const optionArray = Array.from(options.values()) as string[];
          if (!optionArray.includes(item)) {
            options.add(item);
          }
        });
      }

      if (column.id === 'status__in') {
        let filters: Array<StatusFilterValue> = [];
        TRACKER_VISIBLE_STATUSES.forEach((value: any) => {
          const name = getDisplayStatus(value);
          if (options.has(name) || webStore.selectedStatus === 'all') {
            filters.push({ name, value });
          }
        });
        return filters;
      }
      if (column.id === 'order__expedite__in') {
        return [
          { name: 'Yes', value: 'Yes' },
          { name: 'No', value: 'No' },
        ];
      }
      if (column.id === 'order__manifest__as400_status__in') {
        let filters: Array<StatusFilterValue> = [];
        AS400_STATUS_DISPLAY.forEach((status: any) => {
          if (
            status === AS400_STATUS_DISPLAY_NAME.REJECTED &&
            webStore.selectedStatus !== 'all'
          ) {
            return;
          }
          filters.push({ name: status, value: status });
        });
        return filters;
      }

      if (column.id === 'driver') {
        const { users: users_ } = rootStore;
        // @ts-ignore
        const users = Array.from(users_.values()).filter(user => user.isDriver);
        // @ts-ignore
        let options: DriverOption[] = users.map(user => ({
          // @ts-ignore
          name: `${user.lastName}, ${user.firstName}`,
          // @ts-ignore
          value: user.getRefId(),
        }));

        options = options.concat({
          name: 'No driver assigned',
          value: '',
          organizationName: '',
        });
        options.sort((a, b) => {
          if (a.name === 'No driver assigned') return -1;
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        return uniqBy(options, 'name');
      }

      return Array.from(options.values()).map(value => ({
        name: value,
        value,
      }));
    } else {
      return values;
    }
  }, [column && column.preFilteredRows, id, values, val]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetTextFilter = useCallback(
    (value: string) => {
      if (column) {
        column.setFilter(value || undefined);
        columnFilters!.setFilters({
          ...columnFilters!.filters,
          [`${column.id}`]: value,
        });
      } else {
        setValue(value);
      }
    },
    [column, columnFilters, setValue],
  );
  // const debouncedSetTextFilter = useCallback(
  //   debounce(handleSetTextFilter, 100),
  //   [],
  // );

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setTempTextValue(value);
      // debouncedSetTextFilter(value);
    },
    [],
  );

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleMenuFilter = useCallback(
    (filters: string[]) => {
      if (column) {
        if (filters.length === 0) {
          column.setFilter(undefined);
          const currentFilter = { ...columnFilters!.filters };
          delete currentFilter[`${column.id}`];
          columnFilters!.setFilters({
            ...currentFilter,
          });
        } else {
          column.setFilter(filters);
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: filters,
          });
        }
      }
    },
    [column, columnFilters],
  );

  const handleMenuSortBy = useCallback(
    (desc: boolean | null) => {
      if (column) {
        if (desc === null) {
          column.clearSortBy();
        } else {
          column.toggleSortBy(desc, true);
        }
        setMenuAnchorEl(null);
      }
    },
    [column],
  );

  const textValue = useMemo(
    () => (isTextValue ? defaultTextValue : (val as string[])),
    [defaultTextValue, isTextValue, val],
  );

  const handleSubmit = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        handleSetTextFilter(event.target.value);
      }
    },
    [handleSetTextFilter],
  );

  return (
    <StyledFormControl
      className={className}
      key={accessor}
      fullWidth
      size="small"
    >
      {column && column.id === 'status__in' ? (
        <StyledDisabledField
          id={labelId}
          className={className}
          disabled={true}
          label={label}
          name={accessor}
          fullWidth
          variant="filled"
          size="small"
        />
      ) : (
        <StyledTextField
          id={labelId}
          className={className}
          disabled={disabled}
          label={label}
          name={accessor}
          fullWidth
          variant="outlined"
          size="small"
          value={tempTextValue}
          onChange={handleTextChange}
          onKeyDown={e => handleSubmit(e)}
        />
      )}
      <StyledIconButton
        aria-label="more"
        aria-haspopup="true"
        size="small"
        onClick={handleMenuOpen}
      >
        <Badge badgeContent={valLength} color="secondary">
          <SortIcon />
        </Badge>
      </StyledIconButton>
      <TextAndSelectFilterMenu
        anchorEl={menuAnchorEl}
        columnId={column ? column.id : ''}
        isSortedDesc={column ? column.isSortedDesc : undefined}
        options={options}
        value={textValue}
        onClose={handleMenuClose}
        onFilter={handleMenuFilter}
        onSortBy={handleMenuSortBy}
      />
    </StyledFormControl>
  );
};

export default React.memo(observer(TextAndSelectFilterForm));
