"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadStopRef = exports.LoadStop = void 0;
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var mobx_keystone_1 = require("mobx-keystone");
var models_1 = require("../utils/models");
var Location_1 = require("./Location");
var User_1 = require("./User");
var LoadStop = /** @class */ (function (_super) {
    __extends(LoadStop, _super);
    function LoadStop() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id); };
        return _this;
    }
    LoadStop_1 = LoadStop;
    Object.defineProperty(LoadStop.prototype, "fullAddress", {
        get: function () {
            var fullAddress = [
                this.street1,
                this.street2,
                this.city,
                this.state,
                this.zipCode,
            ];
            return fullAddress.filter(function (part) { return part.length > 0; }).join(', ');
        },
        enumerable: false,
        configurable: true
    });
    LoadStop.prototype.update = function (data) {
        var rootStore = (0, mobx_keystone_1.getRootStore)(this);
        if (!rootStore) {
            return;
        }
        var result = LoadStop_1._formatData(rootStore, data);
        if (result) {
            Object.assign(this, result);
        }
    };
    Object.defineProperty(LoadStop.prototype, "latlng", {
        // Convert coordinates to LatLng type.
        get: function () {
            return {
                latitude: this.coordinates.coordinates[1],
                longitude: this.coordinates.coordinates[0],
            };
        },
        enumerable: false,
        configurable: true
    });
    var LoadStop_1;
    LoadStop._formatData = function (rootStore, data) {
        var loadStop = __assign({}, data);
        try {
            if (!rootStore.locations.has("".concat(data.location))) {
                rootStore.fetchLocation(Number(data.location));
            }
            loadStop.location = (0, Location_1.locationRef)("".concat(data.location));
            var users = (0, lodash_1.without)((0, lodash_1.uniq)([data.contact, data.creator]), null);
            users.forEach(function (user) {
                if (!rootStore.users.has("".concat(user))) {
                    rootStore.fetchUser(user);
                }
            });
            loadStop.creator = data.creator ? (0, User_1.userRef)(data.creator) : null;
            loadStop.contact = data.contact ? (0, User_1.userRef)(data.contact) : null;
            if (data.stopType) {
                loadStop.stopType = data.stopType;
            }
            loadStop.stopNumber = data.stopNumber;
            loadStop.phoneNumber = data.phoneNumber ? data.phoneNumber : '';
            loadStop.pickupNumber = data.pickupNumber ? data.pickupNumber : '';
            loadStop.pickupWindow = data.pickupWindow ? data.pickupWindow : null;
            loadStop.dropoffWindow = data.dropoffWindow ? data.dropoffWindow : null;
            if (data.appointmentRequired) {
                loadStop.appointmentRequired = data.appointmentRequired;
            }
            loadStop.notes = data.notes ? data.notes : '';
            loadStop.street1 = data.street1 ? data.street1 : '';
            loadStop.street2 = data.street2 ? data.street2 : '';
            loadStop.city = data.city ? data.city : '';
            loadStop.state = data.state ? data.state : '';
            loadStop.zipCode = data.zipCode ? data.zipCode : '';
            loadStop.canSelfLoad = data.canSelfLoad;
            loadStop.canSelfUnload = data.canSelfUnload;
            if (data.coordinates) {
                loadStop.coordinates = data.coordinates;
            }
            return loadStop;
        }
        catch (error) {
            console.warn('[WARN] error', error);
            return null;
        }
    };
    LoadStop.create = function (rootStore, data) {
        var result = LoadStop_1._formatData(rootStore, data);
        if (result) {
            var newLoadStop = new LoadStop_1(result);
            return newLoadStop;
        }
        else {
            return null;
        }
    };
    __decorate([
        (0, models_1.asDate)('created')
    ], LoadStop.prototype, "createdOnDate", void 0);
    __decorate([
        (0, models_1.asDate)('modified')
    ], LoadStop.prototype, "modifiedOnDate", void 0);
    __decorate([
        mobx_1.computed
    ], LoadStop.prototype, "fullAddress", null);
    __decorate([
        mobx_keystone_1.modelAction
    ], LoadStop.prototype, "update", null);
    LoadStop = LoadStop_1 = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/LoadStop')
    ], LoadStop);
    return LoadStop;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    stopType: (0, mobx_keystone_1.prop)(),
    stopNumber: (0, mobx_keystone_1.prop)(),
    location: (0, mobx_keystone_1.prop)(),
    phoneNumber: (0, mobx_keystone_1.prop)(),
    pickupNumber: (0, mobx_keystone_1.prop)(),
    pickupWindow: (0, mobx_keystone_1.prop)(),
    dropoffWindow: (0, mobx_keystone_1.prop)(),
    appointmentRequired: (0, mobx_keystone_1.prop)(false),
    isCustomer: (0, mobx_keystone_1.prop)(true),
    notes: (0, mobx_keystone_1.prop)(),
    street1: (0, mobx_keystone_1.prop)(),
    street2: (0, mobx_keystone_1.prop)(),
    city: (0, mobx_keystone_1.prop)(),
    state: (0, mobx_keystone_1.prop)(),
    zipCode: (0, mobx_keystone_1.prop)(),
    coordinates: (0, mobx_keystone_1.prop)(),
    contact: (0, mobx_keystone_1.prop)(),
    creator: (0, mobx_keystone_1.prop)(),
    created: (0, mobx_keystone_1.prop)(),
    modified: (0, mobx_keystone_1.prop)(),
    canSelfLoad: (0, mobx_keystone_1.prop)(),
    canSelfUnload: (0, mobx_keystone_1.prop)(),
})));
exports.LoadStop = LoadStop;
exports.loadStopRef = (0, mobx_keystone_1.rootRef)('doc-mate/LoadStopRef', {
    getId: function (target) {
        if (target instanceof LoadStop) {
            return "".concat(target.id);
        }
    },
});
