import { observer } from 'mobx-react-lite';
import React from 'react';
import { ColumnInstance } from 'react-table';
import { FilterValue, IFilterSelectMultiple } from '../../types/filtering';
import TextAndSelectFilterForm from './TextAndSelectFilterForm';

interface IFilterSelectMultipleProps extends IFilterSelectMultiple {
  className?: string;
  disabled?: boolean;
  value: FilterValue;
  setValue(value: FilterValue): void;
  column?: ColumnInstance;
}

const TextAndSelectFilter: React.FC<IFilterSelectMultipleProps> = ({
  accessor,
  disabled,
  label,
  className,
  values,
  value,
  setValue,
  type,
  column,
}) => {
  return (
    <>
      <TextAndSelectFilterForm
        accessor={accessor}
        disabled={disabled}
        label={label}
        className={className}
        values={values}
        value={value}
        setValue={setValue}
        type={type}
        column={column}
      />
    </>
  );
};

export default React.memo(observer(TextAndSelectFilter));
