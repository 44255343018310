import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import qs from 'qs';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import DividerWithText from '../../components/DividerWithText';
import LoginForm from '../../components/LoginForm';
import TrackerAppBar from '../../components/TrackerAppBar';
import TrackingLinkForm from '../../components/TrackingLinkForm';
import { useWebStores } from '../../hooks';
import { colors } from '../../themes';
import Root from '../Root';

type Props = {};

const Login: React.FC<Props> = () => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const history = useHistory();
  const location = useLocation();
  const search = location.search.substring(1);
  const query = qs.parse(search);

  useEffect(() => {
    when(
      () => !!rootStore.token,
      () => history.replace((query.next as string) || '/'),
    );
  }, [history, query.next, rootStore.token]);

  async function handleLogin(username: string, password: string) {
    return rootStore.login({ username, password }, () => webStore.load());
  }

  const handleResetPasswordRequest = () => {
    console.log('Forgot password clicked');
    history.push('/resetpassword');
    // send request for password reset
    // navigate to token validation page + reset password form
  };

  return (
    <Root>
      <Helmet>
        <title>Sign In | Doc-Mate</title>
        <meta name="apple-itunes-app" content="app-id=1490860416" />
      </Helmet>
      <TrackerAppBar subtitle="Tracker" />

      <div className={classes.container}>
        <Container maxWidth="sm" className={classes.formWrapper}>
          <Typography variant="h1" className={classes.track}>
            Track Your Shipment
          </Typography>
          <TrackingLinkForm autoFocus className={classes.trackingForm} />
          <DividerWithText
            text="or"
            variant="middle"
            textProps={{ variant: 'h4' }}
          />
          <LoginForm className={classes.trackingForm} onLogin={handleLogin} />
          <Button onClick={handleResetPasswordRequest}>Forgot password?</Button>
        </Container>
      </div>
    </Root>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(10),
    },
  },
  formWrapper: {
    flexGrow: 0,
    padding: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: 2,
      borderStyle: 'solid',
    },
  },
  track: {
    color: colors.primary[900],
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    marginBottom: theme.spacing(10),
  },
  trackingForm: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
}));

export default observer(Login);
