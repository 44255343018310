import { makeStyles, Popover } from '@material-ui/core';
import DateTimeRange from 'doc-mate-store/lib/models/DateTimeRange';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  cell: {
    value: [boolean, DateTimeRange];
  };
};

const TimeDisplay: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { cell } = props;
  const {
    value: [isEta, timestamp],
  } = cell;

  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!!timestamp) {
        event.preventDefault();
        event.stopPropagation();
        navigator.clipboard.writeText(
          `${moment(timestamp.lower).format('HH:mm')} - ${moment(
            timestamp.upper,
          ).format('HH:mm')}`,
        );
        setAnchorEl(event.currentTarget);
        setCopy(true);
      }
    },
    [timestamp],
  );
  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  if (!timestamp || !timestamp.lower) {
    return <span>Not provided</span>;
  }

  const display = `${moment(timestamp.lower).format('HH:mm')} - ${moment(
    timestamp.upper,
  ).format('HH:mm')}`;

  return (
    <>
      <span className={isEta ? classes.eta : undefined} onClick={handleClick}>
        {display}
      </span>
      <Popover
        id={display}
        open={copy}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  eta: {
    color: theme.palette.grey[500],
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default observer(TimeDisplay);
