import {
  BILL_TO_TEXT,
  MODE_OPTION_TEXT,
} from 'doc-mate-store/lib/constants/load';
import { Load } from 'doc-mate-store/lib/models';
import { LoadAccessorial } from 'doc-mate-store/lib/models/Accessorial';
import { Billing } from 'doc-mate-store/lib/models/Billing';
import { LoadStop } from 'doc-mate-store/lib/models/LoadStop';
import { Manifest } from 'doc-mate-store/lib/models/Manifest';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import moment from 'moment';
import {
  initialBillingValue,
  initialLoadStopValue,
  initialOrderLegValue,
  stateChoices,
} from './constants';
import {
  AccessorialFormData,
  BillingFormData,
  LoadStopFormData,
  ManifestFormData,
  OrderFormData,
  OrderLegFormData,
} from './types';

export const sleep = (timeout: number) =>
  new Promise(resolve => setTimeout(resolve, timeout));

export const getDmidFromOrderLeg = (
  orderLeg: OrderLegFormData | undefined,
  orderLegs: OrderLegFormData[],
  loads: OrderFormData[],
  manifestNumber: string,
) => {
  let dmId = manifestNumber;

  if (!orderLeg) {
    return '';
  }

  const legCount = orderLegs.filter(ordLeg => ordLeg.orderW === orderLeg.orderW)
    .length;
  const loadCount = loads.length;
  const load = loads.find(load => load.idW === orderLeg.orderW);

  if (!load) {
    return '';
  }

  if (loadCount > 1) {
    dmId = dmId + '-O' + load.orderNumber + '/' + loadCount;
  }

  if (legCount > 1) {
    dmId = dmId + '-L' + orderLeg.legNumber + '/' + legCount;
  }

  return dmId;
};

export const getLoadDetailsFromLoad = (
  load: OrderFormData | undefined,
  bol: string,
) =>
  load
    ? {
        orderNumber: load.orderNumber || '1',
        loadDescription: load.loadDescription || '',
        billOfLadingNumber: bol || '',
      }
    : { orderNumber: '1', loadDescription: '', billOfLadingNumber: '' };

export const getDriverFromLeg = (leg: OrderLegFormData | undefined) =>
  leg ? leg.driver || '' : '';

const getLoadStopData = (loadStop: LoadStop) => {
  const stateChoice = stateChoices.filter(
    state => state.value === loadStop.state,
  );
  let stopData: LoadStopFormData = {
    ...initialLoadStopValue,
    // id: loadStop.id,
    idW: `${loadStop.id}`,
    isCustomer: loadStop.isCustomer,
    stopNumber: loadStop.stopNumber,
    stopType: loadStop.stopType,
    phoneNumber: loadStop.phoneNumber,
    pickupNumber: loadStop.pickupNumber,
    appointmentRequired: loadStop.appointmentRequired,
    notes: loadStop.notes,
    street1: loadStop.street1,
    street2: loadStop.street2,
    city: loadStop.city,
    state: stateChoice[0],
    zipCode: loadStop.zipCode,
    canSelfLoad: loadStop.canSelfLoad,
    canSelfUnload: loadStop.canSelfUnload,
  };
  if (loadStop.pickupWindow) {
    const dateWindow = {
      bounds: loadStop.pickupWindow.bounds,
      lower: moment(loadStop.pickupWindow.lower),
      upper: moment(loadStop.pickupWindow.upper),
    };
    stopData = {
      ...stopData,
      pickupWindow: dateWindow,
    };
  }
  if (loadStop.dropoffWindow) {
    const dateWindow = {
      bounds: loadStop.dropoffWindow.bounds,
      lower: moment(loadStop.dropoffWindow.lower),
      upper: moment(loadStop.dropoffWindow.upper),
    };
    stopData = {
      ...stopData,
      dropoffWindow: dateWindow,
    };
  }
  const stopLocation = loadStop.location.isValid
    ? loadStop.location.current
    : null;
  if (stopLocation) {
    stopData = {
      ...stopData,
      contactName: stopLocation.name,
    };
  }
  // if (!stopLocation) {
  //   return null;
  // }
  return stopData;
};

export const getInitialManifestFormState = (
  manifest: Manifest,
): ManifestFormData => {
  const terminal =
    manifest.revenueTerminal && manifest.revenueTerminal.isValid
      ? manifest.revenueTerminal.current
      : null;
  const terminalData = terminal
    ? {
        name: terminal.name,
        value: `${terminal.id}`,
      }
    : null;

  const billAsUnit =
    manifest.billAsUnit && manifest.billAsUnit.isValid
      ? manifest.billAsUnit.current.name
      : '';
  const manifestData = {
    id: manifest.id,
    manifestNumber: manifest.manifestNumber,
    as400ManifestNumber: manifest.as400ManifestNumber,
    billOfLadingNumber: manifest.billOfLadingNumber,
    billToOptions: BILL_TO_TEXT[manifest.billToOptions],
    billToThirdParty: manifest.billToThirdParty,
    billAs: `${manifest.billAs}`,
    billAsUnit: billAsUnit,
    billRevenue: `${manifest.billRevenue}`,
    revenueTerminal: terminalData,
  };

  return manifestData;
};

export const getInitialOrderFormState = (
  orders: (Load | undefined)[],
  isUpdate: boolean,
) => {
  let orderData: { [id: string]: OrderFormData } = {};

  const orderValues = orders.map(order => {
    if (!order) {
      return {};
    }

    const stopsData = Array.from(order.orderStops).map(stop =>
      getLoadStopData(stop),
    );
    const loadType =
      order.loadType && order.loadType.isValid
        ? order.loadType.current.name
        : '';
    const loadWeight = order.loadWeight ? `${order.loadWeight}` : '';
    const loadQuantity = order.loadQuantity ? `${order.loadQuantity}` : '';
    const estimatedTravelDistance = order.estimatedTravelDistance
      ? `${order.estimatedTravelDistance}`
      : '';
    const loadWeightUnit =
      order.loadWeightUnit && order.loadWeightUnit.isValid
        ? order.loadWeightUnit.current.name
        : '';
    const loadQuantityUnit =
      order.loadQuantityUnit && order.loadQuantityUnit.isValid
        ? order.loadQuantityUnit.current.name
        : '';
    const estimatedTravelDistanceUnit =
      order.estimatedTravelDistanceUnit &&
      order.estimatedTravelDistanceUnit.isValid
        ? order.estimatedTravelDistanceUnit.current.name
        : '';
    return {
      idW: `${order.id}`,
      orderNumber: `${order.orderNumber}`,
      loadDescription: isUpdate ? `${order.loadDescription}` : '',
      loadType: loadType,
      loadWeight: loadWeight,
      loadWeightUnit: loadWeightUnit,
      loadQuantity: loadQuantity,
      loadQuantityUnit: loadQuantityUnit,
      estimatedTravelDistance: estimatedTravelDistance,
      estimatedTravelDistanceUnit: estimatedTravelDistanceUnit,
      sealNumber: `${order.sealNumber}`,
      tripNumber: `${order.tripNumber}`,
      trailerNumber: `${order.trailerNumber}`,
      broker: `${order.broker}` || '',
      mode: MODE_OPTION_TEXT[order.mode],
      expedite: order.expedite,
      pickupNumber: `${order.pickupNumber}`,
      other: `${order.other}`,
      poNumberShipper: `${order.poNumberShipper}`,
      poNumberConsignee: `${order.poNumberConsignee}`,
      customerOrderNumber: `${order.customerOrderNumber}`,
      partnerRefNumber: `${order.partnerRefNumber}`,
      loadedOnSameTrailer: order.loadedOnSameTrailer,
      billOfLadingNumber: isUpdate ? order.billOfLadingNumber : '',
      stops: stopsData,
    };
  });

  orderValues.forEach(
    orderValue => orderValue && (orderData[`${orderValue.idW}`] = orderValue),
  );
  return orderData;
};

export const getInitialOrderLegFormState = (
  orderLegs: (OrderLeg | undefined)[],
  isUpdate: boolean,
) => {
  let orderLegData: { [id: string]: OrderLegFormData } = {};

  const orderLegValues = orderLegs.map(orderLeg => {
    if (!orderLeg) {
      return initialOrderLegValue;
    }
    const driver =
      isUpdate && orderLeg.driver && orderLeg.driver.isValid
        ? orderLeg.driver.current.name
        : '';

    return {
      idW: `${orderLeg.id}`,
      orderW: `${orderLeg.order.current.id}`,
      legNumber: orderLeg.legNumber,
      pickupInstructionsInherit: orderLeg.pickupInstructionsInherit,
      pickupInstructionsText: orderLeg.pickupInstructionsText || '',
      pickupHasNoDamages: orderLeg.pickupHasNoDamages || false,
      dropoffInstructionsInherit: orderLeg.dropoffInstructionsInherit,
      dropoffInstructionsText: orderLeg.dropoffInstructionsText || '',
      dropoffHasNoDamages: orderLeg.dropoffHasNoDamages || false,
      driver: driver,
    };
  });
  orderLegValues.forEach(
    orderLegValue =>
      orderLegValue && (orderLegData[`${orderLegValue.idW}`] = orderLegValue),
  );
  return orderLegData;
};

export const getInitialBillingFormState = (
  billings: (Billing | undefined)[],
) => {
  let billingData: { [id: string]: BillingFormData } = {};

  const billingValues = billings.map(billing => {
    if (!billing) {
      return initialBillingValue;
    }

    return {
      idW: `${billing.id}`,
      legW: `${billing.leg.current.id}`,
      percentage: `${billing.percentage}`,
      notes: billing.notes,
    };
  });
  billingValues.forEach(
    billingValue =>
      billingValue && (billingData[`${billingValue.idW}`] = billingValue),
  );
  return billingData;
};

export const getInitialAccessorialFormState = (
  accessorials: (LoadAccessorial | undefined)[],
) => {
  let accessorialsData: { [id: string]: AccessorialFormData } = {};

  const accessorialValues = accessorials.map(accessorial => {
    if (!accessorial) {
      return null;
    }
    const legs = Array.from(accessorial.legs).map(accLeg => ({
      legW: `${accLeg.leg.current.id}`,
      percentage: `${accLeg.percentage}`,
    }));
    return {
      idW: `${accessorial.id}`,
      description: `${accessorial.description}`,
      quantity: `${accessorial.quantity}`,
      notes: accessorial.notes,
      revenue: `${accessorial.revenue}`,
      legs: legs,
    };
  });
  accessorialValues.forEach(
    accessorialValue =>
      accessorialValue &&
      (accessorialsData[`${accessorialValue.idW}`] = accessorialValue),
  );
  return accessorialsData;
};
