import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';
import { colors } from '../../themes';

type Props = {
  active?: boolean;
};

const TimelinePath: React.FC<Props> = ({ active }) => {
  const theme = useTheme();
  const fill = active ? colors.primary[900] : theme.palette.grey[500];
  return (
    <SvgIcon
      width={5}
      viewBox="0 0 5 40"
      preserveAspectRatio="none"
      style={{
        flex: 1,
        flexBasis: 0,
        width: 5,
        height: 'inherit',
        minHeight: 40,
        maxHeight: '100%',
        marginBottom: -4,
      }}
    >
      <rect width={5} fill={fill} style={{ height: '100%' }} />
    </SvgIcon>
  );
};

export default TimelinePath;
