import { Load, LoadTableRow } from 'doc-mate-store/lib/models';
import { defaultRootStore } from 'doc-mate-store/lib/models/Store';
import { customRef, detach } from 'mobx-keystone';

export const loadRef = customRef<Load>('doc-mate-web/LoadRef', {
  getId: target => {
    if (target instanceof Load) {
      return `${target.id}`;
    }
  },

  resolve(ref) {
    if (!defaultRootStore) {
      return undefined;
    }

    return defaultRootStore.loads.get(ref.id);
  },

  onResolvedValueChange(ref, newLoad, oldLoad) {
    if (oldLoad && !newLoad) {
      detach(ref);
    }
  },
});

export const loadTableRowRef = customRef<LoadTableRow>(
  'doc-mate-web/LoadTableRowRef',
  {
    getId: target => {
      if (target instanceof Load) {
        return `${target.id}`;
      }
    },

    resolve(ref) {
      if (!defaultRootStore) {
        return undefined;
      }

      return defaultRootStore.loadTableRows.get(ref.id);
    },

    onResolvedValueChange(ref, newLoad, oldLoad) {
      if (oldLoad && !newLoad) {
        detach(ref);
      }
    },
  },
);
