import { Button, Divider, makeStyles, Typography } from '@material-ui/core';
import { groupBy, without } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { DataTableContext } from '../../contexts';
import { colors } from '../../themes';
import Item from './Item';

type Props = {};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ActiveColumns: React.FC<Props> = () => {
  const classes = useStyles();
  const { tableProps, resetColumns, saveColumnOrdering } = useContext(
    DataTableContext,
  );
  const { allColumns, setColumnOrder, visibleColumns } = tableProps;

  const availableColumns: any[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortableColumns: any[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fixedColumns: any[] = [];

  allColumns.forEach((column: any) => {
    if (!column.isVisible) {
      availableColumns.push(column);
    } else if (column.isAlwaysVisible) {
      fixedColumns.push(column);
    }
  });
  visibleColumns.forEach((column: any) => {
    if (!column.isAlwaysVisible) {
      sortableColumns.push(column);
    }
  });

  const groupedAvailableColumns = groupBy(availableColumns, o => o.groupLabel);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }
      const columnOrder = fixedColumns
        .map((d: any) => d.id)
        .concat(
          reorder(
            sortableColumns.map((d: any) => d.id),
            result.source.index,
            result.destination.index,
          ),
        );
      setColumnOrder(columnOrder);
      saveColumnOrdering(columnOrder);
    },
    [fixedColumns, saveColumnOrdering, setColumnOrder, sortableColumns],
  );

  const handleMove = useCallback(
    (index: number, distance: number) => {
      if (index > 0) {
        const columnOrder = fixedColumns
          .map((d: any) => d.id)
          .concat(
            reorder(
              sortableColumns.map((d: any) => d.id),
              index,
              index + distance,
            ),
          );
        setColumnOrder(columnOrder);
        saveColumnOrdering(columnOrder);
      }
    },
    [fixedColumns, saveColumnOrdering, setColumnOrder, sortableColumns],
  );

  const handleMoveUp = useCallback(
    (index: number) => {
      handleMove(index, -1);
    },
    [handleMove],
  );

  const handleMoveDown = useCallback(
    (index: number) => {
      handleMove(index, 1);
    },
    [handleMove],
  );

  const handleResetClick = useCallback(() => {
    resetColumns();
    saveColumnOrdering();
  }, [resetColumns, saveColumnOrdering]);

  const handleToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
      let columnOrder = fixedColumns
        .map((d: any) => d.id)
        .concat(sortableColumns.map((d: any) => d.id));
      if (!event.target.checked) {
        columnOrder = without(columnOrder, columnId);
      } else {
        columnOrder.push(columnId);
      }
      saveColumnOrdering(columnOrder);
    },
    [fixedColumns, saveColumnOrdering, sortableColumns],
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Typography component="h3" variant="subtitle1" className={classes.header}>
        Active Columns
      </Typography>
      <Button onClick={handleResetClick} className={classes.reset} size="small">
        Reset
      </Button>

      <ul className={classes.columnList}>
        {fixedColumns.map((column: any, index) => {
          if (column.Header === ' ') {
            //Linked Legs
            return '';
          }
          return <Item key={column.id} column={column} index={index} />;
        })}
      </ul>

      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <ul
            className={classes.columnList}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sortableColumns.map((column: any, index) => (
              <Item
                key={column.id}
                column={column}
                index={index}
                draggable
                onMoveUp={handleMoveUp}
                onMoveDown={handleMoveDown}
                onToggle={handleToggle}
              />
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>

      <Divider className={classes.divider} />

      <Typography component="h3" variant="subtitle1" className={classes.header}>
        Available Columns
      </Typography>

      {availableColumns.length === 0 ? (
        <div className={classes.noAvailable}>All columns are active.</div>
      ) : (
        Object.keys(groupedAvailableColumns).map(label => (
          <ul key={label} className={classes.columnListWrapper}>
            <li>{label}</li>
            <ul className={classes.columnList}>
              {groupedAvailableColumns[label].map((column: any, index) => (
                <Item
                  key={column.id}
                  column={column}
                  index={index}
                  onToggle={handleToggle}
                />
              ))}
            </ul>
          </ul>
        ))
      )}
    </DragDropContext>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    color: colors.primary[900],
    float: 'left',
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  divider: {
    margin: theme.spacing(2, -3),
  },
  columnListWrapper: {
    clear: 'both',
    listStyle: 'none',
    margin: 0,
    padding: 0,

    '& > li:first-child': {
      ...theme.typography.h6,
      backgroundColor: colors.primary[100],
      color: colors.primary[900],
      cursor: 'default',
      // fontWeight: theme.typography.fontWeightMedium,
      fontWeight: 500,
      lineHeight: 1,
      padding: theme.spacing(1),
      textTransform: 'uppercase',
    },
  },
  columnList: {
    backgroundColor: 'white',
    borderColor: theme.palette.grey[500],
    borderStyle: 'solid',
    borderWidth: 1,
    clear: 'both',
    listStyleType: 'none',
    margin: 0,
    padding: 0,

    '& + $columnList': {
      marginTop: -1,
    },

    '& li': {
      ...theme.typography.body1,
      alignItems: 'center',
      backgroundColor: 'white',
      borderColor: theme.palette.grey[500],
      borderStyle: 'solid',
      borderWidth: 1,
      color: colors.primary[900],
      display: 'flex',
      // fontWeight: theme.typography.fontWeightMedium,
      fontWeight: 500,
      margin: '-1px -1px 0 -1px',
      textTransform: 'uppercase',

      '&:last-child': {
        borderBottomWidth: 0,
      },
    },
  },
  noAvailable: {
    ...theme.typography.body1,
    clear: 'both',
    color: theme.palette.grey[500],
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 500,
    padding: 0,
  },
  reset: {
    float: 'right',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
  },
}));

export default observer(ActiveColumns);
