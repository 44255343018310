import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ACCESSORIAL_DESCRIPTION_CHOICES } from 'doc-mate-store/lib/constants/accessorial';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { colors } from '../../themes';
import SimpleAutocompleteValidator from '../AutocompleteField/SimpleAutocompleteValidator';
import AccessorialLegForm from './AccessorialLegForm';
import { AccessorialFormData, OrderFormData, OrderLegFormData } from './types';
import { getDmidFromOrderLeg } from './utils';

type Props = {
  idW: string;
  index: number;
  invoiceDetailOpen: boolean;
  isLoading: boolean;
  billToOptions: string;
  manifestNumber: string;
  accessorial: AccessorialFormData;
  orderLegs: OrderLegFormData[];
  loads: OrderFormData[];
  onAccessorialChange: (idW: string, name: string, value: any) => void;
  onRemoveAccessorial: (idW: string) => void;
};

const AccessorialDetailForm: React.FC<Props> = ({
  idW,
  index,
  invoiceDetailOpen,
  isLoading,
  billToOptions,
  manifestNumber,
  accessorial,
  orderLegs,
  loads,
  onAccessorialChange,
  onRemoveAccessorial,
}) => {
  const classes = useStyles();
  const [accessorialDetailsOpen, setAccessorialDetailsOpen] = useState(
    invoiceDetailOpen,
  );
  const withCheckBox = useMemo(() => {
    return orderLegs.length > 1;
  }, [orderLegs.length]);

  useEffect(() => {
    setAccessorialDetailsOpen(invoiceDetailOpen);
  }, [invoiceDetailOpen]);

  const handleAccessorialChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;

      onAccessorialChange(idW, name, value);
    },
    [idW, onAccessorialChange],
  );

  const handleAccessorialAutoCompleteChange = useCallback(
    (name: string, value: string) => {
      onAccessorialChange(idW, name, value);
    },
    [idW, onAccessorialChange],
  );

  const handleRemoveAccessorial = useCallback(() => {
    onRemoveAccessorial(idW);
  }, [idW, onRemoveAccessorial]);

  const getLoadFromOrderLeg = useCallback(
    (orderLeg: OrderLegFormData) => {
      return loads.find((load) => load.idW === orderLeg.orderW);
    },
    [loads.length], //eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleExpandAccessorialDetails = useCallback(() => {
    setAccessorialDetailsOpen((prevState) => !prevState);
  }, []);

  const sortOrderLeg = useCallback(
    (orderLegA: OrderLegFormData, orderLegB: OrderLegFormData) => {
      const loadA = getLoadFromOrderLeg(orderLegA);
      const loadB = getLoadFromOrderLeg(orderLegB);

      if (loadA && loadB) {
        if (loadA.orderNumber !== loadB.orderNumber) {
          return (
            parseInt(loadA.orderNumber!, 10) - parseInt(loadB.orderNumber!, 10)
          );
        }
      }

      return orderLegA.legNumber! - orderLegB.legNumber!;
    },
    [getLoadFromOrderLeg],
  );

  return (
    <div className={classes.formContainer}>
      <div className={classes.accessorialContainer}>
        <div className={classes.assessorialHeader}>
          <Typography className={classes.assessorialHeaderName} variant="h6">
            Accessorial # {index + 1}
          </Typography>
          <IconButton
            aria-label="remove"
            className={classes.removeBtn}
            onClick={handleRemoveAccessorial}
            size="small"
          >
            <Typography className={classes.removeName} variant="body2">
              Remove
            </Typography>
          </IconButton>
          <Button
            className={classes.expandButton}
            disabled={isLoading}
            onClick={handleExpandAccessorialDetails}
          >
            {accessorialDetailsOpen ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </Button>
        </div>
        <div className={classes.assessorialDetailRow}>
          <div className={classes.assessorialDetail}>
            <div className={classes.assessorialDetailItem}>
              <Typography
                className={classes.assessorialDetailItemName}
                variant="body2"
              >
                Bill To
              </Typography>
              <Typography
                className={classes.assessorialDetailItemValue}
                variant="body2"
              >
                {billToOptions}
              </Typography>
            </div>
          </div>
          <div className={classes.assessorialDetail}>
            <div className={classes.assessorialDetailItem}>
              <SimpleAutocompleteValidator
                className={classes.inputDescription}
                isLoading={isLoading}
                label="Description"
                name="description"
                onChange={handleAccessorialAutoCompleteChange}
                options={Object.values(ACCESSORIAL_DESCRIPTION_CHOICES)}
                value={accessorial.description}
                required
              />
            </div>
          </div>
          <div className={classes.assessorialDetail}>
            <div className={classes.assessorialDetailItem}>
              <TextValidator
                name="quantity"
                className={classes.inputWithHeader}
                disabled={isLoading}
                inputProps={{ min: '0', step: '1', type: 'number' }}
                label="Quantity"
                onChange={handleAccessorialChange}
                required
                validators={['required']}
                errorMessages={['This field is required.']}
                size="small"
                value={accessorial.quantity}
                variant="outlined"
              />
            </div>
          </div>

          <div className={classes.assessorialDetail}>
            <div className={classes.assessorialDetailItem}>
              <TextValidator
                name="revenue"
                className={classes.inputWithHeader}
                disabled={isLoading}
                inputProps={{ min: '0', step: '0.01', type: 'number' }}
                label="Accessorial Revenue"
                onChange={handleAccessorialChange}
                required
                validators={['required']}
                errorMessages={['This field is required.']}
                size="small"
                value={accessorial.revenue}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>

        {accessorialDetailsOpen && (
          <div>
            <div className={classes.assessorialDetailRow}>
              <div className={classes.assessorialDetailLeg}>
                <div className={classes.assessorialDetailLegItem}>
                  <Typography
                    className={classes.assessorialDetailItemName}
                    variant="body2"
                  >
                    Order #
                  </Typography>
                </div>
              </div>
              {withCheckBox && (
                <div className={classes.assessorialDetailLeg}>
                  <div className={classes.assessorialDetailLegItem}>
                    <Typography
                      className={classes.assessorialDetailItemName}
                      variant="body2"
                    >
                      Apply To
                    </Typography>
                  </div>
                </div>
              )}
              <div className={classes.assessorialDetailLeg}>
                <div className={classes.assessorialDetailLegItem}>
                  <Typography
                    className={classes.assessorialDetailItemName}
                    variant="body2"
                  >
                    DM-ID
                  </Typography>
                </div>
              </div>
            </div>

            {orderLegs
              .sort((a, b) => sortOrderLeg(a, b))
              .map((orderLeg) => (
                <AccessorialLegForm
                  key={orderLeg.idW}
                  idW={accessorial.idW || ''}
                  isLoading={isLoading}
                  dmId={getDmidFromOrderLeg(
                    orderLeg,
                    orderLegs,
                    loads,
                    manifestNumber,
                  )}
                  accessorial={accessorial}
                  orderLeg={orderLeg}
                  load={getLoadFromOrderLeg(orderLeg)}
                  onAccessorialChange={onAccessorialChange}
                  withCheckBox={withCheckBox}
                />
              ))}

            <div className={classes.assessorialDetailRow}>
              <TextValidator
                name="notes"
                className={classes.input}
                disabled={isLoading}
                label="Notes"
                onChange={handleAccessorialChange}
                size="small"
                fullWidth
                value={accessorial.notes}
                variant="outlined"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    margin: theme.spacing(0, 3),
    padding: theme.spacing(2, 0),
  },
  accessorialContainer: {
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  assessorialDetailRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  assessorialDetail: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  assessorialDetailItem: {},
  assessorialDetailLeg: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  assessorialDetailLegItem: {
    minWidth: 80,
  },
  assessorialDetailItemName: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
  },
  assessorialDetailItemValue: {
    color: 'black',
    fontWeight: 500,
    fontSize: 16,
  },
  input: {
    margin: theme.spacing(1, 1, 1.5),
    minWidth: 140,
  },
  inputWithHeader: {
    minWidth: 140,
  },
  inputDescription: {
    minWidth: 250,
  },
  notes: {
    margin: theme.spacing(2, 1, 1.5),
  },
  assessorialHeader: {
    alignItems: 'center',
    backgroundColor: '#C8D9E4',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  assessorialHeaderName: {
    flex: 1,
    color: '#0D374B',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  removeBtn: {
    color: colors.primary[600],
    marginLeft: theme.spacing(3),
  },
  removeName: {
    textTransform: 'uppercase',
    color: '#4F4F4F',
    textDecorationLine: 'underline',
  },
  expandButton: {
    color: colors.primary[600],
    marginLeft: theme.spacing(3),
  },
  expandIcon: {},
}));

export default React.memo(AccessorialDetailForm);
