import { Container, makeStyles } from '@material-ui/core';
import { LOAD_STATUS } from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import { GPSPositionData, LatLng } from 'doc-mate-store/lib/models/GPSPosition';
import { OrderLegData } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import CountdownTimer from '../../components/CountdownTimer';
import DriverPath from '../../components/DriverPath';
import LoadAppBar from '../../components/LoadAppBar';
import LoadStatusAppBar from '../../components/LoadStatusAppBar';
import RefreshNow from '../../components/RefreshNow';
import RouteMap from '../../components/RouteMap';
import TrackerAppBar from '../../components/TrackerAppBar';
import TrackerLoadDetail from '../../components/TrackerLoadDetail';
import Root from '../Root';

type Props = {};

const LOAD_REFRESH_INTERVAL = 60;

const Track: React.FC<Props> = () => {
  const { rootStore } = useStores();
  // @ts-ignore jus: might need to upgrade to react-router v6
  const { orderLegLinkId } = useParams();
  const classes = useStyles();
  const [path, setPath] = useState<GPSPositionData[]>([]);
  const leg = rootStore.trackedLeg;

  const [loadStatus, setLoadStatus] = useState<OrderLegData | null | undefined>(
    undefined,
  );
  const { me, loadStops: loadStops_ } = rootStore;
  const user = me ? me.maybeCurrent : undefined;
  const isDispatcher = user && user.isDispatcher;
  const isBroker = user && user.isBroker;
  const legStatus = leg ? leg.status : LOAD_STATUS.UNASSIGNED;

  const legStops =
    leg && leg.order && leg.order.isValid && !!leg.order.current.orderStops
      ? Array.from(leg.order.current.orderStops.values())
      : [];

  useEffect(() => {
    (async () => {
      if (orderLegLinkId) {
        const result = await rootStore.fetchOrderLegByOrderLegLinkId(
          orderLegLinkId,
        );

        setLoadStatus(result);
      }
    })();
    return () => {
      rootStore.resetOrderLegLinkId();
    };
  }, [rootStore, orderLegLinkId]);

  useEffect(() => {
    if (leg) {
      leg.scheduleUpdateETA();
    }

    return () => {
      if (leg) {
        leg.clearUpdateETA();
      }
    };
  }, [leg, legStatus, rootStore]);

  const driverPosition = path.length > 0 ? path[path.length - 1] : undefined;
  if (driverPosition && leg) {
    leg.setCurrentGPSPosition(driverPosition);
  }
  const loadStops: string[] = Array.from(loadStops_.keys());
  const loadStopsJ = JSON.stringify(loadStops);

  const route = useMemo(() => {
    const result: LatLng[] = [];
    if (!leg) {
      return [];
    }
    if (driverPosition) {
      result.push({
        latitude: driverPosition.latitude,
        longitude: driverPosition.longitude,
      });
    }
    if (leg.pickup && leg.pickup.isValid) {
      const { latlng } = leg.pickup.current;
      if (!!leg.pickupDepartedOn) {
        result.unshift(latlng);
      } else {
        result.push(latlng);
      }
    }

    // if (leg.order && leg.order.isValid && !!leg.order.current.orderStops) {
    //   const stops = Array.from(leg.order.current.orderStops.values());
    //   stops.forEach(stop => {
    //     if (stop.stopNumber !== 1 || stop.stopNumber !== stops.length + 1) {
    //       result.push(stop.latlng);
    //     }
    //   });
    // }
    if (leg.dropoff && leg.dropoff.isValid) {
      const { latlng } = leg.dropoff.current;
      result.push(latlng);
    }
    return result;
  }, [driverPosition, leg, loadStopsJ]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefreshLoad = useCallback(() => {
    if (leg) {
      rootStore.fetchOrderLegByOrderLegLinkId(leg.orderLegLinkId);
    }
  }, [leg, rootStore]);

  const handlePathInsert = useCallback((additionalPath: GPSPositionData[]) => {
    setPath(prevPath => [...prevPath, ...additionalPath]);
  }, []);

  const handlePathReset = useCallback((newPath: GPSPositionData[]) => {
    setPath(newPath);
  }, []);

  useEffect(() => {
    if (leg) {
      handleRefreshLoad();
    }
  }, [handleRefreshLoad, leg]);

  if (
    !leg ||
    !rootStore.loaded ||
    typeof loadStatus === 'undefined' ||
    (leg &&
      (!leg.pickup ||
        !leg.pickup.isValid ||
        !leg.dropoff ||
        !leg.dropoff.isValid))
  ) {
    return (
      <Root>
        <Helmet>
          <title>
            {`Doc-Mate `}
            {isDispatcher ? 'Dispatcher' : isBroker ? 'Brokerage' : 'Tracker'}
          </title>
          <meta name="apple-itunes-app" content="app-id=1490860416" />
        </Helmet>
        <TrackerAppBar subtitle="Tracker" me={user} />
        <Container>Loading&hellip;</Container>
      </Root>
    );
  }

  const renderTopBar = () => {
    if (!user) {
      return null;
    }
    return <TrackerAppBar subtitle="Tracker" me={user} />;
  };

  const renderMapAreaBar = () => {
    if (user) {
      return <LoadAppBar loadId={leg.dmId} showBackButton />;
    }
    return (
      <TrackerAppBar subtitle="Tracker" me={user}>
        <LoadAppBar loadId={leg.dmId} flex={2} />
      </TrackerAppBar>
    );
  };

  const renderLoadArea = () => {
    return (
      <div className={classes.loadArea}>
        <LoadStatusAppBar
          sticky
          loadStatus={leg.displayStatus}
          onTimeStatus={leg.onTimeStatus}
        />
        {user ? (
          <RefreshNow onRefresh={handleRefreshLoad} />
        ) : (
          <CountdownTimer
            defaultTime={LOAD_REFRESH_INTERVAL}
            onTimeElapsed={handleRefreshLoad}
          />
        )}
        <TrackerLoadDetail load={leg} />
      </div>
    );
  };

  return (
    <Root>
      <Helmet>
        <title>
          {`Manifest # ${leg.dmId} | Doc-Mate `}
          {isDispatcher ? 'Dispatcher' : isBroker ? 'Brokerage' : 'Tracker'}
        </title>
      </Helmet>

      <div className={classes.container}>
        {renderTopBar()}
        <div
          className={[
            classes.content,
            user ? classes.contentLoggedIn : undefined,
          ].join(' ')}
        >
          <div className={classes.mapArea}>
            {renderMapAreaBar()}
            {leg.driver && leg.driver.isValid && (
              <DriverPath
                load={leg}
                onPathInsert={handlePathInsert}
                onPathReset={handlePathReset}
              />
            )}
            <RouteMap
              className={classes.map}
              driverPosition={
                leg.status !== LOAD_STATUS.COMPLETED
                  ? driverPosition
                  : undefined
              }
              driverPath={path}
              // dropoffLocation={
              //   leg.dropoffLocation && leg.dropoffLocation.maybeCurrent
              // }
              // pickupLocation={
              //   leg.pickupLocation && leg.pickupLocation.maybeCurrent
              // }
              // shipperStop={leg.pickup && leg.pickup.maybeCurrent}
              // consigneeStop={leg.dropoff && leg.dropoff.maybeCurrent}
              stops={legStops}
              route={route}
            />
          </div>
          {renderLoadArea()}
        </div>
      </div>
    </Root>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
    },
  },
  content: {
    flexGrow: 1,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  contentLoggedIn: {
    height: 'calc(100% - 48px)',
    marginTop: theme.spacing(6),
    '& $mapArea': {
      height: 'calc(80% - 48px)',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      '& $mapArea': {
        height: '100%',
      },
    },
  },
  loadArea: {
    background: 'white',
    position: 'absolute',
    top: '80%',
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      flex: 1,
      height: '100%',
      overflow: 'auto',
      maxWidth: 480,
      minWidth: 480,
      position: 'static',
    },
  },
  loadDetailArea: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
  mapArea: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    height: '80%',
    width: '100%',
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'static',
      height: '100%',
    },
  },
  map: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  refresh: {
    background: theme.palette.grey[100],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}));

export default observer(Track);
