// https://codesandbox.io/s/laughing-boyd-zo5h0

import { Button, debounce, makeStyles } from '@material-ui/core';
import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

type Props = Omit<
  TimePickerProps,
  'value' | 'onChange' | 'labelFunc' | 'onBegin' | 'shouldClose' | 'revertValue'
> & {
  value: [MaterialUiPickersDate, MaterialUiPickersDate];
  onChange: (values: { date: MaterialUiPickersDate }) => void;
  toggleBegin: (toggle: boolean) => void;
  onBegin: boolean;
  shouldClose: boolean;
  revertValue: () => void;
};

const TimeRangePicker: React.FC<Props> = ({
  value,
  onChange,
  toggleBegin,
  onBegin,
  shouldClose,
  revertValue,
  format,
  emptyLabel,
  autoOk,
  onOpen,
  onClose,
  open: openForward,
  ...props
}) => {
  const classes = useStyles();
  const [begin, setBegin] = useState<MaterialUiPickersDate>(value[0]);
  const [end, setEnd] = useState<MaterialUiPickersDate>(value[1]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBegin(value[0]);
    setEnd(value[1]);
  }, [value]);

  const labelFunc = useCallback(() => {
    if (!!begin && !!end) {
      return `${moment(begin).format('HH:00')} - ${moment(end).format(
        'HH:00',
      )} `;
    } else {
      return '';
    }
  }, [begin, end]);

  const onChangeValue = useCallback(
    (date: any) => {
      onChange({ date });
    },
    [onChange],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetHour = useCallback(debounce(onChangeValue, 100), [
    onChangeValue,
  ]);

  return (
    <TimePicker
      {...props}
      className={clsx(classes.root)}
      autoOk
      ampm={false}
      views={['hours']}
      initialFocusedDate={moment().startOf('day')}
      value={onBegin ? begin : end}
      open={open && !shouldClose}
      variant="inline"
      onOpen={() => {
        setOpen(true);
        onOpen && onOpen();
      }}
      // onClose={() => {
      //   !!shouldClose && setOpen(true);
      // }}
      onChange={date => {
        debounceSetHour(date);
      }}
      labelFunc={labelFunc}
      ToolbarComponent={() => (
        <div className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <div className={classes.timeTextContainer}>
              <div className={classes.timeText}>TIME:</div>
            </div>
            <Button
              onClick={() => {
                toggleBegin(true);
              }}
              classes={{
                containedSecondary: classes.secondary,
              }}
              variant="contained"
              color={onBegin ? 'secondary' : 'primary'}
              disableElevation
            >
              <div className={classes.timeText}>
                {!!begin ? moment(begin).format('HH:00') : '00:00'}
              </div>
            </Button>
            <div className={classes.timeSeparator}> - </div>
            <Button
              onClick={() => {
                toggleBegin(false);
              }}
              classes={{
                containedSecondary: classes.secondary,
              }}
              color={onBegin ? 'primary' : 'secondary'}
              variant="contained"
              disableElevation
            >
              <div className={classes.timeText}>
                {!!end ? moment(end).format('HH:00') : '00:00'}
              </div>
            </Button>
          </div>
        </div>
      )}
      PopoverProps={{
        onEscapeKeyDown: () => {
          setOpen(false);
          revertValue();
        },
        onBackdropClick: () => {
          setOpen(false);
          revertValue();
        },
      }}
    />
  );
};

export const useStyles = makeStyles(
  theme => {
    return {
      root: {
        marginTop: theme.spacing(1),
      },
      toolbar: {
        background: theme.palette.primary.main,
        height: 100,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      toolbarHeader: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      },
      timeTextContainer: {
        paddingRight: theme.spacing(4),
      },
      timeText: {
        fontSize: 20,
        color: 'white',
      },
      timeSeparator: {
        fontSize: 20,
        color: 'white',
        paddingRight: 5,
        paddingLeft: 5,
      },
      secondary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: 'rgba(77, 133, 167, 0.75)',
        '&:hover': {
          backgroundColor: 'rgba(77, 133, 167, 0.75)',
        },
        '@media (hover: none)': {
          backgroundColor: 'rgba(77, 133, 167, 0.75)',
        },
      },
    };
  },
  { name: 'TimeRangePicker' },
);

export default TimeRangePicker;
