import {
  Divider,
  DividerProps,
  makeStyles,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import React from 'react';

type Props = DividerProps & {
  text?: string;
  textProps?: TypographyProps;
};

const DividerWithText: React.FC<Props> = ({
  text,
  textProps,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider className={[className, classes.divider].join(' ')} {...rest} />
      {text && <Typography {...textProps}>{text}</Typography>}
      {text && (
        <Divider className={[className, classes.divider].join(' ')} {...rest} />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    height: 2,
    flex: 1,
  },
}));

export default DividerWithText;
