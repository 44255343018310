import { makeStyles, Popover } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import LoadDriverCellFields from './LoadDriverCellFields';

type Props = {
  cell: {
    value: [string, string, number, boolean, number[]];
  };
};

const LoadDriverSelect: React.FC<Props> = props => {
  const { cell } = props;
  const {
    value: [driverName, driverId, loadId, isDispatcher, linkedLegs],
  } = cell;

  const classes = useStyles();
  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handlePopUpClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  // useEffect(() => {
  //   if (driverId) {
  //     loadDriverInfo(driverId);
  //   }
  // }, [driverName, driverId]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <span className={classes.content} onClick={handleClick}>
        <LoadDriverCellFields
          driverName={driverName}
          driverId={driverId}
          loadId={loadId}
          isDispatcher={isDispatcher}
          linkedLegs={linkedLegs}
        />
      </span>
      <Popover
        id={`${loadId}`}
        open={copy}
        anchorEl={anchorEl}
        onClose={handlePopUpClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    backgroundColor: 'transparent',
    '&&&& input': {
      paddingLeft: 5,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  dropdown: {
    '& .Mui-focused': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
      backgroundColor: 'white',
      borderRadius: 4,
    },
  },
  driverInfo: {
    width: 450,
    backgroundColor: '#155878',
    position: 'absolute',
    left: 0,
    marginLeft: '75%',
    color: 'white',
    padding: '29px 30px',
    borderRadius: 5,
    'z-index': 10,
    '& img': {
      height: 50,
      width: 50,
      borderRadius: 25,
    },
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
  content: {
    paddingTop: 10,
    paddingBottom: 30,
  },
}));

export default React.memo(observer(LoadDriverSelect));
