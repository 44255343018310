import { FilterType } from '../../types/filtering';
import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import SelectFilter from './SelectFilter';
import TextFilter from './TextFilter';

export function getFilterComponent(
  type: FilterType,
): React.FC<any> | undefined {
  switch (type) {
    case 'text':
      return TextFilter;
    case 'select':
    case 'selectMultiple':
      return SelectFilter;
    case 'date':
      return DateFilter;
    case 'boolean':
      return BooleanFilter;
  }
  return undefined;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DateFilter,
  SelectFilter,
  TextFilter,
};
