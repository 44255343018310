import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

type Props = {
  autoFocus?: boolean;
  className?: string;
  onLogin: (username: string, password: string) => Promise<{ errors: any }>;
};

const LoginForm: React.FC<Props> = ({ autoFocus, className, onLogin }) => {
  const classes = useStyles();
  const passwordRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  function handleChangeEmail(event: any) {
    setEmail(event.target.value);
    setError(null);
  }

  function handleChangePassword(event: any) {
    setPassword(event.target.value);
    setError(null);
  }

  async function handleSubmit() {
    setError(null);
    setLoading(true);
    const { errors } = await onLogin(email, password);
    setLoading(false);
    if (errors && errors.detail) {
      setError(errors.detail);
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
      setPassword('');
    }
  }

  return (
    <ValidatorForm className={className} onSubmit={handleSubmit}>
      <Typography variant="h3" component="h2" className={classes.prompt}>
        Log in to your account
      </Typography>
      <TextValidator
        id="email"
        name="email"
        autoFocus={autoFocus}
        className={classes.input}
        fullWidth
        label="Username or email address"
        onChange={handleChangeEmail}
        validators={['required']}
        errorMessages={[
          'This field is required.',
          'Enter a valid email address.',
        ]}
        size="small"
        value={email}
        variant="outlined"
      />
      <TextValidator
        inputRef={passwordRef}
        id="password"
        name="password"
        className={classes.input}
        error={!!error}
        fullWidth
        helperText={error || undefined}
        label="Password"
        onChange={handleChangePassword}
        required
        validators={['required']}
        errorMessages={['This field is required.']}
        size="small"
        type="password"
        value={password}
        variant="outlined"
      />
      <Button
        className={classes.submit}
        color="primary"
        endIcon={
          loading ? <CircularProgress size={14} color="secondary" /> : undefined
        }
        fullWidth
        type="submit"
        variant="contained"
      >
        Log In
      </Button>
    </ValidatorForm>
  );
};

const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: theme.spacing(3),
  },
  prompt: {
    marginBottom: theme.spacing(4),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}));

export default LoginForm;
