import { InputAdornment, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { colors } from '../../themes';
import { BillingFormData } from './types';

type Props = {
  idW: string;
  isLoading: boolean;
  dmId: string;
  loadDetails: {
    orderNumber: string | undefined;
    loadDescription: string | undefined;
    billOfLadingNumber: string;
  };
  showPercentage: boolean;
  billing: BillingFormData;
  driver: string;
  onBillingChange: (id: string, name: string, value: any) => void;
};

const BillingFormLeg: React.FC<Props> = ({
  idW,
  isLoading,
  dmId,
  loadDetails,
  showPercentage,
  billing,
  driver,
  onBillingChange,
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onBillingChange(idW, name, value);
    },
    [idW, onBillingChange],
  );
  if (!loadDetails) {
    return null;
  }
  return (
    <div>
      <div className={classes.billingDetailRow}>
        <div className={classes.billingOrder}>
          <div className={classes.billingDetailItem}>
            <Typography
              className={classes.billingDetailItemName}
              variant="body2"
            >
              Order #
            </Typography>
            <Typography
              className={classes.billingDetailItemValue}
              variant="body2"
            >
              {loadDetails.orderNumber || ''}
            </Typography>
          </div>
        </div>

        <div className={classes.billingDetail}>
          <div className={classes.billingDetailDMID}>
            <Typography
              className={classes.billingDetailItemName}
              variant="body2"
            >
              DM-ID
            </Typography>
            <Typography
              className={classes.billingDetailItemValue}
              variant="body2"
            >
              {dmId}
            </Typography>
          </div>
        </div>
        <div className={classes.billingDetail}>
          <div className={classes.billingDetailItem}>
            <Typography
              className={classes.billingDetailItemName}
              variant="body2"
            >
              Description
            </Typography>
            <Typography className={classes.billingDescription} variant="body2">
              {loadDetails.loadDescription || '-'}
            </Typography>
          </div>
        </div>
        {showPercentage && (
          <div className={classes.billingDetail}>
            <div className={classes.billingDetailItem}>
              <TextValidator
                name="percentage"
                className={classes.input}
                disabled={isLoading}
                inputProps={{
                  min: '0',
                  max: '100',
                  step: '0.01',
                  type: 'number',
                }}
                label="Drvr Revenue"
                onChange={handleChange}
                required={showPercentage}
                size="small"
                fullWidth
                value={billing.percentage}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <div className={classes.billingDetail}>
          <div className={classes.billingDetailItem}>
            <Typography
              className={classes.billingDetailItemName}
              variant="body2"
            >
              Driver
            </Typography>
            <Typography
              className={classes.billingDetailItemValue}
              variant="body2"
            >
              {driver || '-'}
            </Typography>
          </div>
        </div>
        <div className={classes.billingDetail}>
          <div className={classes.billingDetailItem}>
            <Typography
              className={classes.billingDetailItemName}
              variant="body2"
            >
              BOL
            </Typography>
            <Typography
              className={classes.billingDetailItemValue}
              variant="body2"
            >
              {loadDetails.billOfLadingNumber || '-'}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.billingDetailRow}>
        <TextValidator
          name="notes"
          className={classes.notes}
          disabled={isLoading}
          label="Notes"
          onChange={handleChange}
          size="small"
          fullWidth
          value={billing.notes}
          variant="outlined"
        />
      </div>

      {/* </div> */}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadContainer: {
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  billingDetailRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  loadHeader: {
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  loadHeaderName: {
    color: 'black',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  loadName: {
    color: colors.textPrimary[600],
    textTransform: 'uppercase',
  },
  loadId: {
    color: 'black',
    marginLeft: theme.spacing(0.5),
  },
  billingOrder: {
    display: 'flex',
    padding: theme.spacing(2),
    width: '10%',
  },
  billingDetail: {
    display: 'flex',
    padding: theme.spacing(2),
    width: '20%',
  },
  billingDetailItem: {
    flex: 1,
  },
  billingDetailDMID: {
    minWidth: 180,
  },
  billingDetailItemName: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  billingDetailItemValue: {
    color: 'black',
    fontWeight: 500,
    fontSize: 16,
  },
  billingDescription: {
    color: 'black',
    fontSize: 14,
    fontWeight: 500,
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  },
  input: {
    margin: theme.spacing(0, 1, 1.5),
    minWidth: 140,
  },
  inputWithHeader: {
    minWidth: 140,
  },
  notes: {
    margin: theme.spacing(0, 1, 1.5),
    width: '100%',
  },
}));

export default React.memo(BillingFormLeg);
