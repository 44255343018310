import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { ColumnInstance } from 'react-table';
import { useWebStores } from '../../hooks';
import { FilterValue, IFilterBoolean } from '../../types/filtering';

interface IFilterBooleanProps extends IFilterBoolean {
  className?: string;
  disabled?: boolean;
  value: FilterValue;
  setValue(value: FilterValue): void;
  type: 'boolean';

  column?: ColumnInstance;
}

const SelectFilter: React.FC<IFilterBooleanProps> = ({
  accessor,
  disabled,
  label,
  className,
  value,
  setValue,
  column,
}) => {
  const { webStore } = useWebStores();
  const { columnFilters } = webStore;
  const filters = {
    ...columnFilters!.filters,
  };

  const defaultValue = columnFilters && column ? filters[column.id] : '';

  const labelId = column
    ? `column-filter-${column.id}-label`
    : `filter-${accessor}-label`;
  const id = column ? `column-filter-${column.id}-id` : `filter-${accessor}-id`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = [
    {
      name: 'Yes',
      value: 'true',
    },
    {
      name: 'No',
      value: 'false',
    },
  ];

  let val = '';
  if (column) {
    val = (column.filterValue || '').toString();
  } else {
    val = (value || '').toString();
  }

  useEffect(() => {
    if (column && defaultValue) {
      column.setFilter(defaultValue);
    }
  }, [defaultValue, column]);

  const renderValue = useCallback(
    (selected: unknown) => {
      const v = options.find(
        item => item.value.toString() === (selected as string).toString(),
      );
      if (v) {
        return v.name;
      }
      return '';
    },
    [options],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newValue = event.target.value as string;
      if (column) {
        if (newValue === val) {
          column.setFilter('');
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: '',
          });
        } else {
          column.setFilter(newValue);
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: newValue,
          });
        }
      } else {
        if (newValue === val) {
          setValue('');
        } else {
          setValue(newValue);
        }
      }
    },
    [column, setValue, val, columnFilters],
  );

  return (
    <FormControl
      className={className}
      key={accessor}
      fullWidth
      size="small"
      variant="outlined"
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        disabled={disabled}
        value={val}
        renderValue={renderValue}
        onChange={handleChange}
      >
        {options.map(item => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={val === item.value} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default observer(SelectFilter);
