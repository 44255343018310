import { observer } from 'mobx-react-lite';
import React from 'react';
import { Row } from 'react-table';
import { DateFilterValue, IFilterDate } from '../../types/filtering';
import TimeFilterWrapper from './TimeFilterWrapper';

interface IFilterDateProps extends IFilterDate {
  className?: string;
  disabled?: boolean;
  value: DateFilterValue;
  setValue(value?: DateFilterValue): void;

  column?: {
    filterValue: DateFilterValue;
    filteredRows: Row[];
    id: string;
    prefilteredRows: Row[];
    setFilter(value?: DateFilterValue): void;
  };
}

const TimeFilter: React.FC<IFilterDateProps> = ({
  accessor,
  disabled,
  label,
  className,
  value,
  setValue,
  type,
  column,
}) => {
  return (
    <TimeFilterWrapper
      accessor={accessor}
      disabled={disabled}
      label={label}
      className={className}
      value={value}
      setValue={setValue}
      type={type}
      column={column}
    />
  );
};

export default React.memo(observer(TimeFilter));
