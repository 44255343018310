import MomentUtils from '@date-io/moment';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useStores } from 'doc-mate-store/lib/hooks';
import { LocationAddressData } from 'doc-mate-store/lib/models/Location';
import moment from 'moment';
import React, { forwardRef, useCallback, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { colors } from '../../themes';
import AutocompleteValidator from '../AutocompleteField/AutocompleteValidator';
import LocationAutocompleteField from '../AutocompleteField/LocationAutocompleteField';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import { stateChoices } from './constants';
import { LoadStopFormData } from './types';

type Props = {
  idW: string;
  data: LoadStopFormData;
  orderW: string;
  isShipper: boolean;
  isConsignee: boolean;
  isMidStop: boolean;
  isLoading: boolean;
  onLoadStopChange: (
    orderW: string,
    id: string,
    name: string,
    value: any,
  ) => void;
};

const RefTextField = (props: any, ref: any) => (
  <TextField {...props} inputRef={ref} />
);

const ForwardRefTextField = forwardRef(RefTextField);

const LoadStopForm: React.FC<Props> = ({
  idW,
  data,
  orderW,
  isShipper = false,
  isConsignee = false,
  isMidStop = false,
  isLoading,
  onLoadStopChange,
}) => {
  const { rootStore } = useStores();
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const classes = useStyles();

  const handleContactNameChange = useCallback(
    async (name: string, value: string) => {
      const data: LocationAddressData = await rootStore.fetchLocationAddressData(
        value,
      );
      const state = stateChoices.find(state => state.value === data.state);
      onLoadStopChange(orderW, idW, name, value);
      if (state) {
        onLoadStopChange(orderW, idW, 'street1', data.street1);
        onLoadStopChange(orderW, idW, 'street2', data.street2);
        onLoadStopChange(orderW, idW, 'city', data.city);
        onLoadStopChange(orderW, idW, 'state', state || null);
        onLoadStopChange(orderW, idW, 'zipCode', data.zipCode);
      }
    },
    [rootStore, orderW, idW, onLoadStopChange],
  );

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onLoadStopChange(orderW, idW, name, value);
    },
    [orderW, idW, onLoadStopChange],
  );

  const handleBooleanFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onLoadStopChange(orderW, idW, name, value === 'true');
    },
    [orderW, idW, onLoadStopChange],
  );

  const handleLocationStateChange = useCallback(
    (name: string, value: AutocompleteFieldOption) => {
      onLoadStopChange(orderW, idW, name, value);
    },
    [orderW, idW, onLoadStopChange],
  );

  const handleContactNumberChange = useCallback(
    (value?: string) => {
      onLoadStopChange(orderW, idW, 'phoneNumber', value || '');
      if (value && !isPossiblePhoneNumber(value)) {
        setContactNumberError('Please enter a valid number.');
      } else {
        setContactNumberError('');
      }
    },
    [orderW, idW, onLoadStopChange],
  );

  const handlePickupWindowLowerChange = useCallback(
    (date: MaterialUiPickersDate) => {
      let window = {
        ...data.pickupWindow,
        lower: date,
      };
      if (date) {
        const upperDate = moment(date).add(30, 'minutes');
        window = {
          ...window,
          upper: upperDate,
        };
      }
      onLoadStopChange(orderW, idW, 'pickupWindow', window);
    },
    [data, orderW, idW, onLoadStopChange],
  );

  const handlePickupWindowUpperChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const window = {
        ...data.pickupWindow,
        upper: date,
      };
      onLoadStopChange(orderW, idW, 'pickupWindow', window);
    },
    [data.pickupWindow, orderW, idW, onLoadStopChange],
  );

  const handleDropoffWindowLowerChange = useCallback(
    (date: MaterialUiPickersDate) => {
      let window = {
        ...data.dropoffWindow,
        lower: date,
      };
      if (date) {
        const upperDate = moment(date).add(30, 'minutes');
        window = {
          ...window,
          upper: upperDate,
        };
      }
      onLoadStopChange(orderW, idW, 'dropoffWindow', window);
    },
    [data, idW, orderW, onLoadStopChange],
  );

  const handleDropoffWindowUpperChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const window = {
        ...data.dropoffWindow,
        upper: date,
      };
      onLoadStopChange(orderW, idW, 'dropoffWindow', window);
    },
    [data, orderW, idW, onLoadStopChange],
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.loadStopColumn1}>
        <LocationAutocompleteField
          className={classes.input}
          freeSolo
          fullWidth
          isLoading={isLoading}
          label={isMidStop ? 'Stop' : isShipper ? 'Shipper' : 'Consignee'}
          name="contactName"
          onChange={handleContactNameChange}
          required
          value={data.contactName}
        />
        <TextValidator
          name="street1"
          className={classes.input}
          disabled={isLoading}
          fullWidth
          inputProps={{ autoComplete: 'new-password' }}
          label="Address Line 1"
          onChange={handleFieldChange}
          required
          validators={['required']}
          errorMessages={['This field is required.']}
          size="small"
          value={data.street1}
          variant="outlined"
        />
        <TextValidator
          name="street2"
          className={classes.input}
          disabled={isLoading}
          fullWidth
          inputProps={{ autoComplete: 'new-password' }}
          label="Address Line 2"
          onChange={handleFieldChange}
          size="small"
          value={data.street2}
          variant="outlined"
        />
        <FormControl fullWidth>
          <div className={classes.flexInputGroup}>
            <TextValidator
              name="city"
              disabled={isLoading}
              fullWidth
              inputProps={{ autoComplete: 'new-password' }}
              label="City"
              onChange={handleFieldChange}
              required
              validators={['required']}
              errorMessages={['This field is required.']}
              size="small"
              value={data.city}
              variant="outlined"
            />
            <p>&nbsp;</p>
            <AutocompleteValidator
              fullWidth
              isLoading={isLoading}
              label="State"
              name="state"
              onChange={handleLocationStateChange}
              options={stateChoices}
              value={data.state}
            />
          </div>
        </FormControl>
        <FormControl fullWidth>
          <div className={classes.flexInputGroup}>
            <TextValidator
              name="zipCode"
              disabled={isLoading}
              fullWidth
              inputProps={{ autoComplete: 'new-password' }}
              label="Zip Code"
              onChange={handleFieldChange}
              required
              validators={['required']}
              errorMessages={['This field is required.']}
              size="small"
              value={data.zipCode}
              variant="outlined"
            />
            <p>&nbsp;</p>
            <PhoneInput
              className={classes.input}
              country="US"
              disabled={isLoading}
              error={Boolean(contactNumberError)}
              helperText={contactNumberError}
              fullWidth
              inputComponent={ForwardRefTextField}
              label="Contact Number"
              onChange={handleContactNumberChange}
              size="small"
              value={data.phoneNumber}
              variant="outlined"
            />
          </div>
        </FormControl>
      </div>
      <div className={classes.loadStopColumn2}>
        <FormControl fullWidth>
          <FormLabel className={classes.inputLabel}>
            {isShipper ? 'Pickup Date & Time' : 'Dropoff Date & Time'}
          </FormLabel>
          <div className={classes.flexInputGroup}>
            <DateTimePicker
              name="windowLower"
              autoOk
              ampm={false}
              disabled={isLoading}
              InputProps={{
                className: classes.loadStopDateTimeInput,
                autoComplete: 'off',
                placeholder: 'mm/dd/yyyy 00:00',
              }}
              format="MM/DD/YYYY HH:mm"
              fullWidth
              hideTabs
              inputVariant="outlined"
              onChange={
                isShipper
                  ? handlePickupWindowLowerChange
                  : handleDropoffWindowLowerChange
              }
              size="small"
              value={
                isShipper ? data.pickupWindow.lower : data.dropoffWindow.lower
              }
              variant="inline"
              required
            />

            <Typography variant="body2">TO</Typography>
            <DateTimePicker
              name="windowUpper"
              autoOk
              ampm={false}
              disabled={isLoading}
              InputProps={{
                className: classes.loadStopDateTimeInput,
                autoComplete: 'off',
                placeholder: 'mm/dd/yyyy 00:00',
              }}
              format="MM/DD/YYYY HH:mm"
              fullWidth
              hideTabs
              inputVariant="outlined"
              minDate={
                (isShipper
                  ? data.pickupWindow.lower
                  : data.dropoffWindow.lower) || undefined
              }
              onChange={
                isShipper
                  ? handlePickupWindowUpperChange
                  : handleDropoffWindowUpperChange
              }
              size="small"
              value={
                isShipper ? data.pickupWindow.upper : data.dropoffWindow.upper
              }
              variant="inline"
              required
            />
          </div>
        </FormControl>
        {!isShipper && !isConsignee && (
          <FormControl fullWidth>
            <FormLabel className={classes.inputLabel}>
              {'Pickup Date & Time'}
            </FormLabel>
            <div className={classes.flexInputGroup}>
              <DateTimePicker
                name="pickupWindowLower"
                autoOk
                ampm={false}
                disabled={isLoading}
                InputProps={{
                  className: classes.loadStopDateTimeInput,
                  autoComplete: 'off',
                  placeholder: 'mm/dd/yyyy 00:00',
                }}
                format="MM/DD/YYYY HH:mm"
                fullWidth
                hideTabs
                inputVariant="outlined"
                onChange={handlePickupWindowLowerChange}
                size="small"
                value={data.pickupWindow.lower}
                variant="inline"
                required
              />

              <Typography variant="body2">TO</Typography>
              <DateTimePicker
                name="pickupWindowUpper"
                autoOk
                ampm={false}
                disabled={isLoading}
                InputProps={{
                  className: classes.loadStopDateTimeInput,
                  autoComplete: 'off',
                  placeholder: 'mm/dd/yyyy 00:00',
                }}
                format="MM/DD/YYYY HH:mm"
                fullWidth
                hideTabs
                inputVariant="outlined"
                minDate={data.pickupWindow.lower || undefined}
                onChange={handlePickupWindowUpperChange}
                size="small"
                value={data.pickupWindow.upper}
                variant="inline"
                required
              />
            </div>
          </FormControl>
        )}
        <FormControl fullWidth>
          <FormLabel className={classes.inputLabel}>
            Appointment Required?
          </FormLabel>
          <RadioGroup
            className={classes.radioGroup}
            name="appointmentRequired"
            onChange={handleBooleanFieldChange}
            value={data.appointmentRequired}
          >
            <FormControlLabel
              disabled={isLoading}
              value={true}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              disabled={isLoading}
              value={false}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        {(isShipper || isMidStop) && (
          <FormControl fullWidth>
            <FormLabel className={classes.inputLabel}>
              Is self load allowed?
            </FormLabel>
            <RadioGroup
              className={classes.radioGroup}
              name="canSelfLoad"
              onChange={handleBooleanFieldChange}
              value={data.canSelfLoad}
            >
              <FormControlLabel
                disabled={isLoading}
                value={true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                disabled={isLoading}
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        )}
        {(isConsignee || isMidStop) && (
          <FormControl fullWidth>
            <FormLabel className={classes.inputLabel}>
              Is self unload allowed?
            </FormLabel>
            <RadioGroup
              className={classes.radioGroup}
              name="canSelfUnload"
              onChange={handleBooleanFieldChange}
              value={data.canSelfUnload}
            >
              <FormControlLabel
                disabled={isLoading}
                value={true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                disabled={isLoading}
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(theme => ({
  loadStopColumn1: {
    flex: 1,
    paddingRight: theme.spacing(1.5),
  },
  loadStopColumn2: {
    flex: 1,
    paddingLeft: theme.spacing(1.5),
  },
  loadStopDateTimeInput: {
    paddingRight: '5px',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  radioGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1.5),
  },
  flexInputGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(1.5),

    '& > p': {
      margin: theme.spacing(1.5),
    },
  },
}));

export default React.memo(LoadStopForm);
