import { makeStyles, Popover } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  cell: {
    value: string;
  };
};

const Text: React.FC<Props> = ({ cell: { value } }) => {
  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigator.clipboard.writeText(value);
      setAnchorEl(event.currentTarget);
      setCopy(true);
    },
    [value],
  );

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  return (
    <>
      <span onClick={handleClick}>{value}</span>
      <Popover
        id={value}
        open={copy}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default observer(Text);
