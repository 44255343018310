import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { User } from 'doc-mate-store/lib/models';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

type Props = {
  user: User;
};

const UserMenu: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();

  const [
    accountButtonAnchorEl,
    setAccountButtonAnchorEl,
  ] = useState<HTMLButtonElement | null>(null);

  function handleAccountButtonClick(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    setAccountButtonAnchorEl(event.currentTarget);
  }

  function handleAccountButtonClose() {
    setAccountButtonAnchorEl(null);
  }

  function handleLogout() {
    history.push('/logout');
  }

  return (
    <>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        className={classes.button}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleAccountButtonClick}
      >
        {user.fullName || user.username}
      </Button>
      <Menu
        anchorEl={accountButtonAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        onClose={handleAccountButtonClose}
        open={!!accountButtonAnchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id="menu-appbar"
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
  },
}));

export default UserMenu;
