"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationAddressData = exports.locationRef = exports.Location = void 0;
var mobx_1 = require("mobx");
var mobx_keystone_1 = require("mobx-keystone");
var Location = /** @class */ (function (_super) {
    __extends(Location, _super);
    function Location() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id); };
        return _this;
    }
    Object.defineProperty(Location.prototype, "isFourStarServices", {
        get: function () {
            return this.name === 'Four Star Services';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Location.prototype, "fullAddress", {
        get: function () {
            var fullAddress = [
                this.street1,
                this.street2,
                this.city,
                this.state,
                this.zipCode,
            ];
            return fullAddress.filter(function (part) { return part.length > 0; }).join(', ');
        },
        enumerable: false,
        configurable: true
    });
    Location.prototype.update = function (data) {
        Object.assign(this, data);
    };
    Object.defineProperty(Location.prototype, "latlng", {
        // Convert coordinates to LatLng type.
        get: function () {
            return {
                latitude: this.coordinates.coordinates[1],
                longitude: this.coordinates.coordinates[0],
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.computed
    ], Location.prototype, "isFourStarServices", null);
    __decorate([
        mobx_1.computed
    ], Location.prototype, "fullAddress", null);
    __decorate([
        mobx_keystone_1.modelAction
    ], Location.prototype, "update", null);
    Location = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/Location')
    ], Location);
    return Location;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    name: (0, mobx_keystone_1.prop)(),
    locationType: (0, mobx_keystone_1.prop)(),
    street1: (0, mobx_keystone_1.prop)(),
    street2: (0, mobx_keystone_1.prop)(),
    city: (0, mobx_keystone_1.prop)(),
    state: (0, mobx_keystone_1.prop)(),
    zipCode: (0, mobx_keystone_1.prop)(),
    coordinates: (0, mobx_keystone_1.prop)(),
    businessHours: (0, mobx_keystone_1.prop)(),
    businessHoursDisplay: (0, mobx_keystone_1.prop)(),
    darkHours: (0, mobx_keystone_1.prop)(),
    amenities: (0, mobx_keystone_1.prop)(),
    safetyRequirements: (0, mobx_keystone_1.prop)(),
    contacts: (0, mobx_keystone_1.prop)(),
    medianPickupDetentionTime: (0, mobx_keystone_1.prop)(),
    medianDropoffDetentionTime: (0, mobx_keystone_1.prop)(),
    isFourStar: (0, mobx_keystone_1.prop)(),
})));
exports.Location = Location;
exports.locationRef = (0, mobx_keystone_1.rootRef)('doc-mate/LocationRef', {
    getId: function (target) {
        if (target instanceof Location) {
            return "".concat(target.id);
        }
    },
});
var getLocationAddressData = function (loc) {
    if (!loc) {
        return {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zipCode: '',
        };
    }
    return {
        street1: loc.street1,
        street2: loc.street2,
        city: loc.city,
        state: loc.state,
        zipCode: loc.zipCode,
    };
};
exports.getLocationAddressData = getLocationAddressData;
