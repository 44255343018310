import {
  Checkbox,
  Divider,
  ListItemText,
  makeStyles,
  MenuItem,
  Popover,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { getChipDisplayClass } from 'doc-mate-store/lib/constants/load';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { SingleFilterValue } from '../../types/filtering';
type Props = {
  anchorEl: HTMLButtonElement | null;
  columnId: string;
  isSortedDesc?: boolean;
  options: { name: string; value: any }[];
  value: string[];
  onClose: () => void;
  onFilter: (filters: string[]) => void;
  onSortBy: (desc: boolean | null) => void;
};

const SelectClearContainer = styled('div')({
  display: 'flex',
  paddingTop: '9px',
  textAlign: 'center',
});

const SelectClearText = styled(ListItemText)({
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },
});

const OptionsContainer = styled('div')({
  maxHeight: '400px',
  overflow: 'auto',
});

const StatusColorIndicator = styled('div')({
  display: 'inline-block',
  height: '12px',
  width: '12px',
  borderRadius: '1px',
  marginRight: '5px',

  '&.unassigned': {
    backgroundColor: '#F38686',
    color: 'white',
  },
  '&.pickup': {
    backgroundColor: '#FADFA3',
  },
  '&.dropoff': {
    backgroundColor: '#A6E7C1',
  },
  '&.delivered': {
    backgroundColor: '#CAA2EE',
  },
  '&.completed': {
    backgroundColor: '#ACD5F0',
  },
  '&.cancelled': {
    backgroundColor: '#BDBDBD',
  },
});

const TextAndSelectFilterMenu: React.FC<Props> = ({
  anchorEl,
  columnId,
  isSortedDesc,
  options,
  value,
  onClose,
  onFilter,
  onSortBy,
}) => {
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const isSortedAsc = !isSortedDesc && isSortedDesc !== undefined;
  const [filterValue, setFilterValue] = useState(value);

  const handleSortAsc = useCallback(() => {
    if (isSortedAsc) {
      onSortBy(null);
    } else {
      onSortBy(false);
    }
  }, [onSortBy, isSortedAsc]);

  const handleSortDesc = useCallback(() => {
    if (isSortedDesc) {
      onSortBy(null);
    } else {
      onSortBy(true);
    }
  }, [onSortBy, isSortedDesc]);

  const handleFilter = useCallback(
    (item: string) => {
      if (filterValue.includes(item)) {
        setFilterValue(prev => prev.filter(val => val !== item));
      } else {
        setFilterValue(prev => [...prev, item]);
      }
    },
    [filterValue],
  );
  const handleConfirmFilter = useCallback(() => {
    onFilter(filterValue);
  }, [filterValue, onFilter]);

  const handleSelectAll = useCallback(() => {
    const values = options.map(item => item.name);
    onFilter(values);
  }, [onFilter, options]);

  const handleClear = useCallback(() => {
    onFilter([]);
  }, [onFilter]);

  const handleClose = useCallback(() => {
    setFilterValue(value);
    onClose();
  }, [onClose, value]);
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          minWidth: '150px',
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem key="sort-desc" onClick={handleSortAsc}>
        <ListItemText primary="Sort A to Z" />
      </MenuItem>
      <MenuItem key="sort-asc" onClick={handleSortDesc}>
        <ListItemText primary="Sort Z to A" />
      </MenuItem>
      <Divider />
      <SelectClearContainer>
        <SelectClearText primary="Select all" onClick={handleSelectAll} />
        <SelectClearText primary="Clear" onClick={handleClear} />
      </SelectClearContainer>
      <OptionsContainer>
        {options.map(item => (
          <MenuItem key={item.value} onClick={() => handleFilter(item.name)}>
            <Checkbox
              checked={
                (filterValue as SingleFilterValue[]).indexOf(item.name) > -1
              }
            />
            {columnId === 'status__in' && (
              <StatusColorIndicator
                className={getChipDisplayClass(item.value)}
              />
            )}
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </OptionsContainer>
      <MenuItem className={classes.button} onClick={handleConfirmFilter}>
        FILTER
      </MenuItem>
    </Popover>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: 15,
    fontWeight: 700,
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    color: 'white',
    backgroundColor: '#155878',
    '&:hover': {
      backgroundColor: '#0D374B',
    },
  },
}));

export default React.memo(observer(TextAndSelectFilterMenu));
