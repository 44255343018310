import MomentUtils from '@date-io/moment';
import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect } from 'react';
import { Row } from 'react-table';
import { useWebStores } from '../../hooks';
import { DateFilterValue, IFilterDate } from '../../types/filtering';
import DateRangePicker from '../DateRangePicker';
interface IFilterDateProps extends IFilterDate {
  className?: string;
  disabled?: boolean;
  value: DateFilterValue;
  setValue(value?: DateFilterValue): void;

  column?: {
    filterValue: DateFilterValue;
    filteredRows: Row[];
    id: string;
    prefilteredRows: Row[];
    setFilter(value?: DateFilterValue): void;
  };
}

const DateFilterWrapper: React.FC<IFilterDateProps> = ({
  accessor,
  disabled,
  label,
  className,
  value,
  setValue,
  column,
}) => {
  const { webStore } = useWebStores();
  const { columnFilters } = webStore;
  const filters = {
    ...columnFilters!.filters,
  };

  const defaultValue = columnFilters && column ? filters[column.id] : '';
  const classes = useStyles();
  const id = column ? `column-filter-${column.id}-id` : `filter-${accessor}-id`;
  const { from, to } = (column && column.filterValue) ||
    value ||
    (!!defaultValue && {
      from: moment(defaultValue.from),
      to: moment(defaultValue.to),
    }) || { from: undefined, to: undefined };

  useEffect(() => {
    if (defaultValue && column) {
      column.setFilter({
        from: from.startOf('day'),
        to: to.endOf('day'),
      });
    }
  }, [defaultValue, column, from, to]);

  const handleChange = useCallback(
    ({ begin: from, end: to }: { begin: any; end: any }) => {
      if (to && from) {
        if (column) {
          column.setFilter({
            from: from.startOf('day'),
            to: to.endOf('day'),
          });
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: {
              from: from.startOf('day').toString(),
              to: to.endOf('day').toString(),
            },
          });
        } else {
          setValue({
            from: from.startOf('day'),
            to: to.endOf('day'),
          });
        }
      }
    },
    [column, setValue, columnFilters],
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <DateRangePicker
          autoOk
          disabled={disabled}
          key={[from, to].toString()}
          className={className}
          format="MM/DD/YYYY"
          fullWidth
          id={id}
          inputVariant="outlined"
          label={label}
          margin="normal"
          onChange={handleChange}
          size="small"
          style={{ margin: 0 }}
          value={[from as Moment, to as Moment]}
          variant="inline"
          clearable
        />
        {column && (
          <IconButton
            className={classes.clear}
            onClick={() => {
              column ? column.setFilter(undefined) : setValue(undefined);
              columnFilters!.setFilters({
                ...filters,
                [`${column.id}`]: undefined,
              });
            }}
            size="small"
            title="Clear"
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      minWidth: '100%',
    },
  },
  clear: {
    flexShrink: 0,
    marginLeft: theme.spacing(0.5),
  },
}));

export default React.memo(observer(DateFilterWrapper));
