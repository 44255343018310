// @ts-nocheck
// jus: has to ignore for now has <Hidden> as mui v5 is needed

import {
  AppBar,
  Hidden,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { User } from 'doc-mate-store/lib/models';
import React from 'react';
// import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { colors } from '../../themes';
import UserMenu from '../UserMenu';

type Props = {
  children?: React.ReactNode;
  me?: User;
  subtitle?: string;
};

const TrackerAppBar: React.FC<Props> = ({ children, me, subtitle }) => {
  const classes = useStyles();
  // const location = useLocation();
  const isDispatcher = me && me.isDispatcher;
  const isBroker = me && me.isBroker;
  // const isDriverPath = location.pathname === '/drivers';
  const subtitleText = isDispatcher
    ? 'Dispatcher'
    : isBroker
    ? 'Brokerage'
    : subtitle;

  return (
    <div className={classes.root}>
      <Hidden mdDown={!!children}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar variant="dense">
            <div className={classes.logo}>
              <Link to="/" className={classes.logoLink}>
                <Typography variant="h3" noWrap className={classes.logoText}>
                  Doc-Mate{' '}
                  {subtitle && (
                    <span className={classes.subtitle}>{subtitleText}</span>
                  )}
                </Typography>
              </Link>
            </div>
            {/***{isDispatcher && (
              <div className={classes.nav}>
                <Link to="/" className={classes.navLink}>
                  <Typography
                    className={`${classes.navText} ${
                      !isDriverPath ? classes.navTextActive : ''
                    }`}
                    noWrap
                    variant="h4"
                  >
                    Loads
                  </Typography>
                </Link>
                <Link to="/drivers" className={classes.navLink}>
                  <Typography
                    className={`${classes.navText} ${
                      isDriverPath ? classes.navTextActive : ''
                    }`}
                    noWrap
                    variant="h4"
                  >
                    Drivers
                  </Typography>
                </Link>
              </div>
            )}***/}
            {!children && me && <UserMenu user={me} />}
          </Toolbar>
        </AppBar>
      </Hidden>
      {children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  appBar: {
    backgroundColor: colors.primary[900],
    flex: 1,
    minWidth: '100vw',
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      minWidth: 480,
      position: 'static',
    },
  },
  logo: {
    flexGrow: 1,
  },
  logoLink: {
    textDecoration: 'none',
    '&:link, &:visited': {
      color: 'white',
    },
  },
  logoText: {
    display: 'inline-block',
  },
  subtitle: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 0.98,
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 500,
    letterSpacing: theme.spacing(0.25),
    textTransform: 'uppercase',
  },
  nav: {
    flexGrow: 1,
    textAlign: 'center',
  },
  navLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:link, &:visited': {
      color: 'white',
    },
  },
  navText: {
    display: 'inline-block',
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  navTextActive: {
    color: theme.palette.secondary.main,
  },
}));

export default TrackerAppBar;
