import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DataTableContext } from '../../contexts';
import { colors } from '../../themes';
import { getFilterComponent } from '../filters';

type Props = {};

const FilterActive: React.FC<Props> = () => {
  const classes = useStyles();
  const {
    editable,
    filters,
    getValue,
    setValue,
    deleteValue,
    clearValues,
    visibleFilters,
    handleUpdateFilters,
  } = useContext(DataTableContext);

  const [showFilterAdd, setShowFilterAdd] = useState(false);

  const sortedFilters = useMemo(
    () => sortBy(filters, f => visibleFilters.indexOf(f.accessor)),
    [filters, visibleFilters],
  );

  const handleShowFilterToggle = useCallback(() => {
    setShowFilterAdd(prev => !prev);
  }, []);

  const handleSelectFilter = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const accessor = event.target.value as string;
      setValue(accessor)(undefined);
      setShowFilterAdd(false);
    },
    [setValue],
  );

  const handleFilterRemove = useCallback(
    (accessor: string) => {
      deleteValue(accessor);
    },
    [deleteValue],
  );

  const handleFilterClear = () => clearValues();

  if (!editable && visibleFilters.length === 0) {
    return null;
  }

  return (
    <>
      <Typography component="h3" variant="subtitle1" className={classes.header}>
        Active Filters ({visibleFilters.length})
      </Typography>

      {sortedFilters.map(filter => {
        if (visibleFilters.indexOf(filter.accessor) < 0) {
          return null;
        }
        let Component = getFilterComponent(filter.type);
        if (Component) {
          return (
            <div key={filter.accessor} className={classes.filterRow}>
              <Component
                {...filter}
                disabled={!editable}
                type={filter.type}
                value={getValue(filter.accessor)}
                setValue={setValue}
              />
              {editable && (
                <IconButton
                  aria-label="cancel adding filter"
                  className={classes.filterRowButton}
                  size="small"
                  onClick={() => handleFilterRemove(filter.accessor)}
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              )}
            </div>
          );
        }

        return null;
      })}

      {editable && !showFilterAdd && (
        <div
          className={[classes.filterRow, classes.filterRowAddFilter].join(' ')}
        >
          <Button
            size="small"
            startIcon={<AddIcon />}
            onClick={handleShowFilterToggle}
            className={[
              classes.filterRowAddFilterButton,
              classes.addFilter,
            ].join(' ')}
          >
            Add Filter
          </Button>
          {visibleFilters.length > 0 && (
            <Button
              size="small"
              onClick={handleFilterClear}
              className={[
                classes.filterRowAddFilterButton,
                classes.clearFilters,
              ].join(' ')}
            >
              Clear
            </Button>
          )}
        </div>
      )}

      {editable && showFilterAdd && (
        <div className={classes.filterRow}>
          <FormControl
            className={classes.filterRowControl}
            size="small"
            variant="outlined"
          >
            <InputLabel id="add-filter-label">Filter By</InputLabel>
            <Select
              id="add-filter"
              labelId="add-filter-label"
              onChange={handleSelectFilter}
              defaultValue=""
            >
              {filters
                .filter(filter => visibleFilters.indexOf(filter.accessor) < 0)
                .map(filter => (
                  <MenuItem key={filter.accessor} value={filter.accessor}>
                    {filter.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <IconButton
            aria-label="cancel adding filter"
            className={classes.filterRowButton}
            size="small"
            onClick={handleShowFilterToggle}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </div>
      )}

      {editable && (
        <Button
          className={classes.updateFilterButton}
          size="small"
          color="primary"
          variant="contained"
          onClick={handleUpdateFilters}
        >
          Update Filters
        </Button>
      )}

      <Divider className={classes.divider} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  addFilter: {
    color: colors.primary[600],
  },
  clearFilters: {
    color: colors.danger[500],
  },
  divider: {
    margin: theme.spacing(2, -3),
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  filterRowAddFilter: {
    marginBottom: -theme.spacing(1),
    justifyContent: 'space-between',
  },
  filterRowAddFilterButton: {},
  filterRowDeleteTable: {
    justifyContent: 'flex-end',
  },
  filterRowButton: {
    flexGrow: 0,
  },
  filterRowControl: {
    flexGrow: 1,
  },
  header: {
    color: colors.primary[900],
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  updateFilterButton: {
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
}));

export default observer(FilterActive);
