import { Backdrop, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useWebStores } from '../../hooks';

type Props = {};

const Logout: React.FC<Props> = () => {
  const { webStore } = useWebStores();
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    (async () => {
      await webStore.load();
      await webStore.logout();
      setLoggedOut(true);
    })();
  }, [webStore]);

  if (!loggedOut) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return <Redirect to="/" />;
};

export default observer(Logout);
