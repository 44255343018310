"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayAction = exports.getDisplayStatus = exports.getTransitions = exports.SCHEDULE_TRANSITION = exports.getNextStatus = exports.NEXT_SWIPE_ROW_STATUS = exports.getRowStatus = exports.ROW_STATUS = void 0;
var ROW_STATUS;
(function (ROW_STATUS) {
    ROW_STATUS["INACTIVE"] = "inactive";
    ROW_STATUS["ACTIVE"] = "active";
    ROW_STATUS["ARRIVED"] = "arrived";
    ROW_STATUS["DONE"] = "done";
})(ROW_STATUS = exports.ROW_STATUS || (exports.ROW_STATUS = {}));
var getRowStatus = function (status) {
    switch (status) {
        case 'inactive':
            return ROW_STATUS.INACTIVE;
        case 'active':
            return ROW_STATUS.ACTIVE;
        case 'arrived':
            return ROW_STATUS.ARRIVED;
        case 'done':
            return ROW_STATUS.DONE;
    }
    return ROW_STATUS.INACTIVE;
};
exports.getRowStatus = getRowStatus;
exports.NEXT_SWIPE_ROW_STATUS = (_a = {},
    _a[ROW_STATUS.INACTIVE] = ROW_STATUS.ACTIVE,
    // [ROW_STATUS.ACTIVE]: ROW_STATUS.DONE,
    _a[ROW_STATUS.ACTIVE] = ROW_STATUS.ARRIVED,
    _a[ROW_STATUS.ARRIVED] = ROW_STATUS.DONE,
    _a[ROW_STATUS.DONE] = null,
    _a);
var getNextStatus = function (status) {
    return exports.NEXT_SWIPE_ROW_STATUS[status] || null;
};
exports.getNextStatus = getNextStatus;
var SCHEDULE_TRANSITION;
(function (SCHEDULE_TRANSITION) {
    SCHEDULE_TRANSITION["START_TRAVEL"] = "start_travel";
    SCHEDULE_TRANSITION["ARRIVE"] = "arrive";
    SCHEDULE_TRANSITION["COMPLETE"] = "complete";
})(SCHEDULE_TRANSITION = exports.SCHEDULE_TRANSITION || (exports.SCHEDULE_TRANSITION = {}));
var getTransitions = function (status, hasNextStop) {
    switch (status) {
        case ROW_STATUS.INACTIVE:
            return [{ fn: SCHEDULE_TRANSITION.START_TRAVEL }];
        case ROW_STATUS.ACTIVE:
            return [{ fn: SCHEDULE_TRANSITION.ARRIVE }];
        case ROW_STATUS.ARRIVED:
            return [{ fn: SCHEDULE_TRANSITION.COMPLETE }];
        // case ROW_STATUS.DONE:
        //   return [];
        case ROW_STATUS.DONE:
            //   if (hasNextStop) {
            //     return [{ fn: SCHEDULE_TRANSITION.START_TRAVEL }];
            //   } else return [{ fn: SCHEDULE_TRANSITION.COMPLETE }];
            return [{ fn: SCHEDULE_TRANSITION.COMPLETE }];
        default:
            return [];
    }
};
exports.getTransitions = getTransitions;
var getDisplayStatus = function (status) {
    switch (status) {
        case ROW_STATUS.INACTIVE:
            return 'Inactive';
        case ROW_STATUS.ACTIVE:
            return 'Active';
        case ROW_STATUS.ARRIVED:
            return 'Arrived';
        case ROW_STATUS.DONE:
            return 'DONE';
        default:
            return status;
    }
};
exports.getDisplayStatus = getDisplayStatus;
var getDisplayAction = function (transitions) {
    if (!transitions || transitions.length === 0) {
        return '';
    }
    switch (transitions && transitions[0].fn) {
        case SCHEDULE_TRANSITION.ARRIVE:
            return 'ARRIVE';
        case SCHEDULE_TRANSITION.START_TRAVEL:
            return 'START TRAVEL';
        case SCHEDULE_TRANSITION.COMPLETE:
            return 'COMPLETE';
        default:
            return '';
    }
};
exports.getDisplayAction = getDisplayAction;
