import { makeStyles } from '@material-ui/core';
import { Location } from 'doc-mate-store/lib/models';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import React from 'react';
import LocationWidget from '../LocationWidget';
import NextArrow from './NextArrow.svg';

interface Props {
  className?: string;
  style?: {};
  load: OrderLeg;
  showLabel?: boolean;
  onLocationPress?: (location: Location) => void;
}

const LoadLocation: React.FC<Props> = ({ className, style, load, ...rest }) => {
  const classes = useStyles();
  const className_ = [className ? className : '', classes.container].join(' ');

  return (
    <div className={className_} style={style}>
      {load.pickupLocation &&
        load.pickup &&
        load.pickupLocation.isValid &&
        load.dropoff.isValid && (
          <LocationWidget
            label="Pick-up Location"
            location={load.pickupLocation.current}
            loadStop={load.pickup.current}
            isTimestampEta={!load.pickupArrivedOn}
            timestamp={
              load.pickupArrivedOn ||
              (load.etas && load.etas.pickupEta && load.etas.pickupEta.eta) ||
              undefined
            }
            window={load.pickupWindow}
            {...rest}
          />
        )}
      <img src={NextArrow} className={classes.divider} alt="Arrow" />
      {load.dropoffLocation &&
        load.dropoffLocation.isValid &&
        load.pickupLocation &&
        load.pickupLocation.isValid &&
        load.dropoff &&
        load.dropoff.isValid && (
          <LocationWidget
            label="Drop-off Location"
            location={load.dropoffLocation.current}
            loadStop={load.dropoff.current}
            isTimestampHidden={
              !load.pickupLocation ||
              !load.pickupLocation.isValid ||
              !load.pickupLocation.current.medianPickupDetentionTime
            }
            isTimestampEta={!load.dropoffArrivedOn}
            timestamp={
              load.dropoffArrivedOn ||
              (load.etas && load.etas.dropoffEta && load.etas.dropoffEta.eta) ||
              undefined
            }
            window={load.dropoffWindow}
            {...rest}
          />
        )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  divider: {
    display: 'block',
    width: 14,
    height: 40,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    userSelect: 'none',
  },
}));

export default observer(LoadLocation);
