"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.scheduleRowRef = exports.ScheduleRow = void 0;
var mobx_keystone_1 = require("mobx-keystone");
var driverSchedule_1 = require("../constants/driverSchedule");
var DriverSchedule_1 = require("./DriverSchedule");
var ScheduleRow = /** @class */ (function (_super) {
    __extends(ScheduleRow, _super);
    function ScheduleRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id, "-").concat(_this.isProposed); };
        return _this;
    }
    ScheduleRow_1 = ScheduleRow;
    Object.defineProperty(ScheduleRow.prototype, "displayStatus", {
        get: function () {
            return (0, driverSchedule_1.getDisplayStatus)(this.status);
        },
        enumerable: false,
        configurable: true
    });
    ScheduleRow.prototype.update = function (data, isProposed) {
        var rootStore = (0, mobx_keystone_1.getRootStore)(this);
        if (!rootStore) {
            return;
        }
        var result = ScheduleRow_1._formatData(rootStore, data, isProposed);
        if (result) {
            Object.assign(this, result);
        }
    };
    Object.defineProperty(ScheduleRow.prototype, "latlng", {
        // Convert coordinates to LatLng type.
        get: function () {
            return {
                latitude: this.coordinates.coordinates[1],
                longitude: this.coordinates.coordinates[0],
            };
        },
        enumerable: false,
        configurable: true
    });
    var ScheduleRow_1;
    ScheduleRow._formatData = function (rootStore, data, isProposed) {
        var scheduleRow = __assign({}, data);
        try {
            scheduleRow.schedule = (0, DriverSchedule_1.driverScheduleRef)("".concat(data.schedule, "-").concat(isProposed));
            scheduleRow.isProposed = isProposed;
            scheduleRow.locationName = data.locationName ? data.locationName : '';
            scheduleRow.address = data.address ? data.address : '';
            scheduleRow.rowNumber = data.rowNumber;
            scheduleRow.completedOn = data.completedOn ? data.completedOn : '';
            if (data.coordinates) {
                scheduleRow.coordinates = data.coordinates;
            }
            if (data.status) {
                scheduleRow.status = (0, driverSchedule_1.getRowStatus)(data.status);
            }
            // console.log('PICKUP STOPS', data.pickupStops);
            // if (data.pickupStops) {
            //   const rows = data.pickupStops;
            // .map(stop => {
            //   // const newStop = LoadStop.create(rootStore, stop);
            //   // if (newStop) {
            //   // const newStop = rootStore.loadStops.get(`${stop}`);
            //   // return newStop;
            //   // }
            //   // return null;
            // })
            // .filter(stop => !!stop) as number[];
            // scheduleRow.pickupStops = new ArraySet({ items: rows });
            // }
            // console.log('DROPOFF STOPS', data.dropoffStops);
            // if (data.dropoffStops) {
            //   const rows = data.dropoffStops;
            // .map(stop => {
            //   // const newStop = LoadStop.create(rootStore, stop);
            //   // if (newStop) {
            //   // const newStop = rootStore.loadStops.get(`${stop}`);
            //   // return newStop;
            //   // }
            //   // return null;
            // })
            // .filter(stop => !!stop) as number[];
            // scheduleRow.dropoffStops = new ArraySet({ items: rows });
            // }
            return scheduleRow;
        }
        catch (error) {
            console.warn('[WARN] error', error);
            return null;
        }
    };
    ScheduleRow.create = function (rootStore, data, isProposed) {
        var result = ScheduleRow_1._formatData(rootStore, data, isProposed);
        if (result) {
            var newScheduleRow = new ScheduleRow_1(result);
            return newScheduleRow;
        }
        else {
            return null;
        }
    };
    __decorate([
        mobx_keystone_1.modelAction
    ], ScheduleRow.prototype, "update", null);
    ScheduleRow = ScheduleRow_1 = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/ScheduleRow')
    ], ScheduleRow);
    return ScheduleRow;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    schedule: (0, mobx_keystone_1.prop)(),
    locationName: (0, mobx_keystone_1.prop)(),
    address: (0, mobx_keystone_1.prop)(),
    coordinates: (0, mobx_keystone_1.prop)(),
    rowNumber: (0, mobx_keystone_1.prop)(),
    pickupStops: (0, mobx_keystone_1.prop)(),
    dropoffStops: (0, mobx_keystone_1.prop)(),
    proposedPickupStops: (0, mobx_keystone_1.prop)(),
    proposedDropoffStops: (0, mobx_keystone_1.prop)(),
    status: (0, mobx_keystone_1.prop)(),
    isProposed: (0, mobx_keystone_1.prop)(),
    completedOn: (0, mobx_keystone_1.prop)(),
})));
exports.ScheduleRow = ScheduleRow;
exports.scheduleRowRef = (0, mobx_keystone_1.rootRef)('doc-mate/ScheduleRowRef', {
    getId: function (target) {
        if (target instanceof ScheduleRow) {
            return "".concat(target.id, "-").concat(target.isProposed);
        }
    },
});
