import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ON_TIME_STATUS,
  ON_TIME_STATUS_SHORT_TEXT,
} from 'doc-mate-store/lib/constants/load';
import DateTimeRange from 'doc-mate-store/lib/models/DateTimeRange';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { onTimeChipStatusColors } from '../../themes';
import TimelineItem from './TimelineItem';
import TimelinePath from './TimelinePath';

type Props = {
  date?: Date | string | null;
  etaDate?: Date | string | null;
  extraRender?(active: boolean, textClassName?: string): ReactNode;
  hidden?: boolean;
  hiddenTooltip?: string;
  isLastItem?: boolean;
  isNextItemActive?: boolean;
  prevDate?: Date | string | null;
  statusText: string;
  window?: DateTimeRange;
  onTimeStatus?: ON_TIME_STATUS;
};

const LoadStatusTimelineItem: React.FC<Props> = props => {
  const {
    date,
    etaDate,
    extraRender,
    hidden,
    hiddenTooltip,
    isLastItem,
    isNextItemActive,
    prevDate,
    statusText,
    onTimeStatus,
    window,
  } = props;
  const classes = useStyles(props);
  const active = !!date;

  const mEtaDate = etaDate ? moment(etaDate) : null;

  const mDate = date ? moment(date) : null;
  const mPrevDate = prevDate ? moment(prevDate) : null;
  let format = 'MM-DD HH:mm';
  const mDateComp = mDate || mEtaDate;
  if (mPrevDate && mDateComp) {
    if (mDateComp.format('MM-DD') === mPrevDate.format('MM-DD')) {
      format = 'HH:mm';
    }
  }

  let dateText;
  if (hidden) {
    dateText = (
      <div className={classes.dateContainer}>
        {!!onTimeStatus && (
          <Typography className={[classes.dateTag].join(' ')}>
            {ON_TIME_STATUS_SHORT_TEXT[onTimeStatus]}
          </Typography>
        )}

        {hiddenTooltip && (
          <Typography className={[classes.date, classes.extraText].join(' ')}>
            {hiddenTooltip}
          </Typography>
        )}
      </div>
    );
  } else {
    let isLate = false;
    if (window && !mDate && mEtaDate && mEtaDate > moment(window.upper)) {
      isLate = true;
    }

    dateText = (
      <div className={classes.dateContainer}>
        {!!onTimeStatus && (
          <Typography className={[classes.dateTag].join(' ')}>
            {ON_TIME_STATUS_SHORT_TEXT[onTimeStatus]}
          </Typography>
        )}
        <Typography
          variant="body1"
          className={[
            isLate ? classes.late : '',
            classes.date,
            active ? classes.active : '',
          ].join(' ')}
        >
          {mDate ? (
            mDate.format(format)
          ) : mEtaDate ? (
            `ETA: ${mEtaDate.format(format)}`
          ) : (
            <Typography
              variant="body1"
              className={[
                classes.date,
                active ? classes.active : '',
                hiddenTooltip ? classes.extraRender : '',
              ].join(' ')}
            >
              ETA: calculating&hellip;
            </Typography>
          )}
        </Typography>
        {hiddenTooltip && (
          <Typography className={[classes.date, classes.extraText].join(' ')}>
            {hiddenTooltip}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.dateBlock}>{dateText}</div>
      <div className={classes.path}>
        <TimelineItem active={active} />
        {!isLastItem && <TimelinePath active={isNextItemActive} />}
      </div>
      <div
        className={[
          classes.statusContainer,
          isLastItem ? classes.last : '',
        ].join(' ')}
      >
        <Typography
          className={[
            active ? classes.active : '',
            classes.statusText,
            !!extraRender ? classes.extraRender : '',
          ].join(' ')}
        >
          {statusText}
        </Typography>
        {extraRender
          ? extraRender(
              active,
              [classes.statusText, classes.extraText].join(' '),
            )
          : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  dateTag: (props: Props) => ({
    textAlign: 'center',
    fontSize: 8,
    textTransform: 'uppercase',
    width: 60,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    borderRadius: 16,
    backgroundColor: !!props.onTimeStatus
      ? onTimeChipStatusColors(theme)[props.onTimeStatus]
      : 'white',
    color: !!props.onTimeStatus
      ? theme.palette.getContrastText(
          onTimeChipStatusColors(theme)[props.onTimeStatus],
        )
      : 'black',
  }),
  active: {
    color: `${theme.palette.text.primary} !important`,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  date: {
    color: theme.palette.text.primary,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    textAlign: 'right',
  },
  dateBlock: {
    flex: 1,
  },
  extraRender: {
    marginBottom: theme.spacing(0.5),
  },
  extraText: {
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 500,
    fontSize: theme.typography.fontSize,
  },
  last: {
    minHeight: 'inherit !important',
  },
  late: {
    color: `${theme.palette.error.main} !important`,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
  },
  path: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    marginBottom: -1,
    minWidth: 40,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  statusContainer: {
    flex: 1,
    minHeight: 75,
    paddingBottom: 12,
  },
  statusText: {
    color: theme.palette.grey[500],
  },
}));

export default observer(LoadStatusTimelineItem);
