import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

type Props = {
  autoFocus?: boolean;
  className?: string;
  onResetPasswordConfirm: (
    token: string,
    password: string,
  ) => Promise<{ errors: any }>;
  onResetSuccess: () => void;
};

const ResetPasswordForm: React.FC<Props> = ({
  autoFocus,
  className,
  onResetPasswordConfirm,
  onResetSuccess,
}) => {
  const classes = useStyles();
  const passwordRef = useRef<HTMLInputElement>(null);
  const [validation_token, setValidationToken] = useState('');
  const [new_password1, setNewPassword1] = useState('');
  const [new_password2, setNewPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    new_password1: '',
    new_password2: '',
    status: '',
  });

  function handleValidationToken(event: any) {
    setValidationToken(event.target.value);
  }

  function handleChangePassword1(event: any) {
    setNewPassword1(event.target.value);
  }

  function handleChangePassword2(event: any) {
    const new_value = event.target.value;
    setNewPassword2(new_value);
    let pw_error;
    if (new_password1 === new_value) {
      pw_error = { new_password2: '' };
    } else if (new_password1 !== new_value) {
      pw_error = { new_password2: 'Passwords do not match' };
    }
    setError({ ...error, ...pw_error });
  }

  async function handleSubmit() {
    setError({ ...error });
    setLoading(true);
    const { errors } = await onResetPasswordConfirm(
      validation_token,
      new_password1,
    );
    setLoading(false);
    if (errors && errors.status) {
      setError({ ...error, status: errors.status });
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    } else {
      onResetSuccess();
    }
  }

  return (
    <ValidatorForm className={className} onSubmit={handleSubmit}>
      <Typography variant="h3" component="h2" className={classes.prompt}>
        Reset Password
      </Typography>
      <TextValidator
        id="token"
        name="token"
        className={classes.input}
        error={error && error.status === 'notfound' && 'Invalid Token'}
        fullWidth
        helperText={
          (error && error.status === 'notfound' && 'Invalid Token') || undefined
        }
        label="Validation Token"
        onChange={handleValidationToken}
        required
        validators={['required']}
        errorMessages={['This field is required.']}
        size="small"
        type="number"
        value={validation_token}
        variant="outlined"
      />
      <TextValidator
        inputRef={passwordRef}
        id="new_password1"
        name="new_password1"
        className={classes.input}
        error={error && error.new_password1}
        fullWidth
        helperText={error.new_password1 || undefined}
        label="Password"
        onChange={handleChangePassword1}
        required
        validators={['required']}
        errorMessages={['This field is required.']}
        size="small"
        type="password"
        value={new_password1}
        variant="outlined"
      />
      <TextValidator
        id="new_password2"
        name="new_password2"
        className={classes.input}
        error={error && error.new_password2}
        fullWidth
        helperText={error.new_password2 || undefined}
        label="Confirm Password"
        onChange={handleChangePassword2}
        required
        validators={['required']}
        errorMessages={['This field is required.']}
        size="small"
        type="password"
        value={new_password2}
        variant="outlined"
      />
      <Button
        className={classes.submit}
        color="primary"
        endIcon={
          loading ? <CircularProgress size={14} color="secondary" /> : undefined
        }
        fullWidth
        type="submit"
        variant="contained"
      >
        Reset Password
      </Button>
    </ValidatorForm>
  );
};

const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: theme.spacing(3),
  },
  prompt: {
    marginBottom: theme.spacing(4),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}));

export default ResetPasswordForm;
