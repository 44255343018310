import { makeStyles, Popover } from '@material-ui/core';
import DateTimeRange from 'doc-mate-store/lib/models/DateTimeRange';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  cell: {
    value: DateTimeRange | null;
  };
};

const DateDisplay: React.FC<Props> = props => {
  const { cell } = props;
  const { value } = cell;
  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (value) {
        event.preventDefault();
        event.stopPropagation();
        navigator.clipboard.writeText(
          `${moment(value.lower).format('MM/DD/YY')}`,
        );
        setAnchorEl(event.currentTarget);
        setCopy(true);
      }
    },
    [value],
  );

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  if (!value || !value.lower) {
    return <span>Not provided</span>;
  }
  const lower = moment(value.lower);
  const display = `${lower.format('MM/DD/YY')}`;

  return (
    <>
      <span onClick={handleClick}>{display}</span>
      <Popover
        id={display}
        open={copy}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default observer(DateDisplay);
