import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useWebStores } from '../../hooks';

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
};

const LoadStore: React.FC<Props> = ({ children }) => {
  const { webStore } = useWebStores();

  useEffect(() => {
    (async () => {
      await webStore.load();
    })();
  }, [webStore]);

  return <>{children}</>;
};

export default observer(LoadStore);
