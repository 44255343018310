import { Button, darken, makeStyles, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useStores } from 'doc-mate-store/lib/hooks';
import React, { useCallback, useRef, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useWebStores } from '../../hooks';
import { colors } from '../../themes';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import InvoiceSection from './InvoiceSection';
import ManifestForm from './ManifestForm';
import OrderDetailsSection from './OrderDetailsSection';
import {
  AccessorialFormData,
  BillingFormData,
  ManifestFormData,
  OrderFormData,
  OrderLegFormData,
} from './types';

type Props = {
  manifestNumber: string;
  manifest: ManifestFormData;
  loadTypes: string[];
  locations: AutocompleteFieldOption[];
  quantityUnits: string[];
  weightUnits: string[];
  distanceUnits: string[];
  loads: OrderFormData[];
  // loadStops: LoadStopFormData[];
  orderLegs: OrderLegFormData[];
  billings: BillingFormData[];
  accessorials: AccessorialFormData[];
  onClose: (event: any, addAnother?: boolean) => void;
  onManifestChange: (name: string, value: any) => void;
  onOrderChange: (idW: string, name: string, value: any) => void;
  onAddOrder: () => void;
  onRemoveOrder: (idW: string) => void;
  onLoadStopChange: (
    orderW: string,
    idW: string,
    name: string,
    value: any,
  ) => void;
  onLoadStopAdd: (orderW: string) => void;
  onLoadStopRemove: (orderW: string, id: string) => void;
  onBillingChange: (idW: string, name: string, value: any) => void;
  onAccessorialChange: (idW: string, name: string, value: any) => void;
  onAddAccessorial: () => void;
  onRemoveAccessorial: (idW: string) => void;
  onSubmit: () => Promise<{ errors: any; ok: boolean }>;
  setSuccess: (isSuccess: boolean) => void;
};

const ManifestBuilderForm: React.FC<Props> = ({
  manifestNumber,
  manifest,
  loadTypes,
  locations,
  quantityUnits,
  weightUnits,
  distanceUnits,
  loads,
  // loadStops,
  orderLegs,
  billings,
  accessorials,
  onClose,
  onManifestChange,
  onOrderChange,
  onAddOrder,
  onRemoveOrder,
  onLoadStopChange,
  onLoadStopAdd,
  onLoadStopRemove,
  onBillingChange,
  onAccessorialChange,
  onAddAccessorial,
  onRemoveAccessorial,
  onSubmit,
  setSuccess,
}) => {
  const errorRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<ValidatorForm>(null);
  const manifestRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [allOrderDetailsOpen, setAllOrderDetailsOpen] = useState(true);
  const [invoiceDetailsOpen, setInvoiceDetailsOpen] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [addAnother, setAddAnother] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const classes = useStyles();
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const { updateManifestId } = rootStore;
  const { manifestCount, convertManifestCountToManifestNumber } = webStore;

  const handleSubmit = async (e: any, addAnother: boolean = false) => {
    setError([]);
    setLoading(true);
    const { errors, ok } = await onSubmit();
    setLoading(false);
    if (!ok && errors) {
      const errorList: string[] = [];
      const {
        manifest,
        orders,
        orderLegs,
        billings,
        accessorials,
        validateError = [],
        ...rest
      } = errors;
      if (validateError.length > 0) {
        validateError.forEach((e: string) => {
          errorList.push(e);
        });
      }
      Object.values(rest).forEach(e => {
        const error = e as string[];
        if (error && error.length > 0) {
          errorList.push(error[0]);
        }
      });
      setError(errorList);
      return;
    } else {
      setIsSuccess(true);
      setSuccess(true);
      setAddAnother(addAnother);
    }
  };

  const handleSuccess = useCallback(
    (e: any) => {
      setIsSuccess(false);
      setSuccess(false);
      onClose(e, addAnother);
    },
    [addAnother, onClose, setSuccess],
  );

  const handleSaveAndAddAnother = async (e: any) => {
    if (formRef.current) {
      const isValid = await formRef.current.isFormValid(false);
      if (isValid) {
        return await handleSubmit(e, true);
      }
    }
  };

  const handleExpandOrderDetails = () => {
    setAllOrderDetailsOpen(!allOrderDetailsOpen);
  };

  const handleExpandInvoiceDetails = useCallback(() => {
    setInvoiceDetailsOpen(prevState => !prevState);
  }, []);

  return (
    <>
      {isSuccess ? (
        <div>
          <Typography className={classes.confirmText}>
            Successfully {!!updateManifestId ? 'modified' : 'added'}{' '}
            <b>
              Manifest #
              {!!updateManifestId
                ? manifestNumber
                : convertManifestCountToManifestNumber(manifestCount - 1)}
            </b>
          </Typography>
          <div className={classes.confirmButton}>
            <Button
              className={`${classes.actionBtn} ${classes.saveBtn}`}
              disabled={loading}
              onClick={handleSuccess}
            >
              Confirm
            </Button>
          </div>
        </div>
      ) : (
        <ValidatorForm onSubmit={handleSubmit} ref={formRef}>
          <div className={classes.manifestSection} ref={manifestRef}>
            <ManifestForm
              isLoading={loading}
              locations={locations}
              manifestNumber={manifestNumber}
              manifest={manifest}
              onManifestChange={onManifestChange}
            />
          </div>
          <div className={classes.sectionHeader}>
            <Typography className={classes.sectionName} variant="h4">
              Order Details
            </Typography>
            <Button
              className={classes.expandButton}
              disabled={loading}
              onClick={handleExpandOrderDetails}
            >
              {allOrderDetailsOpen ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </Button>
          </div>
          <div className={classes.orderSection}>
            <OrderDetailsSection
              isLoading={loading}
              isAllOrderDetailsOpen={allOrderDetailsOpen}
              loads={loads}
              locations={locations}
              loadTypes={loadTypes}
              // loadStops={loadStops}
              quantityUnits={quantityUnits}
              weightUnits={weightUnits}
              distanceUnits={distanceUnits}
              onOrderChange={onOrderChange}
              onAddOrder={onAddOrder}
              onRemoveOrder={onRemoveOrder}
              onLoadStopChange={onLoadStopChange}
              onLoadStopAdd={onLoadStopAdd}
              onLoadStopRemove={onLoadStopRemove}
            />
          </div>
          <div className={classes.invoiceSectionHeader}>
            <Typography className={classes.sectionName} variant="h4">
              Invoicing
            </Typography>
            <Button
              className={classes.expandButton}
              disabled={loading}
              onClick={handleExpandInvoiceDetails}
            >
              {invoiceDetailsOpen ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </Button>
          </div>

          <div className={classes.invoiceSection}>
            <InvoiceSection
              isLoading={loading}
              manifestNumber={manifestNumber}
              weightUnits={weightUnits}
              orderLegs={orderLegs}
              loads={loads}
              accessorials={accessorials}
              billings={billings}
              manifest={manifest}
              invoiceDetailOpen={invoiceDetailsOpen}
              onManifestChange={onManifestChange}
              onBillingChange={onBillingChange}
              onAccessorialChange={onAccessorialChange}
              onAddAccessorial={onAddAccessorial}
              onRemoveAccessorial={onRemoveAccessorial}
            />
          </div>

          {error.length > 0 && (
            <Alert
              className={classes.errorAlert}
              ref={errorRef}
              severity="error"
            >
              <AlertTitle>Form Errors</AlertTitle>
              <ul>
                {error.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className={classes.actions}>
            <Button
              className={`${classes.actionBtn} ${classes.cancelBtn}`}
              disabled={loading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className={`${classes.actionBtn} ${classes.saveBtn}`}
              disabled={loading}
              type="submit"
            >
              Save
            </Button>
            <Button
              className={`${classes.actionBtn} ${classes.saveAnotherBtn}`}
              disabled={loading}
              onClick={handleSaveAndAddAnother}
            >
              Save and Add Another
            </Button>
          </div>
        </ValidatorForm>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  errorAlert: {
    marginBottom: theme.spacing(3),

    '& > .MuiAlert-message ul': {
      margin: 0,
    },
  },
  manifestSection: {
    display: 'flex',
    padding: theme.spacing(0, 3),
  },
  orderSection: {
    marginTop: 16,
    display: 'flex',
    // padding: theme.spacing(0, 3),
  },
  invoiceSection: {
    marginTop: 16,
    display: 'flex',
    // padding: theme.spacing(0, 3),
  },
  sectionHeader: {
    marginTop: 16,
    alignItems: 'center',
    backgroundColor: '#fcf0d4',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
  },
  sectionName: {
    flex: 1,
    color: '#4F4F4F',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  invoiceSectionHeader: {
    marginTop: 16,
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 3),
  },
  actions: {
    alignItems: 'center',
    borderTop: '1px solid #bdbdbd',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 3),
  },
  actionBtn: {
    borderRadius: 5,
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 6),
  },
  cancelBtn: {
    backgroundColor: colors.danger[500],
    color: 'white',

    '&:hover': {
      backgroundColor: darken(colors.danger[500], 0.15),
    },
  },
  saveBtn: {
    backgroundColor: colors.textPrimary[400],
    color: 'white',

    '&:hover': {
      backgroundColor: darken(colors.textPrimary[400], 0.15),
    },
  },
  saveAnotherBtn: {
    backgroundColor: colors.primary[100],
    color: colors.textPrimary[600],

    '&:hover': {
      backgroundColor: darken(colors.primary[100], 0.15),
    },
  },
  expandButton: {
    borderRadius: 3,
    color: colors.primary[600],
    fontSize: theme.typography.h4.fontSize,
    justifyContent: 'flex-end',
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
  confirmText: {
    flex: 1,
    color: '#4F4F4F',
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 16,
  },
  confirmButton: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 3),
  },
}));

export default ManifestBuilderForm;
