import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import {
  ON_TIME_STATUS,
  ON_TIME_STATUS_TEXT,
} from 'doc-mate-store/lib/constants/load';
import React from 'react';
import { onTimeStatusColors } from '../../themes';

type Props = {
  flex?: number;
  loadStatus: string;
  onTimeStatus: ON_TIME_STATUS;
  sticky?: boolean;
};

const LoadStatusAppBar: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { flex, loadStatus, onTimeStatus, sticky } = props;

  const message =
    onTimeStatus === ON_TIME_STATUS.UNKNOWN
      ? loadStatus
      : ON_TIME_STATUS_TEXT[onTimeStatus];

  return (
    <AppBar
      position="static"
      className={[classes.appBar, sticky ? classes.sticky : ''].join(' ')}
      style={{ flex }}
    >
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography variant="h3" noWrap className={classes.text}>
          {message}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: (props: Props) => ({
    backgroundColor: onTimeStatusColors(theme)[props.onTimeStatus],
    color: theme.palette.getContrastText(
      onTimeStatusColors(theme)[props.onTimeStatus],
    ),
  }),
  sticky: {
    position: 'sticky',
    top: 0,
  },
  toolbar: {
    margin: 'auto',
  },
  text: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));

export default LoadStatusAppBar;
