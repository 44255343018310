import {
  Button,
  darken,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { LoadTableRow } from 'doc-mate-store/lib/models';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { colors } from '../../../themes';
import LinkIcon from './LinkIcon.svg';

type Props = {
  loading: boolean;
  legId: number;
  legInfoList: number[];
  driver: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const DriverAssignConfirmation: React.FC<Props> = props => {
  const { loading, legId, legInfoList, driver, onConfirm, onCancel } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const { rootStore } = useStores();
  const linkedLegIds = [...legInfoList];
  linkedLegIds.push(legId);

  const linkedLegs = linkedLegIds.map(val => {
    const leg = rootStore.loadTableRows.get(`${val}`);
    return leg;
  });
  const sortOrderLegTableRow = useCallback(
    (legA: LoadTableRow | undefined, legB: LoadTableRow | undefined) => {
      if (!!legA && !!legB) {
        return legA.orderNumber - legB.orderNumber;
      }

      return 1;
    },
    [],
  );

  return (
    <Dialog
      aria-labelledby="driver-confirm-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          // Set 'open' to false, however you would do that with your particular code.
          setOpen(false);
        }
      }}
      fullWidth
      maxWidth="xs"
      className={classes.dialog}
    >
      <DialogTitle disableTypography className={classes.header}>
        <img src={LinkIcon} alt="Link" />
        <span className="linked-text"> Linked Orders</span>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.main}>
        {driver === 'No driver assigned' ? (
          <div className="driver-text">
            Unassign the driver for the following DM-IDs:
          </div>
        ) : (
          <div className="driver-text">
            Driver <span className="driver-name">{driver}</span> will be
            assigned to the following DM-IDs:
          </div>
        )}
        {linkedLegs
          .sort((a, b) => sortOrderLegTableRow(a, b))
          .map(val => {
            if (val) {
              return (
                <div key={val.dmId} className="name-container">
                  DM-ID
                  <span className="name-value"> {val.dmId}</span>
                </div>
              );
            }
            return '';
          })}
        <div className={classes.actions}>
          <Button
            disabled={loading}
            className={`${classes.actionBtn} ${classes.cancelBtn}`}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            className={`${classes.actionBtn} ${classes.saveBtn}`}
            onClick={onConfirm}
          >
            Assign
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paperWidthXs': {
      maxWidth: 400,
    },
  },
  header: {
    fontSize: 18,
    color: '#4f4f4f',
    fontWeight: 700,
    textAlign: 'center',
    padding: theme.spacing(1),

    '& .linked-text': {
      textTransform: 'uppercase',
    },
  },
  main: {
    padding: theme.spacing(1, 0, 0),

    '& .driver-text': {
      textAlign: 'center',
      fontSize: 16,
      padding: theme.spacing(0.5, 3),
      color: '#4f4f4f',
      fontWeight: 500,
      '& .driver-name': {
        color: '#155878',
      },
    },
    '& .name-container': {
      textAlign: 'center',
      padding: theme.spacing(1, 2),
      fontSize: 16,
      color: '#4f4f4f',
      '& .name-value': {
        fontWeight: 700,
      },
    },
  },

  actions: {
    alignItems: 'center',
    borderTop: '1px solid #bdbdbd',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2),
  },
  actionBtn: {
    borderRadius: 5,
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 6),
  },
  cancelBtn: {
    backgroundColor: colors.danger[500],
    color: 'white',

    '&:hover': {
      backgroundColor: darken(colors.danger[500], 0.15),
    },
  },
  saveBtn: {
    backgroundColor: colors.textPrimary[400],
    color: 'white',

    '&:hover': {
      backgroundColor: darken(colors.textPrimary[400], 0.15),
    },
  },
}));

export default observer(DriverAssignConfirmation);
