import { Container, Divider, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Helmet from 'react-helmet';
import TrackerAppBar from '../../components/TrackerAppBar';
import { colors } from '../../themes';
import Root from '../Root';
import PrivacyPolicyContent from './PrivacyPolicyContent';

type Props = {};

const PrivacyPolicy: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Root>
      <Helmet>
        <title>Privacy Policy | Doc-Mate</title>
        <meta name="apple-itunes-app" content="app-id=1490860416" />
      </Helmet>
      <TrackerAppBar subtitle="PrivacyPolicy" />

      <div className={classes.container}>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h1" className={classes.track}>
            Doc-Mate Privacy Policy
          </Typography>
          <Typography variant="subtitle1">
            Last updated: January 8, 2021
          </Typography>
          <Divider />
          <PrivacyPolicyContent />
        </Container>
      </div>
    </Root>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
    height: '100%',
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(5),
  },
  formWrapper: {
    flexGrow: 0,
    padding: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: 2,
      borderStyle: 'solid',
    },
  },
  track: {
    color: colors.primary[900],
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },
}));

export default observer(PrivacyPolicy);
