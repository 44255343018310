import { makeStyles, Popover } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { AS400_STATUS } from 'doc-mate-store/lib/constants/load';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  cell: {
    value: [string, AS400_STATUS];
  };
};

const SelectChip = styled('div')({
  display: 'inline-block',
  padding: '0px 5px',
  height: '20px',
  borderRadius: '10px',
  lineHeight: '20px',
  fontSize: '12px',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',

  '&.na': {
    backgroundColor: '#ACD5F0',
  },
  '&.pending': {
    backgroundColor: '#FADFA3',
  },
  '&.active': {
    backgroundColor: '#A6E7C1',
  },
});

const AS400Status: React.FC<Props> = props => {
  const classes = useStyles();
  const { cell } = props;
  const {
    value: [statusDisplay, status],
  } = cell;

  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      navigator.clipboard.writeText(statusDisplay);
      setAnchorEl(event.currentTarget);
      setCopy(true);
    },
    [statusDisplay],
  );

  return (
    <>
      <SelectChip className={status} onClick={handleClick}>
        {statusDisplay}
      </SelectChip>
      <Popover
        id={`${status}`}
        open={copy}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default observer(AS400Status);
