"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadDocumentRef = exports.LoadDocument = exports.LOAD_DOCUMENT_DIRTY_KEYS = void 0;
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var mobx_keystone_1 = require("mobx-keystone");
var constants_1 = require("../constants");
var api = __importStar(require("../services/api"));
var models_1 = require("../utils/models");
exports.LOAD_DOCUMENT_DIRTY_KEYS = {
    PHOTOS: 'PHOTOS',
    COMMENT: 'COMMENT',
};
var LoadDocumentDirty = /** @class */ (function () {
    function LoadDocumentDirty() {
        this.comment = null;
        this.photos = null;
        this.photosChanged = null;
    }
    LoadDocumentDirty.prototype.setComment = function (value) {
        this.comment = value;
    };
    LoadDocumentDirty.prototype.setPhotos = function (value) {
        this.photos = value;
    };
    LoadDocumentDirty.prototype.setPhotosChanged = function (value) {
        this.photosChanged = value;
    };
    Object.defineProperty(LoadDocumentDirty.prototype, "isDirty", {
        get: function () {
            return this.comment !== null || this.photos !== null;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable
    ], LoadDocumentDirty.prototype, "comment", void 0);
    __decorate([
        mobx_1.observable
    ], LoadDocumentDirty.prototype, "photos", void 0);
    __decorate([
        mobx_1.observable
    ], LoadDocumentDirty.prototype, "photosChanged", void 0);
    __decorate([
        mobx_1.action
    ], LoadDocumentDirty.prototype, "setComment", null);
    __decorate([
        mobx_1.action
    ], LoadDocumentDirty.prototype, "setPhotos", null);
    __decorate([
        mobx_1.action
    ], LoadDocumentDirty.prototype, "setPhotosChanged", null);
    __decorate([
        mobx_1.computed
    ], LoadDocumentDirty.prototype, "isDirty", null);
    return LoadDocumentDirty;
}());
var LoadDocument = /** @class */ (function (_super) {
    __extends(LoadDocument, _super);
    function LoadDocument() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.backoffTimer = null;
        _this.retries = 0;
        _this.deletePhotoIDs = [];
        _this.onAttachedToRootStore = function () {
            var disposer = (0, mobx_1.observe)(_this.dirty, function (change) {
                if (change.object.isDirty) {
                    _this._debouncedSync();
                }
            });
            return function () {
                disposer();
                if (_this.backoffTimer) {
                    clearInterval(_this.backoffTimer);
                }
            };
        };
        _this.dirty = new LoadDocumentDirty();
        _this.loading = false;
        _this.errors = null;
        _this.getRefId = function () { return "".concat(_this.id); };
        _this.forEachPhoto = (0, mobx_keystone_1._async)(function (callback) {
            var _a, _b, photo, _c, _d, _e, e_1_1;
            var e_1, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 5, 6, 7]);
                        _a = __values(this.photos), _b = _a.next();
                        _g.label = 1;
                    case 1:
                        if (!!_b.done) return [3 /*break*/, 4];
                        photo = _b.value;
                        _d = (_c = Object).assign;
                        _e = [photo];
                        return [4 /*yield*/, callback(photo)];
                    case 2:
                        _d.apply(_c, _e.concat([_g.sent()]));
                        _g.label = 3;
                    case 3:
                        _b = _a.next();
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_1_1 = _g.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 7];
                    case 6:
                        try {
                            if (_b && !_b.done && (_f = _a.return)) _f.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
        // Debounce and exponential backoff are used to reduce the requests to update the server.
        _this._sync = function () { return __awaiter(_this, void 0, void 0, function () {
            var store, result, timeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('[DEBUG] attempt to sync load document');
                        store = (0, mobx_keystone_1.getRootStore)(this);
                        if (!store || !store.token || this.loading || !this.dirty.isDirty) {
                            return [2 /*return*/];
                        }
                        console.log('[DEBUG] start syncing load document');
                        if (this.backoffTimer) {
                            clearInterval(this.backoffTimer);
                        }
                        this.setLoading(true);
                        return [4 /*yield*/, this._update(store.token)];
                    case 1:
                        result = _a.sent();
                        this.setLoading(false);
                        console.log('[DEBUG] update result', result);
                        if (!result.ok) {
                            timeout = Math.min((Math.pow(2, this.retries) + Math.random()) * 1000, constants_1.MAX_EXPONENTIAL_BACKOFF);
                            console.log('[DEBUG] timeout', timeout, result.errors);
                            this.retries += 1;
                            this.backoffTimer = setInterval(this._sync, timeout);
                            this.setErrors(result.errors);
                        }
                        else {
                            this.retries = 0;
                            this.setErrors(null);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this._debouncedSync = (0, lodash_1.debounce)(_this._sync, constants_1.DEBOUNCE_TIMEOUT);
        _this._update = (0, mobx_keystone_1._async)(function (token) {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(typeof this.id === 'string')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._createDocument(token)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this._updateDocument(token)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
        _this._createDocument = (0, mobx_keystone_1._async)(function (token) {
            var result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.createLoadDocument(token, this.leg, this, this.dirty.photos !== null)];
                    case 1:
                        result = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, (0, models_1.getError)(error_1)];
                    case 3:
                        Object.assign(this, result.response.entities);
                        this.dirty.setComment(null);
                        if (this.dirty.photos !== null) {
                            this.dirty.setPhotosChanged(null);
                            this.dirty.setPhotos(null);
                        }
                        return [2 /*return*/, (0, models_1.getSuccess)()];
                }
            });
        });
        _this.addPhotos = (0, mobx_keystone_1._async)(function (photo) {
            var store, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        store = (0, mobx_keystone_1.getRootStore)(this);
                        if (typeof this.id === 'string') {
                            return [2 /*return*/, (0, models_1.getSuccess)()];
                        }
                        if (!store || !store.token || this.loading) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, api.createLoadDocumentPhotosWeb(store.token, this.id, photo)];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            Object.assign(this, result.response.entities);
                        }
                        return [2 /*return*/, (0, models_1.getSuccess)()];
                }
            });
        });
        _this.addFiles = (0, mobx_keystone_1._async)(function (file) {
            var store, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        store = (0, mobx_keystone_1.getRootStore)(this);
                        if (typeof this.id === 'string') {
                            return [2 /*return*/, (0, models_1.getSuccess)()];
                        }
                        if (!store || !store.token || this.loading) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, api.createLoadDocumentFilesWeb(store.token, this.id, file)];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            Object.assign(this, result.response.entities);
                        }
                        return [2 /*return*/, (0, models_1.getSuccess)()];
                }
            });
        });
        _this._updateDocument = (0, mobx_keystone_1._async)(function (token) {
            var result, newPhotos, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // This shouldn't happen but we add this to satisfy typescript.
                        if (typeof this.id === 'string') {
                            return [2 /*return*/, (0, models_1.getSuccess)()];
                        }
                        result = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 9, , 10]);
                        if (!(this.dirty.comment !== null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, api.updateLoadDocument(token, this.id, this.comment)];
                    case 2:
                        result = _a.sent();
                        this.dirty.setComment(null);
                        _a.label = 3;
                    case 3:
                        if (!(this.dirty.photos !== null)) return [3 /*break*/, 8];
                        newPhotos = this.photos.filter(function (photo) { return typeof photo.id === 'string'; });
                        if (!(newPhotos.length > 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, api.createLoadDocumentPhotos(token, this.id, newPhotos)];
                    case 4:
                        result = _a.sent();
                        _a.label = 5;
                    case 5:
                        if (!(this.deletePhotoIDs.length > 0)) return [3 /*break*/, 7];
                        return [4 /*yield*/, api.deleteLoadDocumentPhotos(token, this.id, this.deletePhotoIDs)];
                    case 6:
                        result = _a.sent();
                        this.deletePhotoIDs = [];
                        _a.label = 7;
                    case 7:
                        this.dirty.setPhotosChanged(null);
                        this.dirty.setPhotos(null);
                        _a.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        error_2 = _a.sent();
                        return [2 /*return*/, (0, models_1.getError)(error_2)];
                    case 10:
                        if (result) {
                            Object.assign(this, result.response.entities);
                        }
                        return [2 /*return*/, (0, models_1.getSuccess)()];
                }
            });
        });
        _this.deleteDocumentPhoto = (0, mobx_keystone_1._async)(function (id) {
            var store, result, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        store = (0, mobx_keystone_1.getRootStore)(this);
                        if (!store ||
                            !store.token ||
                            typeof this.id === 'string' ||
                            typeof id === 'string') {
                            this.photos = this.photos.filter(function (photo) { return photo.id !== id; });
                            return [2 /*return*/, (0, models_1.getSuccess)()];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.deleteLoadDocumentPhotos(store.token, this.id, [id])];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        return [2 /*return*/, (0, models_1.getError)(error_3)];
                    case 4:
                        Object.assign(this, result.response.entities);
                        return [2 /*return*/, (0, models_1.getSuccess)()];
                }
            });
        });
        _this.deleteDocumentFile = (0, mobx_keystone_1._async)(function (id) {
            var store, result, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        store = (0, mobx_keystone_1.getRootStore)(this);
                        if (!store ||
                            !store.token ||
                            typeof this.id === 'string' ||
                            typeof id === 'string') {
                            this.files = this.files.filter(function (files) { return files.id !== id; });
                            return [2 /*return*/, (0, models_1.getSuccess)()];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.deleteLoadDocumentFilesWeb(store.token, this.id, [id])];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        return [2 /*return*/, (0, models_1.getError)(error_4)];
                    case 4:
                        Object.assign(this, result.response.entities);
                        return [2 /*return*/, (0, models_1.getSuccess)()];
                }
            });
        });
        return _this;
    }
    LoadDocument.prototype.setLoading = function (loading) {
        this.loading = loading;
    };
    LoadDocument.prototype.setErrors = function (errors) {
        this.errors = errors;
    };
    LoadDocument.prototype.setComment = function (value) {
        this.dirty.setComment(this.comment);
        this.comment = value;
    };
    Object.defineProperty(LoadDocument.prototype, "hasPhotos", {
        get: function () {
            return this.photos.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    // !!!: setPhotos doesn't automatically tag the document dirty.
    LoadDocument.prototype.setPhotos = function (photos) {
        var _this = this;
        this.dirty.setPhotosChanged(true);
        var deletePhotoIDs = this.photos
            .map(function (photo) { return photo.id; })
            .filter(function (id) { return typeof id === 'number'; });
        if (typeof photos === 'function') {
            this.photos = photos(this.photos);
        }
        else {
            this.photos = photos;
        }
        var newPhotoIDs = this.photos.map(function (photo) { return photo.id; });
        this.deletePhotoIDs = __spreadArray(__spreadArray([], __read(this.deletePhotoIDs), false), __read(deletePhotoIDs.filter(function (id) { return !newPhotoIDs.includes(id) && !_this.deletePhotoIDs.includes(id); })), false);
    };
    __decorate([
        mobx_1.observable
    ], LoadDocument.prototype, "dirty", void 0);
    __decorate([
        mobx_1.observable
    ], LoadDocument.prototype, "loading", void 0);
    __decorate([
        mobx_1.action
    ], LoadDocument.prototype, "setLoading", null);
    __decorate([
        mobx_1.observable
    ], LoadDocument.prototype, "errors", void 0);
    __decorate([
        mobx_1.action
    ], LoadDocument.prototype, "setErrors", null);
    __decorate([
        (0, models_1.asDate)('createdOn')
    ], LoadDocument.prototype, "createdOnDate", void 0);
    __decorate([
        (0, models_1.asDate)('modifiedOn')
    ], LoadDocument.prototype, "modifiedOnDate", void 0);
    __decorate([
        mobx_keystone_1.modelAction
    ], LoadDocument.prototype, "setComment", null);
    __decorate([
        mobx_keystone_1.modelAction
    ], LoadDocument.prototype, "setPhotos", null);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "forEachPhoto", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "_update", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "_createDocument", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "addPhotos", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "addFiles", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "_updateDocument", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "deleteDocumentPhoto", void 0);
    __decorate([
        mobx_keystone_1.modelFlow
    ], LoadDocument.prototype, "deleteDocumentFile", void 0);
    LoadDocument = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/LoadDocument')
    ], LoadDocument);
    return LoadDocument;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    leg: (0, mobx_keystone_1.prop)(),
    documentType: (0, mobx_keystone_1.prop)(),
    createdOn: (0, mobx_keystone_1.prop)(),
    modifiedOn: (0, mobx_keystone_1.prop)(),
    comment: (0, mobx_keystone_1.prop)(),
    photos: (0, mobx_keystone_1.prop)(function () { return []; }),
    files: (0, mobx_keystone_1.prop)(function () { return []; }),
})));
exports.LoadDocument = LoadDocument;
exports.loadDocumentRef = (0, mobx_keystone_1.rootRef)('doc-mate/LoadDocumentRef', {
    getId: function (target) {
        if (target instanceof LoadDocument) {
            return "".concat(target.id);
        }
    },
});
