import { useStores } from 'doc-mate-store/lib/hooks';
import { LoadData } from 'doc-mate-store/lib/models/Load';
import WebSocketConnection from 'doc-mate-store/lib/websockets';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { PusherContext } from '../../contexts';
import { useWebStores } from '../../hooks';

type Props = {
  children?: React.ReactNode | React.ReactNodeArray;
};

const Pusher: React.FC<Props> = ({ children }) => {
  const [connection, setConnection] = useState<WebSocketConnection>();
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const { token } = rootStore;

  useEffect(() => {
    // Connect to websocket when token changes
    if (token) {
      const cn = new WebSocketConnection(token);
      cn.registerCallback((payload: LoadData) => {
        webStore.fetchPresetTable();
        webStore.fetchSavedTables();
      });
      setConnection(cn);
    }
    return () => {
      if (connection) {
        connection.close();
        setConnection(undefined);
      }
    };
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PusherContext.Provider value={{ websocket: connection }}>
      {children}
    </PusherContext.Provider>
  );
};

export default observer(Pusher);
