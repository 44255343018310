import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import TrackerAppBar from '../../components/TrackerAppBar';
import TrackingLinkForm from '../../components/TrackingLinkForm';
import { colors } from '../../themes';
import Root from '../Root';

type Props = {};

const TrackForm: React.FC<Props> = props => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const { me } = rootStore;

  const user = me ? me.maybeCurrent : undefined;

  return (
    <Root>
      <Helmet>
        <title>Doc-Mate Tracker</title>
        <meta name="apple-itunes-app" content="app-id=1490860416" />
      </Helmet>
      <TrackerAppBar subtitle="Tracker" me={user} />
      <div className={classes.container}>
        <Container maxWidth="sm" className={classes.formWrapper}>
          <Typography variant="h1" className={classes.track}>
            Track Your Shipment
          </Typography>
          <TrackingLinkForm autoFocus className={classes.trackingForm} />
          <p>
            <Button component={Link} color="primary" to="/logout">
              Logout
            </Button>
          </p>
        </Container>
      </div>
    </Root>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 40,
  },
  container: {
    backgroundColor: 'white',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
  },
  formWrapper: {
    flexGrow: 0,
    padding: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: 2,
      borderStyle: 'solid',
    },
  },
  track: {
    color: colors.primary[900],
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    marginBottom: theme.spacing(10),
  },
  trackingForm: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
}));

export default observer(TrackForm);
