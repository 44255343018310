"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.socialAccountRef = exports.SocialAccount = void 0;
var mobx_keystone_1 = require("mobx-keystone");
var SocialAccount = /** @class */ (function (_super) {
    __extends(SocialAccount, _super);
    function SocialAccount() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id); };
        return _this;
    }
    SocialAccount.prototype.update = function (data) {
        Object.assign(this, data);
    };
    __decorate([
        mobx_keystone_1.modelAction
    ], SocialAccount.prototype, "update", null);
    SocialAccount = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/SocialAccount')
    ], SocialAccount);
    return SocialAccount;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    provider: (0, mobx_keystone_1.prop)(),
    uid: (0, mobx_keystone_1.prop)(),
    dateJoined: (0, mobx_keystone_1.prop)(),
    lastLogin: (0, mobx_keystone_1.prop)(),
})));
exports.SocialAccount = SocialAccount;
exports.socialAccountRef = (0, mobx_keystone_1.rootRef)('doc-mate/SocialAccountRef', {
    getId: function (target) {
        if (target instanceof SocialAccount) {
            return "".concat(target.id);
        }
    },
});
