"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.billingRef = exports.Billing = void 0;
var mobx_keystone_1 = require("mobx-keystone");
var OrderLeg_1 = require("./OrderLeg");
var Billing = /** @class */ (function (_super) {
    __extends(Billing, _super);
    function Billing() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id); };
        return _this;
    }
    Billing_1 = Billing;
    Billing.prototype.update = function (data) {
        var rootStore = (0, mobx_keystone_1.getRootStore)(this);
        if (!rootStore) {
            return;
        }
        var result = Billing_1._formatData(rootStore, data);
        if (result) {
            Object.assign(this, result);
        }
    };
    var Billing_1;
    Billing._formatData = function (rootStore, data) {
        var billing = __assign({}, data);
        try {
            billing.leg = (0, OrderLeg_1.orderLegRef)("".concat(data.leg));
            return billing;
        }
        catch (error) {
            console.warn('[WARN] error', error);
            return null;
        }
    };
    Billing.create = function (rootStore, data) {
        var result = Billing_1._formatData(rootStore, data);
        if (result) {
            return new Billing_1(result);
        }
        else {
            return null;
        }
    };
    __decorate([
        mobx_keystone_1.modelAction
    ], Billing.prototype, "update", null);
    Billing = Billing_1 = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/Billing')
    ], Billing);
    return Billing;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    manifest: (0, mobx_keystone_1.prop)(),
    leg: (0, mobx_keystone_1.prop)(),
    percentage: (0, mobx_keystone_1.prop)(),
    notes: (0, mobx_keystone_1.prop)(),
})));
exports.Billing = Billing;
exports.billingRef = (0, mobx_keystone_1.rootRef)('doc-mate/BillingRef', {
    getId: function (target) {
        if (target instanceof Billing) {
            return "".concat(target.id);
        }
    },
});
