import { GPSPositionData } from 'doc-mate-store/lib/models/GPSPosition';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { colors } from '../../themes';

type Props = {
  map: any;
  maps: any;
  path: GPSPositionData[];
};

const mapStrokeColor = colors.primary[900];
const satelliteStrokeColor = colors.primary[100];

const TruckPath: React.FC<Props> = ({ map, maps, path }) => {
  const polyline = useRef<any>(null);
  const [strokeColor, setStrokeColor] = useState(mapStrokeColor);

  const onMapTypeIdChanged = useCallback(() => {
    const mapTypeId = map.getMapTypeId();
    if (mapTypeId === 'satellite' || mapTypeId === 'hybrid') {
      setStrokeColor(satelliteStrokeColor);
    } else {
      setStrokeColor(mapStrokeColor);
    }
  }, [map]);

  useEffect(() => {
    if (map && maps) {
      const listener = map.addListener('maptypeid_changed', onMapTypeIdChanged);
      return () => {
        maps.event.removeListener(listener);
      };
    }
  }, [map, maps, onMapTypeIdChanged]);

  useEffect(() => {
    polyline.current = new maps.Polyline({
      path: path.map(p => ({ lat: p.latitude, lng: p.longitude })),
      opacity: 0.8,
      strokeColor,
      strokeWeight: 8,
      zIndex: 10,
    });
    polyline.current.setMap(map);
    return () => {
      if (polyline && polyline.current) {
        polyline.current.setMap(null);
        polyline.current = null;
      }
    };
  }, [map, maps, path, strokeColor]);

  return null;
};

export default TruckPath;
