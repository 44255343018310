import { IconButton, makeStyles } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { Document, Image as PDFImage, Page, pdf } from '@react-pdf/renderer';
import { useStores } from 'doc-mate-store/lib/hooks';
import { saveAs } from 'file-saver';
import React, { useCallback, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Gallery, { PhotoProps } from 'react-photo-gallery';

type Props = {
  caption: string;
  photos: {
    id: string | number;
    src: string;
    thumbnail: string;
    caption?: string;
    width: number;
    height: number;
    rotate?: number;
  }[];
  onAdd: () => void;
  document: any;
  canUpdate: boolean;
};

// function forceDownload(blob: string, filename: string) {
//   const a = document.createElement('a');
//   a.download = filename;
//   a.href = blob;
//   // For Firefox https://stackoverflow.com/a/32226068
//   document.body.appendChild(a);
//   a.click();
//   // a.remove();
// }

// Current blob size limit is around 500MB for browsers
// function downloadResource(url: string, filename: string, rotate: number) {
//   let filename_ = filename;
//   if (!filename)
//     filename_ = url!
//       .split('\\')!
//       .pop()!
//       .split('/')!
//       .pop()!;
//   let img = new Image();
//   img.src = url;
//   img.crossOrigin = 'anonymous'; // user-credentials doesn't work in firefox

//   let canvas = document.createElement('canvas');

//   img.onload = function() {
//     rotateImage();
//     saveImage(filename_);
//   };

//   const rotateImage = () => {
//     // Create canvas context.
//     let ctx = canvas.getContext('2d');

//     // Assign width and height.

//     if (rotate === 90 || rotate === 270) {
//       //Inverts height and width for side rotate
//       canvas.width = img.height;
//       canvas.height = img.width;
//     } else {
//       canvas.width = img.width;
//       canvas.height = img.height;
//     }

//     ctx!.translate(canvas.width / 2, canvas.height / 2);

//     ctx!.rotate((Math.PI * rotate) / 180);
//     ctx!.drawImage(img, -img.width / 2, -img.height / 2);
//   };

//   const saveImage = (img_name: string) => {
//     let a = document.createElement('a');
//     a.href = canvas.toDataURL('image/jpg');
//     a.download = img_name;
//     document.body.appendChild(a);
//     a.click();
//     a.remove();
//   };
//   //   headers: new Headers({
//   //     Origin: window.location.origin,
//   //   }),
//   //   mode: 'cors',
//   // })
//   //   .then(response => response.blob())
//   //   .then(blob => {
//   //     const blobUrl = window.URL.createObjectURL(blob);
//   //     forceDownload(blobUrl, filename_);
//   //   })
//   //   .catch(e => console.error(e));
// }

// function printResource(url: string, header: string, rotate: number) {
//   printJS({
//     printable: url,
//     type: 'image',
//     header,
//     imageStyle: `
//     max-height: 50%;
//     max-width: 50%;
//     display: block;
//     margin: auto;
//     transform: rotate(${rotate}deg);
//   `,
//   });
// }

const LoadDocumentPhotoList: React.FC<Props> = ({
  caption,
  photos,
  onAdd,
  document,
  canUpdate,
}) => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [rotate, setRotate] = useState(photos.map(photo => photo.rotate || 0));
  const [isOpen, setIsOpen] = useState(false);
  const [currentPhotoRotate, setCurrentPhotoRotate] = useState(
    rotate[currentPhoto],
  );

  const images = photos.map(photo => photo.src);
  const galleryPhotos = photos.map(photo => ({
    src: photo.thumbnail,
    width: photo.width,
    height: photo.height,
  }));
  const handleImageChange = useCallback(
    (index: number) => {
      setCurrentPhoto(index);
      setCurrentPhotoRotate(rotate[index]);
    },
    [rotate],
  );

  // const handleDownload = useCallback(() => {
  //   downloadResource(
  //     photos[currentPhoto].src,
  //     'download.jpg',
  //     rotate[currentPhoto],
  //   );
  // }, [currentPhoto, photos, rotate]);

  const handleRotate = useCallback(() => {
    setRotate(prevState => {
      prevState[currentPhoto] =
        prevState[currentPhoto] === 270 ? 0 : (prevState[currentPhoto] += 90);

      return prevState;
    });
    setCurrentPhotoRotate(prevState =>
      prevState === 270 ? 0 : (prevState += 90),
    );
    rootStore.rotatePhotoRight(`${[photos[currentPhoto].id]}`);
  }, [currentPhoto, photos, rootStore]);

  // const handlePrint = useCallback(() => {
  //   printResource(photos[currentPhoto].src, caption, rotate[currentPhoto]);
  // }, [caption, currentPhoto, photos, rotate]);

  const handlePrevRequest = useCallback(() => {
    const nextIndex = (currentPhoto + images.length - 1) % images.length;
    setCurrentPhoto(nextIndex);
    setCurrentPhotoRotate(rotate[nextIndex]);
  }, [currentPhoto, images.length, rotate]);

  const handleNextRequest = useCallback(() => {
    const nextIndex = (currentPhoto + images.length + 1) % images.length;
    setCurrentPhoto(nextIndex);
    setCurrentPhotoRotate(rotate[nextIndex]);
  }, [currentPhoto, images.length, rotate]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handlePDFSave = useCallback(async () => {
    const orientation =
      photos[currentPhoto].width > photos[currentPhoto].height
        ? 'landscape'
        : 'portrait';
    const PDFPhoto = () => (
      <Document>
        <Page style={{ padding: 10 }} orientation={orientation}>
          <PDFImage src={photos[currentPhoto].src} fixed />
        </Page>
      </Document>
    );

    const blob = await pdf(<PDFPhoto />).toBlob();
    saveAs(blob, `${caption} - ${photos[currentPhoto].id}`);
  }, [caption, currentPhoto, photos]);

  const handleDelete = useCallback(async () => {
    await document.deleteDocumentPhoto(photos[currentPhoto].id);
  }, [currentPhoto, document, photos]);

  const handleDeleteConfirm = useCallback(async () => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this photo?',
      buttons: [
        { label: 'Confirm', onClick: () => handleDelete() },
        {
          label: 'Cancel',
        },
      ],
      overlayClassName: 'react-confirm-alert-overlay-custom',
    });
  }, [handleDelete]);

  const Thumbnail = (param: { index: number; photo: PhotoProps }) => {
    const image = { ...param.photo };
    const rotateValue = rotate[param.index];
    const rotateStyle =
      rotateValue === 0
        ? classes.thumbnailRotateTo0
        : rotateValue === 90
        ? classes.thumbnailRotateTo90
        : rotateValue === 180
        ? classes.thumbnailRotateTo180
        : classes.thumbnailRotateTo270;

    const aspectRatio = image.width / image.height;

    const handleClick = useCallback(() => {
      handleImageChange(param.index);
      setIsOpen(true);
    }, [param.index]);

    return (
      <span className={classes.photoContainer}>
        <img
          className={[classes.photo, rotateStyle].join(' ')}
          src={image.src}
          width={
            rotateValue === 90 || rotateValue === 270
              ? Math.min((1 / aspectRatio) * 64, 64)
              : Math.min(aspectRatio * 64, 64)
          }
          onClick={handleClick}
          alt="img"
        />
      </span>
    );
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Gallery
        photos={galleryPhotos}
        onClick={() => setIsOpen(true)}
        renderImage={props => <Thumbnail {...props} />}
      />
      {canUpdate && (
        <span onClick={onAdd} className={classes.add}>
          +
        </span>
      )}
      {isOpen && (
        <Lightbox
          wrapperClassName={
            currentPhotoRotate === 90
              ? classes.rotateTo90
              : currentPhotoRotate === 180
              ? classes.rotateTo180
              : currentPhotoRotate === 270
              ? classes.rotateTo270
              : classes.rotateTo0
          }
          animationDisabled
          mainSrc={images[currentPhoto]}
          nextSrc={images[(currentPhoto + 1) % images.length]}
          prevSrc={images[(currentPhoto + images.length - 1) % images.length]}
          imageCrossOrigin="use-credentials"
          onCloseRequest={handleClose}
          onMovePrevRequest={handlePrevRequest}
          onMoveNextRequest={handleNextRequest}
          reactModalStyle={{
            overlay: {
              zIndex: 1500,
            },
          }}
          toolbarButtons={
            canUpdate
              ? [
                  <IconButton
                    key="rotate-control"
                    aria-label="rotate"
                    className={classes.control}
                    onClick={handleRotate}
                    title="Rotate"
                  >
                    <RotateRightIcon fontSize="inherit" />
                  </IconButton>,
                  <IconButton
                    key="download-control"
                    aria-label="download"
                    className={classes.control}
                    onClick={handlePDFSave}
                    title="Download"
                  >
                    <CloudDownloadIcon fontSize="inherit" />
                  </IconButton>,

                  <IconButton
                    key="delete-control"
                    aria-label="delete"
                    className={classes.control}
                    onClick={handleDeleteConfirm}
                    title="Delete"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>,
                ]
              : [
                  <IconButton
                    key="rotate-control"
                    aria-label="rotate"
                    className={classes.control}
                    onClick={handleRotate}
                    title="Rotate"
                  >
                    <RotateRightIcon fontSize="inherit" />
                  </IconButton>,
                  <IconButton
                    key="download-control"
                    aria-label="download"
                    className={classes.control}
                    onClick={handlePDFSave}
                    title="Download"
                  >
                    <CloudDownloadIcon fontSize="inherit" />
                  </IconButton>,
                ]
          }
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  control: {
    color: 'white',
  },
  photoContainer: {
    marginRight: 5,
  },
  photo: {
    maxHeight: 64,
    maxWidth: 64,
    cursor: 'pointer',
    position: 'relative',
    transition: 'transform 0.1s',
    objectFit: 'contain',
  },
  photoList: {
    overflow: 'visible',
  },
  rotateTo0: {
    '& .ril-inner': {
      transform: `rotate(0deg)`,
    },
  },
  rotateTo90: {
    '& .ril-inner': {
      transform: `rotate(90deg)`,
    },
  },
  rotateTo180: {
    '& .ril-inner': {
      transform: `rotate(180deg)`,
    },
  },
  rotateTo270: {
    '& .ril-inner': {
      transform: `rotate(270deg)`,
    },
  },
  thumbnailRotateTo0: {
    transform: `rotate(0deg)`,
    '&:hover': {
      transform: 'scale(1.2) rotate(0deg)',
      transition: 'transform 0.1s',
      zIndex: 1100,
    },
  },
  thumbnailRotateTo90: {
    transform: `rotate(90deg)`,
    '&:hover': {
      transform: 'scale(1.2) rotate(90deg)',
      transition: 'transform 0.1s',
      zIndex: 1100,
    },
  },
  thumbnailRotateTo180: {
    transform: `rotate(180deg)`,
    '&:hover': {
      transform: 'scale(1.2) rotate(180deg)',
      transition: 'transform 0.1s',
      zIndex: 1100,
    },
  },
  thumbnailRotateTo270: {
    transform: `rotate(270deg)`,
    '&:hover': {
      transform: 'scale(1.2) rotate(270deg)',
      transition: 'transform 0.1s',
      zIndex: 1100,
    },
  },
  add: {
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 4,
    cursor: 'pointer',
    '&:hover': {
      fontSize: 20,
    },
  },
}));

export default LoadDocumentPhotoList;
