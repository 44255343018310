import {
  Container,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

type Props = {};

const PrivacyPolicyContent: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container maxWidth="md" className={classes.formWrapper}>
        <Typography variant="body1" className={classes.paragraphWrapper}>
          Doc-Mate, LLC is committed to protecting your privacy. This Privacy
          Policy explains the privacy practices (data collection and usage) of
          Doc-Mate, LLC. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to Doc-Mate include www.doc-mate.com
          and Doc-Mate, LLC. By using the doc-mate.com website and app, you
          consent to the data practices described in this statement. Some
          Doc-Mate websites and apps may have different privacy policies. Please
          read the privacy notice of each site to understand how Doc-Mate uses
          your Personal Information.
        </Typography>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">Definition of terms:</Typography>
          <Container>
            <List dense>
              <ListItem>
                - Load: A driver (Doc-Mate App user) moves a load on their
                trailer. A load can consist of one order, or multiple orders.
              </ListItem>
              <ListItem>
                - Dispatcher: One that assigns loads to drivers and helps manage
                load transportation. Use Doc-Mate website
              </ListItem>
              <ListItem>
                - Driver: One that delivers loads to designated terminals. Loads
                assigned are accessed by the driver through Doc-Mate App
              </ListItem>
              <ListItem>
                - Order: A shipment of freight that is picked up at the shipper
                and taken to the consignee. An order can be picked up at
                location A (shipper) and dropped off at an intermediate location
                B before it is taken to its final destination at location C
                (consignee). It is still one order. An order can consist of 1
                unit (e.g. 1 pallet of bricks) or multiple units (e.g. 10
                pallets of bricks).
              </ListItem>
              <ListItem>
                - Shipper: a person, business, or entity that tenders or
                “cosigns” the product to the carrier.
              </ListItem>
              <ListItem>
                Consignee: a person, business, or entity that is the recipient
                of the goods being shipped.{' '}
              </ListItem>
            </List>
          </Container>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">
            Collection of your Personal Information
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            In order to better provide you with products and services offered on
            our Site, Doc-Mate may collect personally identifiable information,
            such as your:
          </Typography>
          <Container>
            <List dense>
              <ListItem>- First and Last Name</ListItem>
              <ListItem>- E-mail Address</ListItem>
              <ListItem>- Phone Number</ListItem>
              <ListItem>- Employer</ListItem>
              <ListItem>- Job Title</ListItem>
              <ListItem>- Driver license number</ListItem>
            </List>
          </Container>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services available on the Site. These may
            include: (a) registering for an account on our Site; (b) entering a
            sweepstakes or contest sponsored by us or one of our partners; (c)
            signing up for special offers from selected third parties; (d)
            sending us an email message; (e) submitting your credit card or
            other payment information when ordering and purchasing products and
            services on our Site. To wit, we will use your information for, but
            not limited to, communicating with you in relation to services
            and/or products you have requested from us. We also may gather
            additional personal or non-personal information in the future.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Other information are also collected from app users such as:
          </Typography>
          <Container>
            <List dense>
              <ListItem>- Geographic location and route</ListItem>
              <ListItem>- Photos</ListItem>
              <ListItem>- Documents</ListItem>
            </List>
          </Container>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate requires users to provide location and route information
            for load tracking. Users also need to submit photos and documents at
            load pick-up and drop-off. Permission to access these information
            can be updated and managed anytime though the device settings.
            However, retracting permission may prevent the user from accessing
            load details and completing delivery of loads assigned as the
            submission of location information, photos and documents are
            required.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">
            Use of your Personal Information and Purpose
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate collects and uses your personal information for the
            following purposes:
          </Typography>
          <Container>
            <List dense>
              <ListItem>- Verify your identity</ListItem>
              <ListItem>
                - Improve the design, style, and user experience of the
                website(s) and app(s)
              </ListItem>
              <ListItem>
                - Ensure the proper functioning, maintenance and improvement of
                the website(s) and app(s)
              </ListItem>
              <ListItem>
                - Inform you about relevant products and services if you have
                chosen to receive these types of communications
              </ListItem>
              <ListItem>
                - Send you service messages such as account registration or
                account resets
              </ListItem>
            </List>
          </Container>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            For Doc-Mate Driver App users, the following information is also
            collected:
          </Typography>
          <Container>
            <List dense>
              <ListItem>
                - Your location and historical route information to help track
                the real time status of loads you are currently moving and
                provide a historical reference of work completed.
              </ListItem>
              <ListItem>
                - Photos of documents and loads you take to more quickly process
                invoices to customers, pay drivers, establish responsibility of
                damaged loads, and save documentation for future reference.
              </ListItem>
            </List>
          </Container>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate may also use your personally identifiable information to
            inform you of other products or services available from Doc-Mate and
            its affiliates.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">
            Sharing Information with Third Parties
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate does not sell, rent or lease its customer lists to third
            parties.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate may share data with trusted partners to help perform
            statistical analysis, send you email or postal mail, provide
            customer support, or arrange for deliveries. All such third parties
            are prohibited from using your personal information except to
            provide these services to Doc-Mate, and they are required to
            maintain the confidentiality of your information.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Doc-Mate or the site; (b)
            protect and defend the rights or property of Doc-Mate; and/or (c)
            act under exigent circumstances to protect the personal safety of
            users of Doc-Mate, or the public.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">
            Automatically Collected Information
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Information about your computer hardware and software may be
            automatically collected by Doc-Mate. This information can include:
            your IP address, browser type, domain names, access times and
            referring website addresses, location and route. This information is
            used for the operation of the service, to maintain quality of the
            service, and to provide general statistics regarding use of the
            Doc-Mate website. For drivers, device location and route information
            is collected even when the app is closed if the driver has an active
            load. This is sent to the server and is read by the dispatcher and
            shipper and receiver for load status tracking.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">
            Storing and Retaining your Personal Data
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            The personal data we collect from you is stored in the United States
            (East) on Cloud Servers of Amazon Web Services.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            We will hold your personal data for as long as it is necessary in
            order to provide you with Doc-Mate services, deal with any specific
            issues that may arise or as it is required by law or by any other
            relevant regulatory body. Once you choose to close your account with
            Doc-Mate, we will delete the personal data relating to your account
            within 1 month. Route information is retained for load record.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">Links</Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">Children Under Thirteen</Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate does not knowingly collect personally identifiable
            information from children under the age of thirteen. If you are
            under the age of thirteen, you must ask your parent or guardian for
            permission to use this website.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">E-mail Communications</Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            From time to time, Doc-Mate may contact you via email for the
            purpose of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">
            Data Protection Rights for EEA residents
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. If you wish to be informed what
            personal data we hold about you and if you want it to be removed
            from our systems, please contact us at dleoni@fourstartrans.com.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            In certain circumstances, you have the following data protection
            rights:
          </Typography>
          <Container>
            <List dense>
              <ListItem>
                - The right to access, update or to delete the information we
                have on you
              </ListItem>
              <ListItem>- The right of rectification </ListItem>
              <ListItem>- The right to object </ListItem>
              <ListItem>- The right of restriction </ListItem>
              <ListItem>- The right to data portability</ListItem>
              <ListItem>- The right to withdraw consent</ListItem>
            </List>
          </Container>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Privacy Information for California residents If you are a California
            resident, California law (California Consumer Privacy Act or CCPA)
            requires us to provide you with additional information regarding
            your privacy rights with respect to your personal information.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate did not, during the preceding 12 months, do not currently
            and will not sell your personal information, unless you give us
            consent or direct us to do so.
          </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            CCPA provides California residents with the following rights:
          </Typography>
          <Container>
            <List dense>
              <ListItem>
                - The right to request disclosure of any of your personal
                information that we collected.
              </ListItem>
              <ListItem>
                - The right to request deletion of any of your personal
                information that we collected.
              </ListItem>
            </List>
          </Container>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            You can contact us with your concerns or requests by sending an
            email to dleoni@fourstartrans.com.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">Changes to this Statement </Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate reserves the right to change this Privacy Policy from time
            to time. We will notify you about significant changes in the way we
            treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our site, and/or by updating any privacy information on this page.
            Your continued use of the Site and/or Services available through
            this Site after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.formWrapper}>
          <Typography variant="h3">Contact Information</Typography>
          <Typography variant="body1" className={classes.paragraphWrapper}>
            Doc-Mate welcomes your questions or comments regarding this
            Statement of Privacy. If you believe that Doc-Mate has not adhered
            to this Statement, please contact Doc-Mate at:
          </Typography>
          <Container>
            <List dense>
              <ListItem>- Email: dleoni@fourstartrans.com</ListItem>
              <ListItem>
                - Address: 2947 Greenfield Road, Melvindale, MI 48122
              </ListItem>
            </List>
          </Container>
        </Container>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(10),
    },
  },
  formWrapper: {
    flexGrow: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
    },
  },
  paragraphWrapper: {
    flexGrow: 0,
    padding: theme.spacing(1),
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
    },
  },
}));

export default observer(PrivacyPolicyContent);
