import WebSocketConnection from 'doc-mate-store/lib/websockets';
import React from 'react';
import { FilterValue, TableInstance } from 'react-table';
import PresetTables from '../models/PresetTables';
import { SavedTable } from '../models/SavedTable';
import { defaultWebStore } from '../models/WebStore';
import { Filter } from '../types/filtering';

export const storesContext = React.createContext({
  webStore: defaultWebStore,
});

export type DataTableState = {
  savedTables: Map<number, SavedTable>;
  savedTable?: SavedTable;
  presetTables?: PresetTables;
  preset?: string;
  editable: boolean;
  filters: Filter[];
  getValue: (accessor: string) => FilterValue;
  setValue: (accessor: string) => (value: FilterValue) => void;
  deleteValue: (accessor: string) => void;
  clearValues: () => void;
  setValues: (savedTable?: SavedTable, preset?: string) => void;
  tableProps: TableInstance;
  resetColumns: () => void;
  saveColumnOrdering: (columnIds?: string[]) => Promise<void>;
  createSavedTable: () => Promise<SavedTable>;
  visibleFilters: string[];
  handleUpdateFilters: () => void;
};

const defaultFilterState: DataTableState = {
  savedTables: new Map<number, SavedTable>(),
  savedTable: undefined,
  presetTables: undefined,
  preset: undefined,
  editable: true,
  filters: [],
  getValue: (accessor: string) => '',
  setValue: (accessor: string) => (value: FilterValue) => {},
  deleteValue: (accessor: string) => {},
  clearValues: () => {},
  setValues: (savedTable?: SavedTable, preset?: string) => {},
  tableProps: {} as TableInstance,
  resetColumns: () => {},
  saveColumnOrdering: async (columnIds?: string[]) => {},
  createSavedTable: async () => ({} as SavedTable),
  visibleFilters: [],
  handleUpdateFilters: () => {},
};

export const DataTableContext = React.createContext<DataTableState>(
  defaultFilterState,
);

type PusherState = {
  websocket?: WebSocketConnection;
};

const defaultPusherState = {
  websocket: undefined,
};

export const PusherContext = React.createContext<PusherState>(
  defaultPusherState,
);
