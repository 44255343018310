import { Button, darken, makeStyles } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import React from 'react';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import OrderDetailForm from './OrderDetailForm';
import { OrderFormData } from './types';

type Props = {
  isLoading: boolean;
  isAllOrderDetailsOpen: boolean;
  loads: OrderFormData[];
  locations: AutocompleteFieldOption[];
  // loadStops: LoadStopFormData[];
  loadTypes: string[];
  quantityUnits: string[];
  weightUnits: string[];
  distanceUnits: string[];
  onOrderChange: (id: string, name: string, value: any) => void;
  onAddOrder: () => void;
  onRemoveOrder: (id: string) => void;
  onLoadStopChange: (
    orderW: string,
    id: string,
    name: string,
    value: any,
  ) => void;
  onLoadStopAdd: (orderW: string) => void;
  onLoadStopRemove: (orderW: string, id: string) => void;
};

const OrderDetailsSection: React.FC<Props> = ({
  isLoading,
  isAllOrderDetailsOpen,
  loads,
  // loadStops,
  locations,
  loadTypes,
  quantityUnits,
  weightUnits,
  distanceUnits,
  onOrderChange,
  onAddOrder,
  onRemoveOrder,
  onLoadStopChange,
  onLoadStopAdd,
  onLoadStopRemove,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.loadContainer}>
        {loads.map(load => (
          <div className={classes.loadDetailRow} key={load.idW}>
            <OrderDetailForm
              key={load.idW}
              idW={load.idW || ''}
              isLoading={isLoading}
              isAllOrderDetailsOpen={isAllOrderDetailsOpen}
              locations={locations}
              // loadStops={loadStops}
              // loadOptions={loadOptions}
              load={load}
              loadTypes={loadTypes}
              quantityUnits={quantityUnits}
              weightUnits={weightUnits}
              distanceUnits={distanceUnits}
              onOrderChange={onOrderChange}
              onLoadStopChange={onLoadStopChange}
              onLoadStopAdd={onLoadStopAdd}
              onLoadStopRemove={onLoadStopRemove}
              onRemoveOrder={onRemoveOrder}
            />
          </div>
        ))}
      </div>
      <div className={classes.addOrderContainer}>
        <div className={classes.loadLineLeft} />
        <Button
          className={classes.addOrderButton}
          disabled={isLoading}
          onClick={onAddOrder}
        >
          <AddBoxOutlinedIcon className={classes.addOrderButtonIcon} />
          Add Order
        </Button>
        <div className={classes.loadLineRight} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadContainer: {
    // border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
    width: '100%',
  },
  loadDetailRow: {
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 3),
  },
  addOrderContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
  },
  addOrderButton: {
    backgroundColor: 'white',
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 700,
    justifyContent: 'flex-start',
    color: '#4f4f4f',
    textAlign: 'center',

    '&:hover': {
      backgroundColor: darken('#c8d9e4', 0.15),
    },
  },
  addOrderButtonIcon: {
    marginRight: theme.spacing(1),
  },
  loadLineLeft: {
    width: theme.spacing(3),
    borderTop: '1px solid #bdbdbd',
    height: 0.5,
    position: 'relative',
    top: 20,
  },
  loadLineRight: {
    flex: 1,
    display: 'flex',
    borderTop: '1px solid #bdbdbd',
    height: 0.5,
    position: 'relative',
    top: 20,
  },
}));

export default OrderDetailsSection;
