// ts-nocheck
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

const FilterIcon = createSvgIcon(
  <React.Fragment>
    <path d="M18.5966 0C19.0029 0 19.2823 0.190481 19.4347 0.571443C19.5871 0.952404 19.5236 1.28257 19.2442 1.56194L12.196 8.60974V18.5909C12.196 18.9719 12.0309 19.2386 11.7007 19.391C11.3705 19.5687 11.0531 19.556 10.7483 19.3529L7.70038 17.2195C7.44639 17.0163 7.3194 16.7623 7.3194 16.4575V8.60974L0.271172 1.56194C-0.00821736 1.28257 -0.0717148 0.952404 0.0806792 0.571443C0.233073 0.190481 0.512462 0 0.918846 0H18.5966Z" />
  </React.Fragment>,
  'Filter',
);

export default FilterIcon;
