import {
  Button,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  BILL_TO_TEXT,
  LOAD_BILL_TO_CHOICES,
} from 'doc-mate-store/lib/constants/load';
import React, { useCallback } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { colors } from '../../themes';
import SimpleAutocompleteValidator from '../AutocompleteField/SimpleAutocompleteValidator';
import { ManifestFormData } from './types';

type Props = {
  isLoading: boolean;
  weightUnits: string[];
  manifest: ManifestFormData;
  billingDetailsOpen: boolean;
  setBillingDetailsOpen: () => void;
  onManifestChange: (name: string, value: any) => void;
};

const BillingForm: React.FC<Props> = ({
  isLoading,
  weightUnits,
  manifest,
  billingDetailsOpen,
  setBillingDetailsOpen,
  onManifestChange,
}) => {
  const classes = useStyles();

  const handleManifestChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onManifestChange(name, value);
    },
    [onManifestChange],
  );

  const handleManifestAutoCompleteChange = useCallback(
    (name: string, value: string) => {
      onManifestChange(name, value);
    },
    [onManifestChange],
  );

  const handleExpandBillingDetails = useCallback(() => {
    return setBillingDetailsOpen();
  }, [setBillingDetailsOpen]);

  return (
    <div>
      <div className={classes.billingHeader}>
        <Typography className={classes.billingHeaderName} variant="h6">
          Order Invoicing
        </Typography>
        <Button
          className={classes.expandButton}
          disabled={isLoading}
          onClick={handleExpandBillingDetails}
        >
          {billingDetailsOpen ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </Button>
      </div>
      <div className={classes.billingDetailRow}>
        <div className={classes.billingDetail}>
          <SimpleAutocompleteValidator
            className={classes.inputBilling}
            isLoading={isLoading}
            label="Bill To"
            name="billToOptions"
            isClearable
            fullWidth
            onChange={handleManifestAutoCompleteChange}
            options={Object.values(BILL_TO_TEXT)}
            required
            value={manifest.billToOptions}
          />
        </div>

        {manifest.billToOptions ===
          BILL_TO_TEXT[LOAD_BILL_TO_CHOICES.THIRD_PARTY] && (
          <div className={classes.billingDetail}>
            <TextValidator
              className={classes.inputStart}
              disabled={isLoading}
              label="Third Party"
              name="billToThirdParty"
              onChange={handleManifestChange}
              required
              size="small"
              value={manifest.billToThirdParty}
              variant="outlined"
            />
          </div>
        )}

        <div className={classes.billingDetail}>
          <TextValidator
            name="billAs"
            className={classes.inputStart}
            disabled={isLoading}
            inputProps={{ min: '0', step: '0.01', type: 'number' }}
            label="Bill As"
            onChange={handleManifestChange}
            required
            validators={['required']}
            errorMessages={['This field is required.']}
            size="small"
            value={manifest.billAs}
            variant="outlined"
          />
          <SimpleAutocompleteValidator
            className={classes.inputEnd}
            isLoading={isLoading}
            label="Unit"
            name="billAsUnit"
            onChange={handleManifestAutoCompleteChange}
            options={weightUnits}
            required
            value={manifest.billAsUnit || ''}
          />
        </div>
        <div className={classes.billingDetail}>
          <TextValidator
            name="billRevenue"
            className={classes.inputStart}
            disabled={isLoading}
            inputProps={{ min: '0', step: '0.01', type: 'number' }}
            label="Linehaul Revenue"
            onChange={handleManifestChange}
            required
            validators={['required']}
            errorMessages={['This field is required.']}
            size="small"
            value={manifest.billRevenue}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  billingContainer: {
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  billingHeader: {
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  billingHeaderName: {
    flex: 1,
    color: '#0D374B',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  billingDetailRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  billingDetail: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  billingDetailItem: {},
  billingDetailItemName: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
  },
  billingDetailItemValue: {
    color: 'black',
    fontWeight: 500,
    fontSize: 16,
  },
  inputStart: {
    margin: theme.spacing(0, 0, 1.5, 1),
    maxWidth: 160,

    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputBilling: {
    margin: theme.spacing(0, 0, 1.5, 1),
    width: 160,

    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputEnd: {
    margin: theme.spacing(0, 1, 1.5, 0),

    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  expandIcon: {},
  expandButton: {
    color: colors.primary[600],
    fontSize: theme.typography.h6.fontSize,
  },
}));

export default React.memo(BillingForm);
