import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { AutocompleteFieldOption } from './types';

type Props = {
  className?: string;
  fullWidth?: boolean;
  isLoading: boolean;
  label: string;
  name: string;
  onChange: (name: string, value: AutocompleteFieldOption) => void;
  options: AutocompleteFieldOption[];
  value: AutocompleteFieldOption | null;
  required?: boolean;
};

const AutocompleteValidator: React.FC<Props> = ({
  className,
  fullWidth = false,
  isLoading,
  label,
  name,
  onChange,
  options,
  value,
  required,
}) => {
  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: AutocompleteFieldOption | null,
  ) => {
    if (value) {
      onChange(name, value);
    }
  };

  const handleGetOptionLabel = (option: any) => option.name;

  const handleGetOptionSelected = (
    option: AutocompleteFieldOption,
    selected: AutocompleteFieldOption,
  ) => option.value === selected.value;

  const handleRenderInput = (params: any) => (
    <TextValidator
      {...params}
      errorMessages={['This field is required.']}
      inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
      label={label}
      size="small"
      validators={['required']}
      value={value ? value.name : ''}
      variant="outlined"
      required={required}
    />
  );

  return (
    <Autocomplete
      autoComplete
      autoSelect
      className={className}
      disabled={isLoading}
      fullWidth={fullWidth}
      getOptionLabel={handleGetOptionLabel}
      getOptionSelected={handleGetOptionSelected}
      onChange={handleChange}
      options={options}
      renderInput={handleRenderInput}
      value={value}
    />
  );
};

export default AutocompleteValidator;
