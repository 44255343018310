"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCUMENT_TYPE = void 0;
// SEE: api/docmate/loads/constants.py
var DOCUMENT_TYPE;
(function (DOCUMENT_TYPE) {
    DOCUMENT_TYPE["PICKUP_LOAD"] = "pickup_load";
    DOCUMENT_TYPE["PICKUP_DAMAGES"] = "pickup_damages";
    DOCUMENT_TYPE["BILL_OF_LADING"] = "bill_of_lading";
    DOCUMENT_TYPE["TRIP_SHEET"] = "trip_sheet";
    DOCUMENT_TYPE["DROPOFF_LOAD"] = "dropoff_load";
    DOCUMENT_TYPE["DROPOFF_DAMAGES"] = "dropoff_damages";
})(DOCUMENT_TYPE = exports.DOCUMENT_TYPE || (exports.DOCUMENT_TYPE = {}));
