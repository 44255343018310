"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripLegRef = exports.TripLeg = void 0;
var mobx_keystone_1 = require("mobx-keystone");
var Truck_1 = require("./Truck");
var User_1 = require("./User");
var TripLeg = /** @class */ (function (_super) {
    __extends(TripLeg, _super);
    function TripLeg() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id); };
        return _this;
    }
    TripLeg_1 = TripLeg;
    var TripLeg_1;
    TripLeg._formatData = function (rootStore, data) {
        var tripLeg = __assign({}, data);
        try {
            if (data.truck) {
                if (!rootStore.trucks.has("".concat(data.truck))) {
                    rootStore.fetchTruck(data.truck);
                }
                tripLeg.truck = (0, Truck_1.truckRef)("".concat(data.truck));
            }
            else {
                tripLeg.truck = null;
            }
            if (data.driver) {
                if (!rootStore.users.has("".concat(data.driver))) {
                    rootStore.fetchUser(data.driver);
                }
                tripLeg.driver = (0, User_1.userRef)("".concat(data.driver));
            }
            else {
                tripLeg.driver = null;
            }
            return tripLeg;
        }
        catch (error) {
            console.warn('[WARN] error', error);
            return null;
        }
    };
    TripLeg.create = function (rootStore, data) {
        var result = TripLeg_1._formatData(rootStore, data);
        if (result) {
            var newTripLeg = new TripLeg_1(result);
            return newTripLeg;
        }
        else {
            return null;
        }
    };
    TripLeg = TripLeg_1 = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/TripLeg')
    ], TripLeg);
    return TripLeg;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    legNumber: (0, mobx_keystone_1.prop)(),
    trailerNumber: (0, mobx_keystone_1.prop)(),
    truck: (0, mobx_keystone_1.prop)(),
    driver: (0, mobx_keystone_1.prop)(),
})));
exports.TripLeg = TripLeg;
exports.tripLegRef = (0, mobx_keystone_1.rootRef)('doc-mate/TripLegRef', {
    getId: function (target) {
        if (target instanceof TripLeg) {
            return "".concat(target.id);
        }
    },
});
