import { Button, darken, makeStyles } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import React, { useCallback } from 'react';
import AccessorialDetailForm from './AccessorialDetailForm';
import { AccessorialFormData, OrderFormData, OrderLegFormData } from './types';

type Props = {
  isLoading: boolean;
  manifestNumber: string;
  loads: OrderFormData[];
  orderLegs: OrderLegFormData[];
  accessorials: AccessorialFormData[];
  billToOptions: string;
  invoiceDetailOpen: boolean;
  onAccessorialChange: (idW: string, name: string, value: any) => void;
  onAddAccessorial: () => void;
  onRemoveAccessorial: (idW: string) => void;
};

const AccessorialSection: React.FC<Props> = ({
  isLoading,
  manifestNumber,
  loads,
  orderLegs,
  accessorials,
  billToOptions,
  invoiceDetailOpen,
  onAccessorialChange,
  onAddAccessorial,
  onRemoveAccessorial,
}) => {
  const classes = useStyles();

  const handleAddAccessorial = useCallback(() => {
    onAddAccessorial();
  }, [onAddAccessorial]);

  return (
    <div>
      {accessorials.map((accessorial, index) => (
        <AccessorialDetailForm
          key={accessorial.idW}
          idW={accessorial.idW || ''}
          index={index}
          invoiceDetailOpen={invoiceDetailOpen}
          isLoading={isLoading}
          billToOptions={billToOptions}
          manifestNumber={manifestNumber}
          accessorial={accessorial}
          loads={loads}
          orderLegs={orderLegs}
          onAccessorialChange={onAccessorialChange}
          onRemoveAccessorial={onRemoveAccessorial}
        />
      ))}

      <div className={classes.addAccessorialContainer}>
        <div className={classes.loadLineLeft} />
        <Button
          className={classes.addAccessorialButton}
          disabled={isLoading}
          onClick={handleAddAccessorial}
        >
          <AddBoxOutlinedIcon className={classes.addAccessorialButtonIcon} />
          Add Accessorial
        </Button>
        <div className={classes.loadLineRight} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  accessorialContainer: {
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  addAccessorialContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  addAccessorialButton: {
    backgroundColor: 'white',
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 700,
    justifyContent: 'flex-start',
    textAlign: 'center',
    color: '#4f4f4f',

    '&:hover': {
      backgroundColor: darken('#c8d9e4', 0.15),
    },
  },
  addAccessorialButtonIcon: {
    marginRight: theme.spacing(1),
  },
  loadLineLeft: {
    width: theme.spacing(3),
    borderTop: '1px solid #bdbdbd',
    height: 0.5,
    position: 'relative',
    top: 20,
  },
  loadLineRight: {
    flex: 1,
    display: 'flex',
    borderTop: '1px solid #bdbdbd',
    height: 0.5,
    position: 'relative',
    top: 20,
  },
}));

export default React.memo(AccessorialSection);
