import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useLocation } from 'react-router';

type Props = {
  autoFocus?: boolean;
  className?: string;
};

const trackingLinkRegex = /^(?<tn1>[A-Za-z0-9]+)$|^https:\/\/www\.doc-mate.com\/l\/(?<tn2>[A-Za-z0-9]{8})$/;

const TrackingLinkForm: React.FC<Props> = ({ autoFocus, className }) => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const location = useLocation();
  const history = useHistory();
  const trackingNumberRef = useRef<TextValidator>(null);
  const search = location.search.substring(1);
  const query = qs.parse(search);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const hasTrackerError = typeof query._t !== 'undefined';

  useEffect(() => {
    ValidatorForm.addValidationRule(
      'noTrackerError',
      value => value || !hasTrackerError,
    );
    return () => {
      ValidatorForm.removeValidationRule('noTrackerError');
    };
  }, [hasTrackerError]);

  useEffect(() => {
    if (hasTrackerError && trackingNumberRef.current) {
      (trackingNumberRef.current as any).validate('');
    }
  }, [hasTrackerError]);

  function handleChange(event: any) {
    setTrackingNumber(event.target.value);
    setError(null);
  }

  async function handleSubmit() {
    const match = trackingLinkRegex.exec(trackingNumber);
    if (!match) {
      // error
      return;
    }
    const { groups } = match;
    if (!groups) {
      // error
      return;
    }
    if (!groups.tn1 && !groups.tn2) {
      // error
      return;
    }
    const tn = groups.tn1 || groups.tn2;
    setLoading(true);
    const ret = await rootStore.fetchOrderLegByOrderLegLinkId(tn, true, true);
    setLoading(false);
    if (ret) {
      history.push(`/l/${tn}`);
    } else {
      setError('Tracking link is not valid.');
    }
  }

  return (
    <ValidatorForm
      className={className}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography variant="h3" component="h2" className={classes.prompt}>
        Enter a Tracking Link
      </Typography>
      <TextValidator
        id="tracking-number"
        ref={trackingNumberRef}
        autoFocus={autoFocus}
        error={!!error}
        helperText={error || undefined}
        size="small"
        name="trackingNumber"
        fullWidth
        onChange={handleChange}
        value={trackingNumber}
        variant="outlined"
        validators={[
          'noTrackerError',
          'required',
          `matchRegexp:${trackingLinkRegex
            .toString()
            .substring(1, trackingLinkRegex.toString().length - 1)}`,
        ]}
        errorMessages={[
          'Tracking link is not valid.',
          'This field is required.',
          'Tracking link is not valid.',
        ]}
        placeholder="https://www.doc-mate.com/l/xxxxxxx"
      />
      <Button
        color="primary"
        endIcon={
          loading ? <CircularProgress size={14} color="secondary" /> : undefined
        }
        fullWidth
        variant="contained"
        type="submit"
        className={classes.submit}
      >
        Track your shipment
      </Button>
    </ValidatorForm>
  );
};

const useStyles = makeStyles(theme => ({
  prompt: {
    marginBottom: theme.spacing(4),
  },
  submit: {
    marginTop: theme.spacing(4),
  },
}));

export default TrackingLinkForm;
