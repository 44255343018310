import { GPSPositionData } from 'doc-mate-store/lib/models/GPSPosition';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import fb from 'doc-mate-store/lib/services/firebase';
import React, { useEffect, useRef } from 'react';

type Props = {
  load: OrderLeg;
  onPathInsert(newPositions: GPSPositionData[]): void;
  onPathReset(positions: GPSPositionData[]): void;
};

function process(username: string, keys: string[]) {
  const usernameCleaned = username.replace('@', '').replace('.', '');
  const posRef = fb.db.ref(`users/${usernameCleaned}/positions`);
  const promises = keys.map(key => posRef.child(key).once('value'));
  return Promise.all(promises).then(snapshots => {
    const newPaths: GPSPositionData[] = [];
    snapshots.forEach((snapshot: { val: () => any }) => {
      const pval: GPSPositionData = snapshot.val();
      if (pval) {
        newPaths.push(pval);
      }
    });
    return newPaths;
  });
}

const DriverPath: React.FC<Props> = ({ load, onPathInsert, onPathReset }) => {
  const pathRef = useRef<any>();
  // const pathRef = useRef<firebase.database.Reference>();

  useEffect(() => {
    if (!load || !load.driver || !load.driver.isValid) {
      return;
    }

    const { username } = load.driver.current;

    pathRef.current = fb.db.ref(`loads/${load!.id}/positions`);
    pathRef.current.once('value', (snapshot: { val: () => any }) => {
      const positionSnapshot = snapshot.val();
      if (positionSnapshot) {
        process(username, Object.keys(positionSnapshot)).then(path => {
          onPathReset(path);
          if (pathRef.current) {
            pathRef.current
              .limitToLast(2)
              .on('child_added', (snapshot: { key: string }) => {
                // to handle the "initial" data we created
                if (snapshot.key) {
                  process(username, [snapshot.key]).then(additionalPath => {
                    onPathInsert(additionalPath);
                  });
                }
              });
          }
        });
      }
    });

    return () => {
      if (pathRef.current) {
        onPathReset([]);
        pathRef.current.off();
      }
    };
  }, [load, onPathInsert, onPathReset]);

  return null;
};

export default DriverPath;
