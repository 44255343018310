import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from 'doc-mate-store/lib/hooks';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import LoadStatusTimelineItem from './LoadStatusTimelineItem';

type Props = { load: OrderLeg };

const LoadStatusTimeline: React.FC<Props> = ({ load }) => {
  const classes = useStyles();
  const { rootStore } = useStores();

  const { me } = rootStore;
  const user = me ? me.maybeCurrent : undefined;
  const isDispatcher = user && user.isDispatcher;
  const isBroker = user && user.isBroker;
  const driverPosition = load.currentGPSPosition;

  const now = moment();
  const timestamp =
    driverPosition &&
    driverPosition.timestamp &&
    moment(driverPosition.timestamp);
  const timestampDiff = now.diff(timestamp, 'minutes');

  const noUpdateTooltip =
    "ETA Might change as driver's location is currently unavailable";

  // const hasPickupETA =
  //   load.etas && load.etas.pickupEta && load.etas.pickupEta.eta;
  const isPickupETAHidden = !load.pickupStartedOn && !load.pickupArrivedOn;
  const pickupHiddenTooltip = isPickupETAHidden
    ? 'The ETA will be updated once driver is in route to pick-up.'
    : !load.pickupArrivedOn &&
      (timestampDiff > 10 ||
        !driverPosition ||
        !(load.etas && load.etas.pickupEta))
    ? noUpdateTooltip
    : '';

  // const hasDropoffETA =
  //   load.etas && load.etas.dropoffEta && load.etas.dropoffEta.eta;
  const isDropoffETAHidden = !load.dropoffStartedOn && !load.dropoffArrivedOn;
  const dropoffHiddenTooltip = isDropoffETAHidden
    ? 'The ETA will be updated once driver is in route to drop-off.'
    : !load.dropoffArrivedOn &&
      (timestampDiff > 10 ||
        !driverPosition ||
        !(load.etas && load.etas.dropoffEta))
    ? noUpdateTooltip
    : '';

  return (
    <div className={classes.container}>
      {(isDispatcher || isBroker) && (
        <LoadStatusTimelineItem
          date={load.loadStartedOn}
          isNextItemActive={!!load.pickupStartedOn}
          statusText="Accepted"
          hidden={!load.loadStartedOn}
        />
      )}
      <LoadStatusTimelineItem
        date={load.pickupStartedOn}
        isNextItemActive={!!load.pickupArrivedOn}
        prevDate={load.loadStartedOn}
        statusText="En Route to Pick-up"
        hidden={!load.pickupStartedOn}
      />
      <LoadStatusTimelineItem
        date={load.pickupArrivedOn}
        etaDate={load.etas && load.etas.pickupEta && load.etas.pickupEta.eta}
        isNextItemActive={!!load.dropoffStartedOn}
        statusText="Arrived at Pick-up"
        onTimeStatus={load.pickupOnTimeChipStatus}
        prevDate={load.loadStartedOn}
        window={load.pickupWindow}
        hidden={isPickupETAHidden}
        hiddenTooltip={pickupHiddenTooltip}
        extraRender={(active, textClassName) => (
          <>
            {load.pickupLocation && load.pickupLocation.isValid && (
              <Typography className={textClassName}>
                {load.pickupLocation.current.name}
              </Typography>
            )}
            {load.pickup && load.pickup.isValid && (
              <Typography className={textClassName}>
                {load.pickup.current.city}, {load.pickup.current.state}{' '}
                {load.pickup.current.zipCode}
              </Typography>
            )}
          </>
        )}
      />
      {/***<LoadStatusTimelineItem
        date={load.pickupCheckedinOn}
        isNextItemActive={!!load.pickupDepartedOn}
        prevDate={load.loadStartedOn}
        statusText="Checked In at Pick-up"
      />***/}
      <LoadStatusTimelineItem
        date={load.dropoffStartedOn}
        isNextItemActive={!!load.dropoffArrivedOn}
        prevDate={load.loadStartedOn}
        statusText="En Route to Drop-off"
        hidden={!load.dropoffStartedOn}
      />
      <LoadStatusTimelineItem
        date={load.dropoffArrivedOn}
        etaDate={load.etas && load.etas.dropoffEta && load.etas.dropoffEta.eta}
        isNextItemActive={!!load.dropoffCompletedOn}
        hidden={isDropoffETAHidden}
        hiddenTooltip={dropoffHiddenTooltip}
        prevDate={load.loadStartedOn}
        statusText="Arrived at Drop-off"
        onTimeStatus={load.dropoffOnTimeChipStatus}
        window={load.dropoffWindow}
        extraRender={(active, textClassName) => (
          <>
            {load.dropoffLocation && load.dropoffLocation.isValid && (
              <Typography className={textClassName}>
                {load.dropoffLocation.current.name}
              </Typography>
            )}
            {load.dropoff && load.dropoff.isValid && (
              <Typography className={textClassName}>
                {load.dropoff.current.city}, {load.dropoff.current.state}{' '}
                {load.dropoff.current.zipCode}
              </Typography>
            )}
          </>
        )}
      />
      {/***<LoadStatusTimelineItem
        date={load.dropoffCheckedinOn}
        isNextItemActive={!!load.dropoffCompletedOn}
        prevDate={load.loadStartedOn}
        statusText="Checked In at Drop-off"
      />***/}
      <LoadStatusTimelineItem
        date={load.dropoffCompletedOn}
        isLastItem
        prevDate={load.loadStartedOn}
        statusText="Delivered"
        hidden={!load.dropoffCompletedOn}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 24,
    paddingRight: 20,
    paddingBottom: 24,
    paddingLeft: 20,
  },
}));

export default observer(LoadStatusTimeline);
