// https://codesandbox.io/s/laughing-boyd-zo5h0
import { alpha, makeStyles } from '@material-ui/core';
import { DatePicker, DatePickerProps, useUtils } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

type Props = Omit<DatePickerProps, 'value' | 'onChange' | 'labelFunc'> & {
  value: [MaterialUiPickersDate, MaterialUiPickersDate];
  onChange: (values: {
    begin: MaterialUiPickersDate;
    end: MaterialUiPickersDate;
  }) => void;
  labelFunc?: (
    values: [MaterialUiPickersDate, MaterialUiPickersDate],
    invalid: string,
  ) => string;
};

const DateRangePicker: React.FC<Props> = ({
  value,
  onChange,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onOpen,
  onClose,
  open: openForward,
  ...props
}) => {
  const classes = useStyles();
  const [begin, setBegin] = useState(value[0]);
  const [end, setEnd] = useState(value[1]);
  const [prevBegin, setPrevBegin] = useState<MaterialUiPickersDate>(null);
  const [prevEnd, setPrevEnd] = useState<MaterialUiPickersDate>(null);
  const [hasClicked, setHasClicked] = useState(false);

  const [hover, setHover] = useState<MaterialUiPickersDate>(null);
  const [accepted, setAccepted] = useState(!!begin && !!end);
  const utils = useUtils();

  // const min = Math.min(begin, end || hover);
  // const max = Math.max(begin, end || hover);

  let min: MaterialUiPickersDate = null;
  let max: MaterialUiPickersDate = null;
  const ds = [begin as Moment, (end || hover) as Moment].filter(d => !!d);
  if (ds) {
    min = moment.min(...ds);
    max = moment.max(...ds);
  }

  const [open, setOpen] = useState(false);

  const isOpen = openForward !== undefined ? openForward : open;

  useEffect(() => {
    // Only way to get to this state is if openForward is used
    if (isOpen && accepted && !prevBegin && !prevEnd) {
      setAccepted(false);
      setPrevBegin(begin);
      setPrevEnd(end);
      return;
    }
    // Closed without accepting, reset to prev state, don't fire onChange
    if (!isOpen && !accepted) {
      setBegin(prevBegin);
      setEnd(prevEnd);
      setHover(null);
      setHasClicked(false);
    }
    // Auto ok and hasn't been accepted, but has all the items set, accept and close.
    // This will also triger the on change event by setting isOpen to false
    if (isOpen && autoOk && !accepted && begin && end && hasClicked) {
      setAccepted(true);
      onClose ? onClose() : setOpen(false);
    }
    if (accepted && begin && end && !isOpen && hasClicked) {
      setHasClicked(false);
      onChange({ begin, end });
      onClose ? onClose() : setOpen(false);
    }
  }, [
    begin,
    end,
    autoOk,
    accepted,
    isOpen,
    prevBegin,
    hasClicked,
    prevEnd,
    onClose,
    onChange,
  ]);

  const renderDay = useCallback(
    (
      day: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      return React.cloneElement(dayComponent, {
        onClick: (e: React.MouseEvent) => {
          setHasClicked(true);
          e.stopPropagation();
          if (!begin) setBegin(day);
          else if (!end) {
            if (utils.isBeforeDay(day, begin)) {
              setEnd(begin);
              setBegin(day);
            } else {
              setEnd(day);
            }
            if (autoOk) {
              setPrevBegin(null);
              setPrevEnd(null);
            }
          } else {
            setBegin(day);
            setEnd(null);
          }
        },
        onMouseEnter: () => requestAnimationFrame(() => setHover(day)),
        onFocus: () => requestAnimationFrame(() => setHover(day)),
        className: clsx(classes.day, {
          [classes.hidden]: dayComponent.props.hidden,
          [classes.current]: dayComponent.props.current,
          [classes.isDisabled]: dayComponent.props.disabled,
          [classes.focusedRange]:
            (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
            (utils.isSameDay(day, min) && !utils.isSameDay(day, max)) ||
            (utils.isSameDay(day, max) && !utils.isSameDay(day, min)),
          [classes.focusedFirst]:
            utils.isSameDay(day, min) && !utils.isSameDay(day, max),
          [classes.focusedLast]:
            utils.isSameDay(day, max) && !utils.isSameDay(day, min),
          [classes.beginCap]: utils.isSameDay(day, min),
          [classes.endCap]: utils.isSameDay(day, max),
        }),
      });
    },
    [autoOk, begin, classes, end, max, min, utils],
  );

  const formatDate = useCallback(
    (date: MaterialUiPickersDate) =>
      utils.format(date, format || utils.dateFormat),
    [format, utils],
  );

  const defaultLabelFunc = useCallback(
    (date: any, invalid: string) => {
      if (!isOpen) {
        if (labelFunc) {
          return labelFunc([begin, end], invalid);
        }
        if (date && begin && end) {
          return `${formatDate(begin)} - ${formatDate(end)}`;
        }
        return emptyLabel || '';
      }
      if (prevBegin && prevEnd) {
        if (labelFunc) {
          return labelFunc([prevBegin, prevEnd], invalid);
        }
        return `${formatDate(prevBegin)} - ${formatDate(prevEnd)}`;
      }
      return emptyLabel || '';
    },
    [begin, emptyLabel, end, formatDate, isOpen, labelFunc, prevBegin, prevEnd],
  );

  return (
    <DatePicker
      {...props}
      className={clsx(classes.root)}
      value={begin}
      renderDay={renderDay}
      open={isOpen}
      onOpen={() => {
        setAccepted(false);
        setPrevBegin(begin);
        setPrevEnd(end);
        onOpen ? onOpen() : setOpen(true);
      }}
      onAccept={() => {
        if (!begin || !end) {
          if (hover && utils.isBeforeDay(begin, hover)) {
            setEnd(hover);
          } else {
            setEnd(begin);
            setBegin(hover);
          }
        }
        setPrevBegin(null);
        setPrevEnd(null);
        setAccepted(true);
      }}
      onClose={() => {
        onClose ? onClose() : setOpen(false);
      }}
      onChange={() => {}}
      labelFunc={defaultLabelFunc}
      DialogProps={{ className: classes.dateRangePickerDialog }}
    />
  );
};

export const useStyles = makeStyles(
  theme => {
    const focusedRangeColor = alpha(theme.palette.primary.main, 0.3);
    const focusedRangeGradient = `linear-gradient(to right, ${focusedRangeColor}, ${focusedRangeColor})`;
    const transparentRangeGradient = `linear-gradient(to right, rgba(0,0,0,0.0), rgba(0,0,0,0.0))`;
    return {
      dateRangePickerDialog: {
        '& .MuiPickersCalendar-transitionContainer': {
          minHeight: 218,
          marginTop: 10,
        },
      },
      day: {
        width: 40,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: 0,
        color: theme.palette.text.primary,
        // fontWeight: theme.typography.fontWeightMedium,
        fontWeight: 500,
        padding: 0,
        transition: 'none',
        '&::after': {
          borderRadius: '100%',
          bottom: 0,
          boxSizing: 'border-box',
          content: '""',
          height: 36,
          width: 36,
          left: 0,
          margin: 'auto',
          position: 'absolute',
          right: 0,
          top: 0,
          transform: 'scale(0)',
          zIndex: 2,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          '&::after': {
            backgroundColor: theme.palette.background.paper,
            border: `2px solid ${theme.palette.primary.main}`,
            bottom: -2,
            left: -2,
            height: 36,
            width: 36,
            right: -2,
            top: -2,
            boxSizing: 'content-box',
            transform: 'scale(1)',
          },
        },
        '& > .MuiIconButton-label': {
          zIndex: 3,
        },
      },
      hidden: {
        opacity: 0,
        pointerEvents: 'none',
      },
      current: {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
      isDisabled: {},
      focusedRange: {
        color: theme.palette.primary.contrastText,
        background: `${focusedRangeGradient} no-repeat 0/20px 40px, ${focusedRangeGradient} no-repeat 20px 0/20px 40px`,
        // fontWeight: theme.typography.fontWeightMedium,
        fontWeight: 500,
        width: 40,
        marginRight: 0,
        marginLeft: 0,
        borderRadius: 0,
      },
      dayDisabled: {
        pointerEvents: 'none',
        color: theme.palette.text.hint,
      },
      beginCap: {
        color: theme.palette.primary.contrastText,
        '&::after': {
          transform: 'scale(1)',
          backgroundColor: theme.palette.primary.main,
        },
      },
      endCap: {
        color: theme.palette.primary.contrastText,
        '&::after': {
          transform: 'scale(1)',
          backgroundColor: theme.palette.primary.main,
        },
      },
      focusedFirst: {
        background: `${transparentRangeGradient} no-repeat 0/20px 40px,${focusedRangeGradient} no-repeat 20px 0/20px 40px`,
      },
      focusedLast: {
        background: `${focusedRangeGradient} no-repeat 0/20px 40px,${transparentRangeGradient} no-repeat 20px 0/20px 40px`,
      },
      root: {
        marginTop: theme.spacing(1),
      },
    };
  },
  { name: 'DateRangePicker' },
);

export default DateRangePicker;
