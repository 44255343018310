import { IconButton, makeStyles, Typography } from '@material-ui/core';
// import AddIcon from '@material-ui/icons/Add';
// import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback } from 'react';
import { colors } from '../../themes';
import ConsigneeIcon from '../icons/Consignee.svg';
import ShipperIcon from '../icons/Shipper.svg';
import LoadStopForm from './LoadStopForm';
import { LoadStopFormData } from './types';

type Props = {
  isLoading: boolean;
  isShipper?: boolean;
  isConsignee?: boolean;
  loadStop: LoadStopFormData | undefined;
  orderW: string;
  removable?: boolean;
  onLoadChange?: (id: string, name: string, value: any) => void;
  onLoadStopChange: (
    orderW: string,
    id: string,
    name: string,
    value: any,
  ) => void;
  onLoadStopRemove?: (orderW: string, id: string) => void;
};

const LoadStopFormContainer: React.FC<Props> = ({
  isLoading,
  isShipper = false,
  isConsignee = false,
  loadStop,
  orderW,
  removable = false,
  onLoadChange,
  onLoadStopChange,
  onLoadStopRemove = (orderW: string, id: string) => {},
}) => {
  const classes = useStyles();

  const handleLoadStopRemove = useCallback(() => {
    onLoadStopRemove(orderW, loadStop!.idW);
  }, [orderW, loadStop, onLoadStopRemove]);

  if (!loadStop) {
    return null;
  }

  return (
    <div className={classes.loadStopContainer}>
      <div className={classes.loadStopHeader}>
        {isShipper ? (
          <img src={ShipperIcon} alt="Shipper" />
        ) : isConsignee ? (
          <img src={ConsigneeIcon} alt="Consignee" />
        ) : null}
        <Typography className={classes.loadStopName} variant="h4">
          {isShipper
            ? 'Shipper'
            : isConsignee
            ? 'Consignee'
            : `Stop # ${loadStop.stopNumber - 1}`}
        </Typography>
        {removable && (
          <IconButton
            aria-label="remove"
            className={classes.removeBtn}
            onClick={handleLoadStopRemove}
            size="small"
          >
            <Typography className={classes.removeName} variant="body2">
              Remove
            </Typography>
          </IconButton>
        )}
      </div>
      <div className={classes.loadStopDetail}>
        <LoadStopForm
          idW={loadStop.idW}
          data={loadStop}
          orderW={orderW}
          isShipper={isShipper}
          isConsignee={isConsignee}
          isLoading={isLoading}
          isMidStop={removable}
          onLoadStopChange={onLoadStopChange}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadStopContainer: {
    border: '1px solid #c8d9e4',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  loadStopHeader: {
    alignItems: 'center',
    backgroundColor: '#dae6ee',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 3),
  },
  loadStopName: {
    color: colors.textPrimary[600],
    flexGrow: 1,
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  loadStopDetail: {
    display: 'flex',
    padding: theme.spacing(3, 3, 1.5),
  },
  loadDetail: {},
  loadDetailHeader: {
    backgroundColor: '#fcf0d4',
    padding: theme.spacing(1, 3),
  },
  loadDetailTitle: {
    color: 'black',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  loadDetailForm: {
    padding: theme.spacing(3),
  },
  removeBtn: {
    color: colors.primary[600],
    marginLeft: theme.spacing(3),
  },
  removeName: {
    textTransform: 'uppercase',
    color: '#4F4F4F',
    textDecorationLine: 'underline',
  },
}));

export default LoadStopFormContainer;
