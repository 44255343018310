// @ts-nocheck

import {
  getAS400DisplayStatus,
  getDisplayStatus,
  LOAD_STATUS,
  ON_ASSIGNMENT_STATUS_LIST,
  ON_ASSIGNMENT_STATUS_TEXT,
  ON_TIME_STATUS_DROPOFF,
  ON_TIME_STATUS_PICKUP,
  ON_TIME_STATUS_TEXT,
  TRACKER_VISIBLE_AS400_STATUSES,
  TRACKER_VISIBLE_STATUSES,
} from 'doc-mate-store/lib/constants/load';
import { LOCATION_TYPE } from 'doc-mate-store/lib/constants/location';
import { useStores } from 'doc-mate-store/lib/hooks';
import { LoadTableRow, LoadType, Location } from 'doc-mate-store/lib/models';
import DateTimeRange from 'doc-mate-store/lib/models/DateTimeRange';
import { Organization } from 'doc-mate-store/lib/models/Organization';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Row } from 'react-table';
import { usaStates } from 'typed-usa-states';
import AS400Status from '../../components/DataTable/cells/AS400Status';
import DateDisplay from '../../components/DataTable/cells/DateDisplay';
import Link from '../../components/DataTable/cells/Link';
import LinkedLegs from '../../components/DataTable/cells/LinkedLegs';
import LoadDriverSelect from '../../components/DataTable/cells/LoadDriverSelect';
import LoadStatusSelect from '../../components/DataTable/cells/LoadStatusSelect';
import TimeDisplay from '../../components/DataTable/cells/TimeDisplay';
// import OnTimeTimestamp from '../../components/DataTable/cells/OnTimeTimestamp';
import DateFilter from '../../components/filters/DateFilter';
import TextAndSelectFilter from '../../components/filters/TextAndSelectFilter';
import TextFilter from '../../components/filters/TextFilter';
import TimeFilter from '../../components/filters/TimeFilter';
import { FilterAndColumn } from '../../types/filtering';

const MINIMUM_WIDTH = 80;
const DEFAULT_COLUMN_WIDTH = 200;
const LINKED_LEGS_WIDTH = 30;

const alphabeticalSort = (aVal: string, bVal: string) => {
  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }
  return 0;
};

const dateSort = (aVal: DateTimeRange | null, bVal: DateTimeRange | null) => {
  if (!aVal) {
    return -1;
  }
  if (!bVal) {
    return 1;
  }
  const aDate = moment(aVal.lower);
  const bDate = moment(bVal.lower);
  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
};

export function useFilterAndColumns() {
  const location = useLocation();
  const { rootStore } = useStores();
  const {
    me,
    organizations: organizations_,
    loadTypes: loadTypes_,
    locations: locations_,
  } = rootStore;

  const columnWidths = JSON.parse(localStorage.getItem('columnWidths') || '{}');

  const user = me ? me.maybeCurrent : undefined;

  const organizations: Organization[] = Array.from(organizations_.values());
  const organizationMap = organizations.map(organization => ({
    name: organization.name,
    value: organization.getRefId(),
  }));
  const organizationsJ = JSON.stringify(organizations);

  const locations: Location[] = Array.from(locations_.values());
  const locationTerminalMap = locations
    .filter(loc => loc.locationType === LOCATION_TYPE.TERMINAL)
    .map(loc => ({
      name: loc.name,
      value: loc.getRefId(),
    }));
  const expediteFilterOptions = [
    {
      name: 'Yes',
      value: 'true',
    },
    {
      name: 'No',
      value: 'false',
    },
  ];
  const locationsJ = JSON.stringify(locations);

  const loadTypes: LoadType[] = Array.from(loadTypes_.values());
  const loadTypeMap = loadTypes.map(loadType => ({
    name: loadType.name,
    value: loadType.getRefId(),
  }));
  const loadTypesJ = JSON.stringify(loadTypes);

  const sortLoadId = useCallback(
    (a: Row<LoadTableRow>, b: Row<LoadTableRow>) => {
      return a.original.id - b.original.id;
    },
    [],
  );

  const sortManifestNumber = useCallback(
    (a: Row<LoadTableRow>, b: Row<LoadTableRow>) => {
      return alphabeticalSort(
        a.original.manifestNumber,
        b.original.manifestNumber,
      );
    },
    [],
  );

  const sortDriver = useCallback(
    (a: Row<LoadTableRow>, b: Row<LoadTableRow>) => {
      return alphabeticalSort(a.original.driverName, b.original.driverName);
    },
    [],
  );

  const sortStatus = useCallback(
    (a: Row<LoadTableRow>, b: Row<LoadTableRow>) => {
      return alphabeticalSort(
        getDisplayStatus(a.original.status),
        getDisplayStatus(b.original.status),
      );
    },
    [],
  );

  const sortPickupWindow = useCallback(
    (a: Row<LoadTableRow>, b: Row<LoadTableRow>) => {
      return dateSort(a.original.pickupWindow, b.original.pickupWindow);
    },
    [],
  );

  const sortDropoffWindow = useCallback(
    (a: Row<LoadTableRow>, b: Row<LoadTableRow>) => {
      return dateSort(a.original.dropoffWindow, b.original.dropoffWindow);
    },
    [],
  );

  const filtersAndColumns = useMemo<FilterAndColumn<LoadTableRow>[]>(
    () => [
      {
        Header: ' ',
        column: {
          accessor: load => load.linkedLegs,
          Cell: LinkedLegs,
          disableFilters: true,
          id: 'linked_legs',
          show: true,
          sortType: sortLoadId,
          isAlwaysVisible: true,
          disableResizing: true,
          minWidth: LINKED_LEGS_WIDTH,
          width: LINKED_LEGS_WIDTH,
        },
        filtering: false,
      },
      {
        Header: 'On Time Status',
        column: false,
        filtering: {
          accessor: 'on_time_status',
          type: 'select',
          values: ON_TIME_STATUS_PICKUP.concat(ON_TIME_STATUS_DROPOFF).map(
            ots => ({
              name: ON_TIME_STATUS_TEXT[ots],
              value: ots,
            }),
          ),
        },
      },
      {
        Header: 'On Assignment Status',
        column: false,
        filtering: {
          accessor: 'on_assignment_status',
          type: 'select',
          values: ON_ASSIGNMENT_STATUS_LIST.map(oas => ({
            name: ON_ASSIGNMENT_STATUS_TEXT[oas],
            value: oas,
          })),
        },
      },
      {
        Header: 'DM-ID',
        column: {
          accessor: load => [
            `${load.dmId}`,
            `/l/${load.manifestLinkId}${location.search}`,
            load.manifestId,
            user && (user.isDispatcher || user.isBroker),
            // load.as400Status === AS400_STATUS.NA,
            true,
          ],
          Cell: Link,
          disableFilters: false,
          // filter: 'textSelectFirstElement',
          Filter: TextFilter,
          groupLabel: 'Load Details',
          id: 'dm_id__icontains',
          show: true,
          sortType: sortLoadId,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dm_id__icontains'] !== undefined
              ? columnWidths['dm_id__icontains'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dm_id__icontains']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: false,
      },
      {
        Header: 'DM MNFST #',
        column: {
          accessor: load => [
            `${load.manifestNumber}`,
            `/l/${load.manifestLinkId}${location.search}`,
            load.manifestId,
            user && (user.isDispatcher || user.isBroker),
            // load.as400Status === AS400_STATUS.NA,
            true,
          ],
          Cell: Link,
          disableFilters: false,
          filter: 'textSelectFirstElement',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__manifest__manifest_number',
          show: true,
          sortType: sortManifestNumber,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__manifest__manifest_number'] !== undefined
              ? columnWidths['order__manifest__manifest_number'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__manifest__manifest_number']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: false,
      },
      {
        Header: 'AS400 MNFST #',
        column: {
          accessor: load => load.as400ManifestNumber,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__manifest__as400_manifest_number',
          groupLabel: 'Load Details',
          show: false,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__manifest__as400_manifest_number'] !== undefined
              ? columnWidths['order__manifest__as400_manifest_number'] <
                MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__manifest__as400_manifest_number']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: false,
      },
      {
        Header: 'TRM',
        column: {
          accessor: load => load.terminal,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__manifest__revenue_terminal',
          groupLabel: 'Load Details',
          show: true,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__manifest__revenue_terminal'] !== undefined
              ? columnWidths['order__manifest__revenue_terminal'] <
                MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__manifest__revenue_terminal']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__manifest__revenue_terminal',
          type: 'selectMultiple',
          values: locationTerminalMap,
        },
      },
      {
        Header: 'CXR',
        column: {
          accessor: load => load.organization,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__manifest__organization',
          groupLabel: 'Load Details',
          show: false,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__manifest__organization'] !== undefined
              ? columnWidths['order__manifest__organization'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__manifest__organization']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__manifest__organization',
          type: 'selectMultiple',
          values: organizationMap,
        },
      },
      {
        Header: 'DRVR',
        column: {
          accessor: load => [
            load.driverName,
            load.driver,
            load.id,
            user &&
              (user.isDispatcher || user.isBroker) &&
              load.isAS400StatusValid &&
              load.isDatesProvided,
            load.linkedLegs,
          ],
          Cell: LoadDriverSelect,
          disableFilters: false,
          filter: 'textSelectFirstElement',
          Filter: TextAndSelectFilter,
          id: 'driver',
          show: true,
          groupLabel: 'Load Details',
          sortType: sortDriver,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['driver'] !== undefined
              ? columnWidths['driver'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['driver']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'driver',
          type: 'text',
        },
      },
      {
        Header: 'STS',
        column: {
          accessor: load => [
            getDisplayStatus(load.status),
            load.status,
            load.id,
            user &&
              (user.isDispatcher || user.isBroker) &&
              load.isAS400StatusValid &&
              load.isDatesProvided,
            load.linkedLegs,
          ],
          Cell: LoadStatusSelect,
          disableFilters: false,
          filter: 'textSelectFirstElement',
          Filter: TextAndSelectFilter,
          id: 'status__in',
          show: true,
          groupLabel: 'Load Details',
          sortType: sortStatus,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['status__in'] !== undefined
              ? columnWidths['status__in'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['status__in']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'status__in',
          type: 'selectMultiple',
          values: TRACKER_VISIBLE_STATUSES.map(status => ({
            value: status,
            name: getDisplayStatus(status),
          })),
        },
      },
      {
        Header: 'AS400 STS',
        column: {
          accessor: load => [
            getAS400DisplayStatus(load.as400Status),
            load.as400Status,
          ],
          Cell: AS400Status,
          disableFilters: false,
          filter: 'textSelectFirstElement',
          Filter: TextAndSelectFilter,
          id: 'order__manifest__as400_status__in',
          groupLabel: 'Load Details',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__manifest__as400_status__in'] !== undefined
              ? columnWidths['order__manifest__as400_status__in'] <
                MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__manifest__as400_status__in']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__manifest__as400_status__in',
          type: 'selectMultiple',
          values: TRACKER_VISIBLE_AS400_STATUSES.map(status => ({
            value: status,
            name: getAS400DisplayStatus(status),
          })),
        },
      },
      {
        Header: 'CUST ORDR #',
        column: {
          accessor: load => load.customerOrderNumber,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__customer_order_number',
          groupLabel: 'Load Details',
          show: false,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__customer_order_number'] !== undefined
              ? columnWidths['order__customer_order_number'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__customer_order_number']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: false,
      },
      {
        //TODO: Replace this with Pickup?
        Header: 'SHPR',
        column: {
          accessor: load => load.shipper,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__shipper__stop__name',
          show: true,
          groupLabel: 'Shipper Details',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__shipper__stop__name'] !== undefined
              ? columnWidths['order__shipper__stop__name'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__shipper__stop__name']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__shipper__stop__name',
          type: 'selectMultiple',
          values: organizationMap,
        },
      },
      {
        Header: 'PO # SHPR',
        column: {
          accessor: load => load.poNumberShipper,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__po_number_shipper',
          groupLabel: 'Shipper Details',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__po_number_shipper'] !== undefined
              ? columnWidths['order__po_number_shipper'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__po_number_shipper']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__po_number_shipper',
          type: 'text',
        },
      },
      {
        Header: 'PU TM',
        column: {
          accessor: load => {
            if (
              load.status === LOAD_STATUS.PENDING_ACCEPTANCE ||
              load.status === LOAD_STATUS.ASSIGNED ||
              load.status === LOAD_STATUS.ACTIVE
            ) {
              return [true, load.pickupWindow];
            }
            if (!load.etas || !load.etas.pickupEta || load.pickupArrivedOn) {
              //return [false, load.pickupArrivedOn];
              return [false, load.pickupWindow];
            }
            //return [true, load.etas.pickupEta.eta];
            return [true, load.pickupWindow];
          },
          Cell: TimeDisplay,
          disableFilters: false,
          disableSortBy: true,
          filter: 'timeRangeEta',
          Filter: TimeFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup_time',
          show: true,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup_time'] !== undefined
              ? columnWidths['pickup_time'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup_time']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup_time',
          type: 'date',
        },
      },
      {
        Header: 'PU DT',
        column: {
          accessor: load => load.pickupWindow,
          Cell: DateDisplay,
          disableFilters: false,
          filter: 'dateRange',
          Filter: DateFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup_window',
          show: true,
          sortType: sortPickupWindow,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup_window'] !== undefined
              ? columnWidths['pickup_window'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup_window']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup_window',
          type: 'date',
        },
      },
      {
        Header: 'CNEE',
        column: {
          accessor: load => load.consignee,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Consignee Details',
          id: 'order__consignee__stop__name',
          show: true,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__consignee__stop__name'] !== undefined
              ? columnWidths['order__consignee__stop__name'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__consignee__stop__name']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__consignee__stop__name',
          type: 'selectMultiple',
          values: organizationMap,
        },
      },
      {
        Header: 'PO # CNEE',
        column: {
          accessor: load => load.poNumberConsignee,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          id: 'order__po_number_consignee',
          groupLabel: 'Consignee Details',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__po_number_consignee'] !== undefined
              ? columnWidths['order__po_number_consignee'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__po_number_consignee']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__po_number_consignee',
          type: 'text',
        },
      },
      {
        Header: 'DO TM',
        column: {
          accessor: load => {
            if (
              load.status === LOAD_STATUS.PENDING_ACCEPTANCE ||
              load.status === LOAD_STATUS.ASSIGNED ||
              load.status === LOAD_STATUS.ACTIVE
            ) {
              // return [
              //   true,
              //   load.dropoffOnTimeStatus(),
              //   load.pickupWindow && load.pickupWindow.upper,
              // ];
              return [true, load.dropoffWindow];
            }
            if (!load.etas || !load.etas.dropoffEta || load.dropoffArrivedOn) {
              //  return [false, load.dropoffOnTimeStatus(), load.dropoffArrivedOn];
              return [false, load.dropoffWindow];
            }
            //return [true, load.dropoffOnTimeStatus(), load.etas.dropoffEta.eta];
            return [true, load.dropoffWindow];
          },
          Cell: TimeDisplay,
          disableFilters: false,
          disableSortBy: true,
          filter: 'timeRangeEta',
          Filter: TimeFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff_time',
          show: true,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff_time'] !== undefined
              ? columnWidths['dropoff_time'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff_time']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff_time',
          type: 'date',
        },
      },
      {
        Header: 'DO DT',
        column: {
          accessor: load => load.dropoffWindow,
          Cell: DateDisplay,
          disableFilters: false,
          filter: 'dateRange',
          Filter: DateFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff_window',
          show: true,
          sortType: sortDropoffWindow,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff_window'] !== undefined
              ? columnWidths['dropoff_window'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff_window']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff_window',
          type: 'date',
        },
      },
      {
        Header: 'CMDTY',
        column: {
          accessor: load => load.loadType,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__load_type',
          show: true,
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__load_type'] !== undefined
              ? columnWidths['order__load_type'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__load_type']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__load_type',
          type: 'selectMultiple',
          values: loadTypeMap,
        },
      },
      {
        Header: 'WT',
        column: {
          accessor: load => load.loadWeight,
          disableFilters: false,
          Filter: TextFilter,
          groupLabel: 'Load Details',
          id: 'order__load_weight__icontains',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__load_weight__icontains'] !== undefined
              ? columnWidths['order__load_weight__icontains'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__load_weight__icontains']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__load_weight__icontains',
          type: 'text',
        },
      },
      {
        Header: 'DIST',
        column: {
          accessor: load => load.estimatedTravelDistance,
          disableFilters: false,
          Filter: TextFilter,
          groupLabel: 'Load Details',
          id: 'order__estimated_travel_distance__icontains',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__estimated_travel_distance__icontains'] !==
            undefined
              ? columnWidths['order__estimated_travel_distance__icontains'] <
                MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__estimated_travel_distance__icontains']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__estimated_travel_distance__icontains',
          type: 'text',
        },
      },
      {
        Header: 'BOL #',
        column: {
          accessor: load => load.billOfLadingNumber,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__manifest__bill_of_lading_number',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__manifest__bill_of_lading_number'] !== undefined
              ? columnWidths['order__manifest__bill_of_lading_number'] <
                MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__manifest__bill_of_lading_number']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__manifest__bill_of_lading_number',
          type: 'text',
        },
      },
      {
        Header: 'TLR #',
        column: {
          accessor: load => load.trailerNumber,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__trailer_number',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__trailer_number'] !== undefined
              ? columnWidths['order__trailer_number'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__trailer_number']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__trailer_number',
          type: 'text',
        },
      },
      {
        Header: 'SL #',
        column: {
          accessor: load => load.sealNumber,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__seal_number',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__seal_number'] !== undefined
              ? columnWidths['order__seal_number'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__seal_number']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__seal_number',
          type: 'text',
        },
      },
      {
        Header: 'EQUIP TYP',
        column: {
          accessor: load => load.specialEquipment,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'special_equipment',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['special_equipment'] !== undefined
              ? columnWidths['special_equipment'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['special_equipment']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: false,
      },
      {
        Header: 'EXP',
        column: {
          accessor: load => load.expedite,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__expedite__in',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__expedite__in'] !== undefined
              ? columnWidths['order__expedite__in'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__expedite__in']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__expedite__in',
          type: 'boolean',
          values: expediteFilterOptions,
        },
      },
      {
        Header: 'NOTES',
        column: {
          accessor: load => load.loadDescription,
          disableFilters: false,
          Filter: TextFilter,
          groupLabel: 'Load Details',
          id: 'order__load___icontains',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__load_notes__icontains'] !== undefined
              ? columnWidths['order__load_notes__icontains'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__load_notes__icontains']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__load_notes__icontains',
          type: 'text',
        },
      },
      {
        Header: 'BKR',
        column: {
          accessor: load => load.broker,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Load Details',
          id: 'order__broker',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['order__broker'] !== undefined
              ? columnWidths['order__broker'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['order__broker']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'order__broker',
          type: 'text',
        },
      },
      {
        Header: 'PU ADR 1',
        column: {
          accessor: load => load.pickupStreet1,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup__location__street1',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup__location__street1'] !== undefined
              ? columnWidths['pickup__location__street1'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup__location__street1']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup__location__street1',
          type: 'text',
        },
      },
      {
        Header: 'PU ADR 2',
        column: {
          accessor: load => load.pickupStreet2,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup__location__street2',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup__location__street2'] !== undefined
              ? columnWidths['pickup__location__street2'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup__location__street2']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup__location__street2',
          type: 'text',
        },
      },
      {
        Header: 'PU CTY',
        column: {
          accessor: load => load.pickupCity,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup__location__city',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup__location__city'] !== undefined
              ? columnWidths['pickup__location__city'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup__location__city']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup__location__city',
          type: 'text',
        },
      },
      {
        Header: 'PU ST',
        column: {
          accessor: load => load.pickupState,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup__location__state',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup__location__state'] !== undefined
              ? columnWidths['pickup__location__state'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup__location__state']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup__location__state',
          type: 'selectMultiple',
          values: usaStates.map(state => ({
            name: state.name,
            value: state.abbreviation,
          })),
        },
      },
      {
        Header: 'PU ZIP',
        column: {
          accessor: load => load.pickupZipCode,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Shipper Details',
          id: 'pickup__location__zip_code',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['pickup__location__zip_code'] !== undefined
              ? columnWidths['pickup__location__zip_code'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['pickup__location__zip_code']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'pickup__location__zip_code',
          type: 'text',
        },
      },
      {
        Header: 'DO ADR 1',
        column: {
          accessor: load => load.dropoffStreet1,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff__location__street1',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff__location__street1'] !== undefined
              ? columnWidths['dropoff__location__street1'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff__location__street1']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff__location__street1',
          type: 'text',
        },
      },
      {
        Header: 'DO ADR 2',
        column: {
          accessor: load => load.dropoffStreet2,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff__location__street2',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff__location__street2'] !== undefined
              ? columnWidths['dropoff__location__street2'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff__location__street2']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff__location__street2',
          type: 'text',
        },
      },
      {
        Header: 'DO CTY',
        column: {
          accessor: load => load.dropoffCity,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff__location__city',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff__location__city'] !== undefined
              ? columnWidths['dropoff__location__city'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff__location__city']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff__location__city',
          type: 'text',
        },
      },
      {
        Header: 'DO ST',
        column: {
          accessor: load => load.dropoffState,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff__location__state',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff__location__state'] !== undefined
              ? columnWidths['dropoff__location__state'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff__location__state']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff__location__state',
          type: 'selectMultiple',
          values: usaStates.map(state => ({
            name: state.name,
            value: state.abbreviation,
          })),
        },
      },
      {
        Header: 'DO ZIP',
        column: {
          accessor: load => load.dropoffZipCode,
          disableFilters: false,
          filter: 'textSelect',
          Filter: TextAndSelectFilter,
          groupLabel: 'Consignee Details',
          id: 'dropoff__location__zip_code',
          minWidth: MINIMUM_WIDTH,
          width:
            columnWidths['dropoff__location__zip_code'] !== undefined
              ? columnWidths['dropoff__location__zip_code'] < MINIMUM_WIDTH
                ? MINIMUM_WIDTH
                : columnWidths['dropoff__location__zip_code']
              : DEFAULT_COLUMN_WIDTH,
        },
        filtering: {
          accessor: 'dropoff__location__zip_code',
          type: 'text',
        },
      },
    ],
    [organizationsJ, loadTypesJ, locationsJ], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return filtersAndColumns;
}
