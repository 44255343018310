import { makeStyles, Popover } from '@material-ui/core';
import { LOAD_STATUS } from 'doc-mate-store/lib/constants/load';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import SelectWrapper from './SelectWrapper';
type Props = {
  cell: {
    value: [string, LOAD_STATUS, number, boolean, number[]];
  };
};

const LoadStatusSelect: React.FC<Props> = props => {
  const classes = useStyles();
  const { cell } = props;
  const {
    value: [statusDisplay, status, loadId, isDispatcher, linkedLegs], // eslint-disable-line @typescript-eslint/no-unused-vars
  } = cell;

  const [copy, setCopy] = useState(false);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  return (
    <>
      <SelectWrapper
        isDispatcher={isDispatcher}
        status={status}
        loadId={loadId}
        linkedLegs={linkedLegs}
      />
      <Popover
        id={`${loadId}`}
        open={copy}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    '& > .MuiSelect-select.MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingRight: 0,
    },
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default React.memo(observer(LoadStatusSelect));
