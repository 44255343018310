import CssBaseline from '@material-ui/core/CssBaseline';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AuthenticationRequired from './containers/AuthenticationRequired';
// import DispatcherRequired from './containers/DispatcherRequired';
import LoadStore from './containers/LoadStore';
import Login from './containers/Login';
import Logout from './containers/Logout';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Pusher from './containers/Pusher';
import ResetPassword from './containers/ResetPassword';
// import Schedule from './containers/Schedule';
import RedirectToStore from './containers/RedirectToStore';
import Track from './containers/Track';
import TrackForm from './containers/TrackForm';
import TrackerLoadList from './containers/TrackerLoadList';

const App: React.FC = () => {
  return (
    <Router>
      <CssBaseline />
      <Pusher>
        <Route path="">
          <LoadStore>
            <Route path="/" exact>
              <AuthenticationRequired>
                <TrackerLoadList />
              </AuthenticationRequired>
            </Route>
            <Route path="/track" exact>
              <AuthenticationRequired>
                <TrackForm />
              </AuthenticationRequired>
            </Route>
            {/***<Route path="/drivers" exact>
              <DispatcherRequired>
                <Schedule />
              </DispatcherRequired>
            </Route>***/}
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/resetpassword" exact>
              <ResetPassword />
            </Route>
            <Route path="/l/:orderLegLinkId">
              <Track />
            </Route>
            <Route path="/privacypolicy" exact>
              <PrivacyPolicy />
            </Route>

            <Route path="/records/organization/:organizationId">
              <RedirectToStore />
            </Route>
          </LoadStore>
        </Route>

        <Route path="/logout" exact>
          <Logout />
        </Route>
      </Pusher>
    </Router>
  );
};

export default observer(App);
