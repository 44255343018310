import {
  Checkbox,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { colors } from '../../themes';
import {
  AccessorialFormData,
  AccessorialLegsData,
  OrderFormData,
  OrderLegFormData,
} from './types';

type Props = {
  idW: string;
  isLoading: boolean;
  dmId: string;
  accessorial: AccessorialFormData;
  orderLeg: OrderLegFormData;
  load: OrderFormData | undefined;
  withCheckBox: boolean;
  onAccessorialChange: (id: string, name: string, value: any) => void;
};

const AccessorialLegForm: React.FC<Props> = ({
  idW,
  isLoading,
  dmId,
  accessorial,
  orderLeg,
  load,
  withCheckBox,
  onAccessorialChange,
}) => {
  const classes = useStyles();
  const initialAccessorialLeg = accessorial.legs.filter(
    accLeg => accLeg.legW === orderLeg.idW,
  );
  const initialData: AccessorialLegsData = initialAccessorialLeg.length
    ? {
        legW: initialAccessorialLeg[0].legW,
        percentage: initialAccessorialLeg[0].percentage,
      }
    : {
        legW: orderLeg.idW,
        percentage: '',
      };
  const [accessorialLegData, setAccessorialLegData] = useState<
    AccessorialLegsData
  >(initialData);

  const [isChecked, setIsChecked] = useState<boolean>(
    withCheckBox ? !!initialAccessorialLeg.length : true,
  );

  useEffect(() => {
    const accessorialLegs = accessorial.legs.filter(
      accLeg => accLeg.legW !== accessorialLegData.legW,
    );
    if (isChecked) {
      accessorialLegs.push(accessorialLegData);
    }

    onAccessorialChange(idW, 'legs', accessorialLegs);
  }, [isChecked, accessorialLegData]); //eslint-disable-line  react-hooks/exhaustive-deps

  const handleDriverRevenueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      setAccessorialLegData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setAccessorialLegData],
  );

  if (!load) {
    return null;
  }

  return (
    <div className={classes.assessorialDetailRow}>
      <div className={classes.assessorialDetail}>
        <div className={classes.assessorialDetailItem}>
          <Typography
            className={classes.assessorialDetailItemValue}
            variant="body2"
          >
            {orderLeg.legNumber === 1 ? load.orderNumber : ''}
          </Typography>
        </div>
      </div>
      {withCheckBox && (
        <div className={classes.assessorialDetail}>
          <div className={classes.assessorialDetailItem}>
            <Checkbox
              color="primary"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className={classes.checkbox}
            />
          </div>
        </div>
      )}
      <div className={classes.assessorialDetail}>
        <div className={classes.assessorialDetaildmId}>
          <Typography
            className={classes.assessorialDetailItemValue}
            variant="body2"
          >
            {dmId}
          </Typography>
        </div>
      </div>

      <div className={classes.assessorialDetail}>
        <TextValidator
          name="percentage"
          className={classes.inputWithHeader}
          disabled={!isChecked || isLoading}
          inputProps={{ min: '0', step: '0.01', max: '100', type: 'number' }}
          label="Drvr Revenue"
          onChange={handleDriverRevenueChange}
          required={!!isChecked}
          errorMessages={['This field is required.']}
          size="small"
          value={isChecked ? accessorialLegData.percentage : ''}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  assessorialDetailRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  assessorialDetail: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 2),
  },
  assessorialDetailItem: { minWidth: 80 },
  assessorialDetaildmId: { minWidth: 150 },
  assessorialDetailItemName: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
  },
  assessorialDetailItemValue: {
    color: 'black',
    fontWeight: 500,
    fontSize: 16,
  },
  input: {
    margin: theme.spacing(0, 1, 1.5),
    minWidth: 140,
  },
  inputWithHeader: {
    minWidth: 160,
  },
  notes: {
    margin: theme.spacing(0, 1, 1.5),
    width: '100%',
  },
  assessorialHeader: {
    alignItems: 'center',
    backgroundColor: '#C8D9E4',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  assessorialHeaderName: {
    color: '#0D374B',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  checkbox: {
    padding: theme.spacing(0.75),
  },
}));

export default React.memo(AccessorialLegForm);
