import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import theme from '../../themes';

type Props = {
  children: React.ReactNode;
};

const Root: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>{children}</div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export default observer(Root);
