import {
  getAPIStatus,
  LOAD_STATUS,
  TRACKER_VISIBLE_STATUSES,
  VISIBLE_ID_FETCH_LIMIT,
} from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import { User } from 'doc-mate-store/lib/models';
import { FetchLoadQuery } from 'doc-mate-store/lib/models/LoadQuery';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import ManifestBuilderDialog from '../../components/ManifestBuilder';
import TrackerAppBar from '../../components/TrackerAppBar';
import { PusherContext } from '../../contexts';
import { useWebStores } from '../../hooks';
import { WebStore } from '../../models';
import DataTableProvider from '../DataTableProvider';
import Root from '../Root';
import Content from './Content';
import { useFilterAndColumns } from './hooks';

type Props = {};

async function fetcher(
  webStore: WebStore,
  user: User,
  filters: FetchLoadQuery,
  statuses?: LOAD_STATUS[],
  offset?: string,
) {
  const count = await webStore.fetchLoads(
    {
      // shipper_or_receiver__username: user.username,
      ...filters,
    },
    statuses || TRACKER_VISIBLE_STATUSES,
    offset,
  );
  return count;
}

// async function fetcherNext(
//   webStore: WebStore,
//   user: User,
//   filters: FetchLoadQuery,
//   url: string,
//   statuses?: LOAD_STATUS[],
// ) {
//   const nextUrl = await webStore.fetchLoads(
//     {
//       ...filters,
//     },
//     statuses || TRACKER_VISIBLE_STATUSES,
//     url,
//   );
//   return nextUrl;
// }

const TrackerLoadList: React.FC<Props> = () => {
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const { websocket } = useContext(PusherContext);
  const { columnFilters } = webStore;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters = {
    ...columnFilters!.filters,
  };
  const prevFilterValues = useRef<FetchLoadQuery>();
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadBuilderOpen, setLoadBuilderOpen] = useState(false);
  const [firstRun, setFirstRun] = useState(true);

  const { me } = rootStore;
  const user = me ? me.maybeCurrent : undefined;
  const isDispatcher = user && user.isDispatcher;
  const isBroker = user && user.isBroker;
  const { filterDrawerOpen } = webStore;
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);

  const isFiltering = !!Object.values(filters).filter(val => val).length;

  const handleFilterDrawerOpen = useCallback(
    (open: boolean) => {
      webStore.setFilterDrawer(open);
    },
    [webStore],
  );

  const handleFilterQuery = useCallback(
    (filterValues: FetchLoadQuery) => {
      const newFilter =
        filterValues &&
        filterValues.status__in &&
        filters &&
        filters.status__in &&
        filters.status__in!.filter((val: string) =>
          filterValues.status__in!.includes(getAPIStatus(val) as LOAD_STATUS),
        );
      const updatedFilter =
        !!newFilter && newFilter.length ? newFilter : 'None';

      return filterValues &&
        filterValues.status__in &&
        filters &&
        filters.status__in
        ? updatedFilter
        : !!filterValues.status__in
        ? filterValues.status__in
        : filters.status__in;
    },
    [filters],
  );

  const handleFilterChange = useCallback(
    async (
      filterValues: FetchLoadQuery,
      showLoading: boolean = true,
      forceReload: boolean = false,
    ) => {
      // Run query when filters change.

      if (user) {
        if (!forceReload && isEqual(prevFilterValues.current, filterValues)) {
          return;
        }

        prevFilterValues.current = filterValues;
        setLoading(showLoading);
        setPageNumber(0);
        webStore.clearVisibleIds();
        await requestAnimationFrame(async () => {
          await webStore.fetchPresetTable();
          const newCount = await fetcher(
            webStore,
            user,
            { ...filters, ...filterValues },
            handleFilterQuery(filterValues),
          );
          setCount(newCount);

          // setFetchLoadsNext(nextUrl);
          setLoading(false);
          setFirstRun(false);
        });
      }
    },
    [filters, handleFilterQuery], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFilterRerun = useCallback(() => {
    if (prevFilterValues.current) {
      handleFilterChange(prevFilterValues.current, false);
    }
  }, [handleFilterChange]);

  const handleForceReload = useCallback(() => {
    if (prevFilterValues.current) {
      handleFilterChange(prevFilterValues.current, true, true);
    }
  }, [handleFilterChange]);

  const handleLoadBuilderClose = () => {
    rootStore.resetCopyLoadId();
    setLoadBuilderOpen(false);
  };

  const handleLoadBuilderOpen = () => {
    setLoadBuilderOpen(true);
  };

  useEffect(() => {
    if (websocket) {
      websocket.registerCallback(handleFilterRerun);
    }
  }, [handleFilterRerun, websocket]);

  // const handleFetchMore = useCallback(() => {
  //   if (fetching) {
  //     return;
  //   }
  //   const filterValues = prevFilterValues.current;
  //   if (fetchLoadsNext && user && filterValues) {
  //     setFetching(true);
  //     requestAnimationFrame(async () => {
  //       const nextUrl = await fetcherNext(
  //         webStore,
  //         user,
  //         filterValues,
  //         fetchLoadsNext,
  //         filterValues.status__in,
  //       );
  //       // setFetchLoadsNext(nextUrl);
  //       if (!nextUrl || !isFiltering) {
  //         setFetching(false);
  //       }
  //     });
  //   }
  // }, [fetching, fetchLoadsNext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      const filterValues = prevFilterValues.current;
      if (user) {
        if (!firstRun) {
          setLoading(true);
          setPageNumber(0);
          webStore.clearVisibleIds();
          requestAnimationFrame(async () => {
            await webStore.fetchPresetTable();
            const newCount = await fetcher(
              webStore,
              user,
              { ...columnFilters!.filters, ...filterValues },
              columnFilters!.filters &&
                columnFilters!.filters.status__in &&
                filterValues &&
                filterValues.status__in
                ? columnFilters!.filters.status__in!.filter((val: string) =>
                    filterValues.status__in!.includes(
                      getAPIStatus(val) as LOAD_STATUS,
                    ),
                  )
                : filterValues && !!filterValues.status__in
                ? filterValues.status__in
                : columnFilters!.filters.status__in,
            );
            setCount(newCount);
            setFetching(false);
            setLoading(false);
          });
        }
      }
    })();
  }, [isFiltering, columnFilters!.filters, prevFilterValues, webStore]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrevPage = useCallback(async () => {
    const filterValues = prevFilterValues.current;
    if (user) {
      setFetching(true);
      webStore.clearVisibleIds();
      const offsetPageNumber = pageNumber;
      setPageNumber(prevState => prevState - 1);

      await fetcher(
        webStore,
        user,
        { ...filters, ...filterValues },
        handleFilterQuery(filterValues!),
        `${(offsetPageNumber - 1) * VISIBLE_ID_FETCH_LIMIT}`,
      );
      setFetching(false);
    }
  }, [user, webStore, pageNumber, filters, handleFilterQuery]);

  const handleNextPage = useCallback(async () => {
    const filterValues = prevFilterValues.current;

    if (user) {
      setFetching(true);
      webStore.clearVisibleIds();
      const offsetPageNumber = pageNumber;
      setPageNumber(prevState => prevState + 1);

      await fetcher(
        webStore,
        user,
        { ...filters, ...filterValues },
        handleFilterQuery(filterValues!),
        `${(offsetPageNumber + 1) * VISIBLE_ID_FETCH_LIMIT}`,
      );
      setFetching(false);
    }
  }, [user, webStore, pageNumber, filters, handleFilterQuery]);

  const handleNewestPage = useCallback(async () => {
    const filterValues = prevFilterValues.current;

    if (user) {
      setFetching(true);
      webStore.clearVisibleIds();
      setPageNumber(0);

      await fetcher(
        webStore,
        user,
        { ...filters, ...filterValues },
        handleFilterQuery(filterValues!),
      );
      setFetching(false);
    }
  }, [user, webStore, filters, handleFilterQuery]);

  const handleOldestPage = useCallback(async () => {
    const filterValues = prevFilterValues.current;

    if (user) {
      setFetching(true);
      webStore.clearVisibleIds();
      const maxPage = Math.floor((count - 1) / VISIBLE_ID_FETCH_LIMIT);
      setPageNumber(maxPage);
      await fetcher(
        webStore,
        user,
        { ...filters, ...filterValues },
        handleFilterQuery(filterValues!),
        `${maxPage * VISIBLE_ID_FETCH_LIMIT}`,
      );
      setFetching(false);
    }
  }, [user, webStore, count, filters, handleFilterQuery]);

  const filterAndColumns = useFilterAndColumns();

  return (
    <Root>
      <Helmet>
        <title>
          Loads | Doc-Mate-
          {isDispatcher ? 'Dispatcher' : isBroker ? 'Brokerage' : 'Tracker'}
        </title>
        <meta name="apple-itunes-app" content="app-id=1490860416" />
      </Helmet>
      <TrackerAppBar subtitle="Tracker" me={user} />
      <DataTableProvider
        filterAndColumns={filterAndColumns}
        onFilterChange={handleFilterChange}
      >
        <Content
          fetching={fetching}
          filterDrawerOpen={filterDrawerOpen}
          loading={loading}
          pageNumber={pageNumber}
          count={count}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
          onNewestPage={handleNewestPage}
          onOldestPage={handleOldestPage}
          onFilterDrawerOpen={handleFilterDrawerOpen}
          onLoadBuilderOpen={handleLoadBuilderOpen}
          onForceReload={handleForceReload}
          me={user}
        />
      </DataTableProvider>
      {user && (user.isDispatcher || user.isBroker) && (
        <ManifestBuilderDialog
          open={loadBuilderOpen}
          onClose={handleLoadBuilderClose}
        />
      )}
    </Root>
  );
};

export default observer(TrackerLoadList);
