import { makeStyles, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import React from 'react';
type Props = {
  children?: React.ReactNode;
  className?: string;
  inner?: boolean;
  label: string;
  onClick?: () => void;
  textClassName?: string;
  showDownloadIcon?: boolean;
};

const Labeled: React.FC<Props> = ({
  children,
  className,
  inner,
  label,
  onClick,
  textClassName,
  showDownloadIcon = false,
}) => {
  const classes = useStyles();
  return (
    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="overline"
          component="label"
          className={[
            inner ? classes.inner : '',
            classes.label,
            textClassName,
          ].join(' ')}
        >
          {label}
        </Typography>
        {showDownloadIcon && (
          <span
            onClick={onClick}
            style={{
              cursor: 'pointer',
              paddingLeft: 4,
            }}
          >
            <CloudDownloadIcon className={classes.icon} />
          </span>
        )}
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  inner: {
    color: `${theme.palette.grey[900]} !important`,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
  },
  label: {
    color: theme.palette.grey[500],
    clear: 'both',
    display: 'block',
    fontSize: theme.typography.fontSize,
    userSelect: 'none',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 16,
    height: 16,
    '&:hover': {
      width: 20,
      height: 20,
    },
  },
}));

export default Labeled;
