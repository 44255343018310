import { makeStyles } from '@material-ui/core';
import React from 'react';
import TruckIcon from './Truck.svg';

type Props = {
  heading?: number;
  lat: number;
  lng: number;
  map: any;
};

const TruckMarker: React.FC<Props> = ({ heading, lat, lng, map }) => {
  const classes = useStyles();
  const zoom = map.getZoom();

  let scaleX = (zoom / 16.0) * 0.7;
  let scaleY = (zoom / 16.0) * 0.7;
  const transform = [
    'translate(-50%,-50%)',
    `scale(${scaleX},${scaleY})`,
    `rotate(${heading || 0}deg)`,
  ];

  return (
    <div
      className={classes.root}
      style={{
        transform: transform.join(' '),
        transformOrigin: 'center center',
        transformStyle: 'preserve-3d',
      }}
    >
      <img src={TruckIcon} alt="Truck" className={classes.icon} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
  },
  icon: {},
}));

export default TruckMarker;
