"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.manifestRef = exports.Manifest = void 0;
var mobx_keystone_1 = require("mobx-keystone");
var models_1 = require("../utils/models");
var LoadUnit_1 = require("./LoadUnit");
var Location_1 = require("./Location");
var Organization_1 = require("./Organization");
var User_1 = require("./User");
var Manifest = /** @class */ (function (_super) {
    __extends(Manifest, _super);
    function Manifest() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id); };
        return _this;
    }
    Manifest_1 = Manifest;
    Manifest.prototype.update = function (data) {
        var rootStore = (0, mobx_keystone_1.getRootStore)(this);
        if (!rootStore) {
            return;
        }
        var result = Manifest_1._formatData(rootStore, data);
        if (result) {
            Object.assign(this, result);
        }
    };
    var Manifest_1;
    Manifest._formatData = function (rootStore, data) {
        var manifest = __assign({}, data);
        try {
            if (!rootStore.locations.has("".concat(data.revenueTerminal))) {
                rootStore.fetchLocation(data.revenueTerminal);
            }
            manifest.revenueTerminal = (0, Location_1.locationRef)("".concat(data.revenueTerminal));
            if (data.billTo) {
                if (!rootStore.locations.has("".concat(data.billTo))) {
                    rootStore.fetchLocation(data.billTo);
                }
                manifest.billTo = (0, Location_1.locationRef)("".concat(data.billTo));
            }
            if (data.billAsUnit) {
                var billAsUnit = new LoadUnit_1.LoadUnit(data.billAsUnit);
                rootStore.loadUnits.set("".concat(billAsUnit.id), billAsUnit);
                manifest.billAsUnit = (0, LoadUnit_1.loadUnitRef)(billAsUnit);
            }
            else {
                manifest.billAsUnit = null;
            }
            if (data.creator) {
                if (!rootStore.users.has("".concat(data.creator))) {
                    rootStore.fetchUser(data.creator);
                }
                manifest.creator = (0, User_1.userRef)("".concat(data.creator));
            }
            else {
                manifest.creator = null;
            }
            if (data.organization) {
                if (!rootStore.organizations.has("".concat(data.organization))) {
                    rootStore.fetchOrganization(data.organization);
                }
                manifest.organization = (0, Organization_1.organizationRef)("".concat(data.organization));
            }
            else {
                manifest.organization = null;
            }
            manifest.billOfLadingNumber = data.billOfLadingNumber
                ? data.billOfLadingNumber
                : '';
            return manifest;
        }
        catch (error) {
            console.warn('[WARN] error', error);
            return null;
        }
    };
    Manifest.create = function (rootStore, data) {
        var result = Manifest_1._formatData(rootStore, data);
        if (result) {
            var newManifest = new Manifest_1(result);
            return newManifest;
        }
        else {
            return null;
        }
    };
    __decorate([
        (0, models_1.asDate)('created')
    ], Manifest.prototype, "createdOnDate", void 0);
    __decorate([
        (0, models_1.asDate)('modified')
    ], Manifest.prototype, "modifiedOnDate", void 0);
    __decorate([
        mobx_keystone_1.modelAction
    ], Manifest.prototype, "update", null);
    Manifest = Manifest_1 = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/Manifest')
    ], Manifest);
    return Manifest;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    manifestNumber: (0, mobx_keystone_1.prop)(),
    as400ManifestNumber: (0, mobx_keystone_1.prop)(),
    billOfLadingNumber: (0, mobx_keystone_1.prop)(),
    billTo: (0, mobx_keystone_1.prop)(),
    billToThirdParty: (0, mobx_keystone_1.prop)(),
    billToOptions: (0, mobx_keystone_1.prop)(),
    billAs: (0, mobx_keystone_1.prop)(),
    billAsUnit: (0, mobx_keystone_1.prop)(),
    billRevenue: (0, mobx_keystone_1.prop)(),
    revenueTerminal: (0, mobx_keystone_1.prop)(),
    organization: (0, mobx_keystone_1.prop)(),
    creator: (0, mobx_keystone_1.prop)(),
    created: (0, mobx_keystone_1.prop)(),
    modified: (0, mobx_keystone_1.prop)(),
})));
exports.Manifest = Manifest;
exports.manifestRef = (0, mobx_keystone_1.rootRef)('doc-mate/ManifestRef', {
    getId: function (target) {
        if (target instanceof Manifest) {
            return "".concat(target.id);
        }
    },
});
