import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  TRACKER_VISIBLE_STATUSES,
  getChipDisplayClass,
  getDisplayStatus,
} from 'doc-mate-store/lib/constants/load';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ColumnInstance } from 'react-table';
import { useWebStores } from '../../hooks';
import {
  FilterValue,
  IFilterSelectMultiple,
  SingleFilterValue,
  StatusFilterValue,
} from '../../types/filtering';

interface IFilterSelectMultipleProps extends IFilterSelectMultiple {
  className?: string;
  disabled?: boolean;
  value: FilterValue;
  setValue(value: FilterValue): void;
  type: 'select' | 'selectMultiple';

  column?: ColumnInstance;
}

const StatusColorIndicator = styled('div')({
  display: 'inline-block',
  height: '12px',
  width: '12px',
  borderRadius: '1px',
  marginRight: '5px',

  '&.unassigned': {
    backgroundColor: '#F38686',
    color: 'white',
  },
  '&.pickup': {
    backgroundColor: '#FADFA3',
  },
  '&.dropoff': {
    backgroundColor: '#A6E7C1',
  },
  '&.completed': {
    backgroundColor: '#ACD5F0',
  },
  '&.cancelled': {
    backgroundColor: '#BDBDBD',
  },
});

const SelectFilter: React.FC<IFilterSelectMultipleProps> = ({
  accessor,
  disabled,
  label,
  className,
  values,
  value,
  setValue,
  type,
  column,
}) => {
  const { webStore } = useWebStores();
  const { columnFilters } = webStore;
  const filters = {
    ...columnFilters!.filters,
  };

  const defaultValue = columnFilters && column ? filters[column.id] : '';

  const labelId = column
    ? `column-filter-${column.id}-label`
    : `filter-${accessor}-label`;
  const id = column ? `column-filter-${column.id}-id` : `filter-${accessor}-id`;
  const multiple = type === 'selectMultiple';

  const options = useMemo(() => {
    if (column) {
      // Calculate the options for filtering
      // using the preFilteredRows
      const options = new Set<any>();
      column.preFilteredRows.forEach(row => {
        if (column.id === 'status__in') {
          options.add(row.values[column.id][0]);
        } else {
          options.add(row.values[column.id]);
        }
      });
      if (column.id === 'status__in') {
        let filters: Array<StatusFilterValue> = [];
        TRACKER_VISIBLE_STATUSES.forEach((value: any) => {
          if (options.has(value)) {
            filters.push({
              name: getDisplayStatus(value),
              value,
            });
          }
        });
        return filters;
      }
      return Array.from(options.values()).map(value => ({
        name: value,
        value,
      }));
    } else {
      return values;
    }
  }, [column && column.preFilteredRows, id, values]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let val: string[] | string = multiple ? [] : '';
  if (column) {
    if (multiple) {
      val = (column.filterValue || []).map((v: any) => v.toString());
    } else {
      val = column.filterValue.toString();
    }
  } else {
    if (multiple) {
      val = (value as SingleFilterValue[]).map((v: any) => v.toString());
    } else {
      val = (value || '').toString();
    }
  }

  useEffect(() => {
    if (column && defaultValue) {
      column.setFilter(defaultValue);
    }
  }, [defaultValue, column]);

  const renderValue = useCallback(
    (selected: unknown) => {
      if (multiple) {
        return (selected as string[])
          .map(id => {
            const option = options.find(
              item => item.value.toString() === id.toString(),
            )!;
            return option ? option.name : '';
          })
          .join(', ');
      }
      const v = options.find(
        item => item.value.toString() === (selected as string).toString(),
      );
      if (v) {
        return v.name;
      }
      return '';
    },
    [multiple, options],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newValue = event.target.value as string;
      if (column) {
        if (newValue === val) {
          column.setFilter('');
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: '',
          });
        } else {
          column.setFilter(newValue);
          columnFilters!.setFilters({
            ...columnFilters!.filters,
            [`${column.id}`]: newValue,
          });
        }
      } else {
        if (newValue === val) {
          setValue('');
        } else {
          setValue(newValue);
        }
      }
    },
    [column, setValue, val, columnFilters],
  );

  const handleChangeMultiple = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newValues = event.target.value as string[];
      if (column) {
        column.setFilter(newValues);
        columnFilters!.setFilters({
          ...columnFilters!.filters,
          [`${column.id}`]: newValues,
        });
      } else {
        setValue(newValues);
      }
    },
    [column, setValue, columnFilters],
  );

  return (
    <FormControl
      className={className}
      key={accessor}
      fullWidth
      size="small"
      variant="outlined"
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        disabled={disabled}
        multiple={multiple}
        value={val}
        renderValue={renderValue}
        onChange={multiple ? handleChangeMultiple : handleChange}
      >
        {options.map(item => (
          <MenuItem key={item.value} value={item.value as number}>
            <Checkbox
              checked={
                multiple
                  ? (val as SingleFilterValue[]).indexOf(item.value) > -1
                  : val === item.value
              }
            />
            {column && column.id === 'status__in' && (
              <StatusColorIndicator
                className={getChipDisplayClass(item.value)}
              />
            )}
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default observer(SelectFilter);
