import React from 'react';
import { isIOS } from 'react-device-detect';

const RedirectToStore: React.FC = () => {
  if (window) {
    if (isIOS) {
      window.location.replace(
        'https://apps.apple.com/us/app/doc-mate-app/id1490860416',
      );
    } else {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=com.docmate.app&hl=en&gl=US',
      );
    }
  }

  return <></>;
};

export default RedirectToStore;
