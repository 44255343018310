import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';
import { colors } from '../../themes';

type Props = {
  active?: boolean;
};

const TimelineItem: React.FC<Props> = ({ active }) => {
  const theme = useTheme();
  const fill = active ? colors.primary[900] : theme.palette.grey[500];
  const radius = 6.5315;
  const size = radius * 2;

  return (
    <SvgIcon
      viewBox={[0, 0, size, size].join(' ')}
      style={{
        width: '100%',
        height: 'inherit',
        paddingTop: theme.spacing(0.5),
      }}
    >
      <circle cx={radius} cy={radius} r={radius} fill={fill} />
    </SvgIcon>
  );
};

export default TimelineItem;
