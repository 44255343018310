import { isFunction } from 'lodash';
import { model, Model, modelAction, prop } from 'mobx-keystone';

export type Filters = { [key: string]: string | any | undefined };

@model('doc-mate-web/ColumnFilters')
export default class ColumnFilters extends Model({
  filters: prop<Filters>(() => ({})),
}) {
  @modelAction
  setFilters(filters: ((filters: Filters) => Filters) | Filters) {
    if (isFunction(filters)) {
      this.filters = filters(this.filters);
    } else {
      this.filters = filters;
    }
  }

  @modelAction
  reset() {
    this.filters = {};
  }
}
