"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CUT_OFF_DISTANCE = exports.LOCATION_TYPE = void 0;
// SEE: api/docmate/locations/constants.py
var LOCATION_TYPE;
(function (LOCATION_TYPE) {
    LOCATION_TYPE["TERMINAL"] = "terminal";
    LOCATION_TYPE["SITE"] = "site";
})(LOCATION_TYPE = exports.LOCATION_TYPE || (exports.LOCATION_TYPE = {}));
exports.CUT_OFF_DISTANCE = 1000; //in km
