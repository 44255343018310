import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AccessorialSection from './AccessorialSection';
import BillingForm from './BillingForm';
import BillingFormLeg from './BillingFormLeg';
import {
  AccessorialFormData,
  BillingFormData,
  ManifestFormData,
  OrderFormData,
  OrderLegFormData,
} from './types';
import {
  getDmidFromOrderLeg,
  getDriverFromLeg,
  getLoadDetailsFromLoad,
} from './utils';

type Props = {
  isLoading: boolean;
  manifestNumber: string;
  weightUnits: string[];
  loads: OrderFormData[];
  orderLegs: OrderLegFormData[];
  accessorials: AccessorialFormData[];
  manifest: ManifestFormData;
  billings: BillingFormData[];
  invoiceDetailOpen: boolean;
  onManifestChange: (name: string, value: any) => void;
  onBillingChange: (idW: string, name: string, value: any) => void;
  onAccessorialChange: (idW: string, name: string, value: any) => void;
  onAddAccessorial: () => void;
  onRemoveAccessorial: (idW: string) => void;
};

const InvoiceSection: React.FC<Props> = ({
  isLoading,
  manifestNumber,
  weightUnits,
  loads,
  orderLegs,
  accessorials,
  manifest,
  billings,
  invoiceDetailOpen,
  onManifestChange,
  onBillingChange,
  onAccessorialChange,
  onAddAccessorial,
  onRemoveAccessorial,
}) => {
  const classes = useStyles();
  const [billingDetailsOpen, setBillingDetailsOpen] = useState(
    invoiceDetailOpen,
  );
  const billToOptions = useMemo(() => manifest.billToOptions, [
    manifest.billToOptions,
  ]);
  const memoedLoads = useMemo(() => loads, [loads.length]); //eslint-disable-line react-hooks/exhaustive-deps
  const showPercentage = useMemo(() => billings.length > 1, [billings.length]);
  const getLegFromBilling = useCallback(
    (billing: BillingFormData) =>
      orderLegs.find(orderLeg => orderLeg.idW === billing.legW),
    [orderLegs],
  );

  useEffect(() => {
    setBillingDetailsOpen(invoiceDetailOpen);
  }, [invoiceDetailOpen]);

  const getLoadFromBilling = useCallback(
    (billing: BillingFormData) => {
      const leg = getLegFromBilling(billing);
      if (leg) {
        return loads.find(load => load.idW === leg.orderW);
      }
      return undefined;
    },
    [loads, getLegFromBilling],
  );

  const handleExpandBillingDetails = useCallback(() => {
    setBillingDetailsOpen(prevState => !prevState);
  }, []);

  const sortBilling = useCallback(
    (billingA: BillingFormData, billingB: BillingFormData) => {
      const loadA = getLoadFromBilling(billingA);
      const loadB = getLoadFromBilling(billingB);

      if (loadA && loadB) {
        if (loadA.orderNumber !== loadB.orderNumber) {
          return (
            parseInt(loadA.orderNumber!, 10) - parseInt(loadB.orderNumber!, 10)
          );
        }
      }

      const legA = getLegFromBilling(billingA);
      const legB = getLegFromBilling(billingB);

      if (legA && legB) {
        return legA.legNumber! - legB.legNumber!;
      }

      return 1;
    },
    [getLoadFromBilling, getLegFromBilling],
  );

  return (
    <div className={classes.invoiceContainer}>
      <div className={classes.billingContainer}>
        <BillingForm
          isLoading={isLoading}
          weightUnits={weightUnits}
          manifest={manifest}
          billingDetailsOpen={billingDetailsOpen}
          setBillingDetailsOpen={handleExpandBillingDetails}
          onManifestChange={onManifestChange}
        />

        {billingDetailsOpen &&
          billings
            .sort((a, b) => sortBilling(a, b))
            .map(billing => (
              <BillingFormLeg
                key={billing.idW}
                idW={billing.idW || ''}
                isLoading={isLoading}
                dmId={getDmidFromOrderLeg(
                  getLegFromBilling(billing),
                  orderLegs,
                  loads,
                  manifestNumber,
                )}
                loadDetails={getLoadDetailsFromLoad(
                  getLoadFromBilling(billing),
                  manifest.billOfLadingNumber || '',
                )}
                driver={getDriverFromLeg(getLegFromBilling(billing))}
                billing={billing}
                showPercentage={showPercentage}
                onBillingChange={onBillingChange}
              />
            ))}
      </div>

      <AccessorialSection
        isLoading={isLoading}
        billToOptions={billToOptions}
        manifestNumber={manifestNumber}
        accessorials={accessorials}
        loads={memoedLoads}
        invoiceDetailOpen={invoiceDetailOpen}
        orderLegs={orderLegs}
        onAccessorialChange={onAccessorialChange}
        onAddAccessorial={onAddAccessorial}
        onRemoveAccessorial={onRemoveAccessorial}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  invoiceContainer: {
    // border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
    width: '100%',
  },
  billingContainer: {
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
    margin: theme.spacing(1, 3),
  },
}));

export default React.memo(InvoiceSection);
