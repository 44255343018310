import {
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useLocation } from 'react-router';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import TrackerAppBar from '../../components/TrackerAppBar';
import { useWebStores } from '../../hooks';
import { colors } from '../../themes';
import Root from '../Root';

type Props = {};

const ResetPassword: React.FC<Props> = () => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const history = useHistory();
  const location = useLocation();
  const search = location.search.substring(1);
  const emailRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const query = qs.parse(search);

  useEffect(() => {
    when(
      () => !!rootStore.token,
      () => history.replace((query.next as string) || '/'),
    );
  }, [history, query.next, rootStore.token]);

  const handleGoToLogin = () => {
    history.push('/login');
  };

  const handleResetPasswordRequest = () => {
    return rootStore.resetPasswordRequest({ email });
  };

  async function handleSubmit() {
    setError('');
    setLoading(true);
    const { errors } = await handleResetPasswordRequest();

    setLoading(false);
    if (errors && (errors.detail || errors.email)) {
      if (errors.detail) {
        setError(errors.detail[0]);
      }
      if (errors.email) {
        setError(errors.email[0]);
      }
      if (emailRef.current) {
        emailRef.current.focus();
      }
    }
  }

  const handleResetPasswordConfirm = (token: string, password: string) => {
    return rootStore.resetPasswordConfirm({ token, password }, () =>
      webStore.load(),
    );
  };

  function handleChangeEmail(event: any) {
    setEmail(event.target.value);
    setError('');
  }

  return (
    <Root>
      <Helmet>
        <title>Reset Password | Doc-Mate</title>
      </Helmet>
      <TrackerAppBar subtitle="Tracker" />

      <div className={classes.container}>
        <Container maxWidth="sm" className={classes.formWrapper}>
          <Typography variant="h3" component="h2" className={classes.prompt}>
            Forgot Password?
          </Typography>
          <ValidatorForm onSubmit={handleSubmit}>
            <TextValidator
              inputRef={emailRef}
              id="email"
              name="email"
              autoFocus
              className={classes.input}
              fullWidth
              label="Email address"
              onChange={handleChangeEmail}
              validators={['required']}
              errorMessages={[
                'This field is required.',
                'Enter a valid email address.',
              ]}
              error={error}
              helperText={error || null}
              size="small"
              value={email}
              variant="outlined"
            />
            <Button
              className={classes.submit}
              color="primary"
              endIcon={
                loading ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  undefined
                )
              }
              fullWidth
              type="submit"
              variant="contained"
            >
              Request token
            </Button>
          </ValidatorForm>
          <ResetPasswordForm
            className={classes.trackingForm}
            onResetPasswordConfirm={handleResetPasswordConfirm}
            onResetSuccess={handleGoToLogin}
          />
          <Button onClick={handleGoToLogin}>Go back to Login</Button>
        </Container>
      </div>
    </Root>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(10),
    },
  },
  formWrapper: {
    flexGrow: 0,
    padding: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: 2,
      borderStyle: 'solid',
    },
  },
  track: {
    color: colors.primary[900],
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    marginBottom: theme.spacing(10),
  },
  trackingForm: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  prompt: {
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}));

export default observer(ResetPassword);
