import { InputAdornment, styled, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { useWebStores } from '../../hooks';
import { FilterValue, IFilterText } from '../../types/filtering';

interface IFilterTextProps extends IFilterText {
  className?: string;
  disabled?: boolean;
  value: FilterValue;
  setValue(value: FilterValue): void;
  column?: ColumnInstance;
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    paddingRight: 5,
    paddingLeft: 5,
    marginRight: 0,
    marginLeft: 0,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    minWidth: '70%',
  },
});

const TextFilterWrapper: React.FC<IFilterTextProps> = ({
  accessor,
  disabled,
  className,
  label,
  value,
  setValue,
  column,
}) => {
  const { webStore } = useWebStores();
  const { columnFilters } = webStore;
  const filters = {
    ...columnFilters!.filters,
  };

  const defaultValue =
    columnFilters && column && filters[column.id] ? filters[column.id] : null;
  const val = (column ? column.filterValue : value) || '';
  // const delayCommit = true;

  const id = column ? `column-filter-${column.id}-id` : `filter-${accessor}-id`;
  const [tempValue, setTempValue] = useState(val);

  useEffect(() => {
    if (defaultValue !== null && column) {
      column.setFilter(defaultValue);
      setTempValue(defaultValue);
    }
  }, [defaultValue, column]);

  useEffect(() => {
    setTempValue(val);
  }, [val]);

  const handleSetValue = useCallback(
    (value: string) => {
      if (column) {
        column.setFilter(value || undefined);
        console.log('DID WE SET FILTERS HERE?');
        columnFilters!.setFilters({
          ...columnFilters!.filters,
          [`${column.id}`]: value || undefined,
        });
      } else {
        setValue(value);
      }
    },
    [column, setValue, columnFilters],
  );
  // const debouncedSetValue = useCallback(debounce(handleSetValue, 100), []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTempValue(value);
    // if (delayCommit) {
    //   debouncedSetValue(value);
    // } else {
    //   handleSetValue(value);
    // }
  };

  const handleSubmit = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        handleSetValue(event.target.value);
      }
    },
    [handleSetValue],
  );

  return (
    <StyledTextField
      id={id}
      className={className}
      disabled={disabled}
      fullWidth
      label={label}
      name={accessor}
      size="small"
      value={tempValue}
      variant="outlined"
      onChange={handleChange}
      onKeyDown={e => handleSubmit(e)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default React.memo(observer(TextFilterWrapper));
