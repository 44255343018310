import { Divider, makeStyles } from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import LinkIcon from './LinkIcon.svg';

type Props = {
  legInfoList: number[];
};

const LinkedLegsInfo: React.FC<Props> = props => {
  const { legInfoList } = props;
  const classes = useStyles();
  const { rootStore } = useStores();

  return (
    <div className={classes.linkedLegInfo}>
      <div className="linked-container">
        <img src={LinkIcon} alt="Link" />
        <span className="linked-text"> Linked To</span>
      </div>
      {legInfoList.map(val => {
        const leg = rootStore.loadTableRows.get(`${val}`);
        if (leg) {
          return (
            <div className="name-container">
              DM-ID <span className="name-value">{leg.dmId}</span>
            </div>
          );
        }
        return '';
      })}
      <Divider />
      <div className="note-container">
        Note: Linked DM-IDs will be
        <br />
        assigned to the same driver
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  onTop: {},
  linkedLegInfo: {
    width: 250,
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 3,
    marginTop: 20,
    borderRadius: 5,

    '& .linked-container': {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      padding: '10px 30px',

      '& .linked-text': {
        fontSize: 16,
        color: '#4f4f4f',
      },
    },
    '& .name-container': {
      width: '100%',
      display: 'block',
      textAlign: 'center',
      fontSize: 16,
      padding: '10px 30px',
      color: '#4f4f4f',
      '& .name-value': {
        fontWeight: 700,
      },
    },
    '& .note-container': {
      maxWidth: '100%',
      display: 'flex',
      textAlign: 'center',
      fontSize: 14,
      paddingTop: '15px',
      color: '#155878',
      padding: '10px 30px',
      fontWeight: 500,
    },
  },
}));

export default observer(LinkedLegsInfo);
