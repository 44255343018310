import { makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { colors } from '../../themes';
import AutocompleteValidator from '../AutocompleteField/AutocompleteValidator';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import { ManifestFormData } from './types';

type Props = {
  isLoading: boolean;
  locations: AutocompleteFieldOption[];
  manifestNumber: string;
  manifest: ManifestFormData;
  onManifestChange: (name: string, value: any) => void;
};

const ManifestForm: React.FC<Props> = ({
  isLoading,
  locations,
  manifestNumber,
  manifest,
  onManifestChange,
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      onManifestChange(name, value);
    },
    [onManifestChange],
  );

  const handleTerminalChange = useCallback(
    (name: string, value: AutocompleteFieldOption) => {
      onManifestChange(name, value);
    },
    [onManifestChange],
  );

  return (
    <>
      <div className={classes.labelGroup}>
        <Typography className={classes.labelName} variant="body2">
          DM Manifest #
        </Typography>
        <Typography className={classes.labelValue} variant="body2">
          {manifestNumber}
        </Typography>
      </div>
      <TextValidator
        className={classes.manifestInput}
        disabled={isLoading}
        fullWidth
        label="AS400 Manifest #"
        name="as400ManifestNumber"
        onChange={handleChange}
        size="small"
        value={manifest.as400ManifestNumber}
        variant="outlined"
        required
      />
      <TextValidator
        className={classes.manifestInput}
        disabled={isLoading}
        fullWidth
        label="BOL #"
        name="billOfLadingNumber"
        onChange={handleChange}
        size="small"
        value={manifest.billOfLadingNumber}
        variant="outlined"
      />
      <AutocompleteValidator
        className={classes.manifestSelect}
        fullWidth
        isLoading={isLoading}
        label="Revenue Terminal"
        name="revenueTerminal"
        onChange={handleTerminalChange}
        options={locations}
        value={manifest.revenueTerminal}
        required
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  labelGroup: {
    width: '100%',
  },
  labelName: {
    color: colors.textPrimary[600],
    textTransform: 'uppercase',
  },
  labelValue: {
    color: 'black',
    fontSize: 16,
    fontWeight: 500,
  },
  manifestInput: {
    marginRight: theme.spacing(4),
  },
  manifestSelect: {},
}));

export default React.memo(ManifestForm);
