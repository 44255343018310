import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import LinkedLegsInfo from './LinkedLegsInfo';
import LinkIcon from './LinkIcon.svg';
type Props = {
  cell: {
    value: number[];
  };
};

const LinkedLegs: React.FC<Props> = ({ cell: { value } }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const hasLink = value.length > 0;
  const classes = useStyles();
  const handleMouseEnter = () => {
    setIsHovering(true);
    return;
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
    return;
  };
  return (
    <>
      <div className={classes.onTop}>
        {!!hasLink && (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img src={LinkIcon} alt="Link" />
            {!!isHovering && <LinkedLegsInfo legInfoList={value} />}
          </div>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  onTop: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    verticalAlign: 'top',
    alignItems: 'center',
    width: '100%',
    minWidth: 0,
    padding: 0,
    margin: 0,
    border: 0,
  },
}));

export default observer(LinkedLegs);
