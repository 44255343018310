"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.driverScheduleRef = exports.DriverSchedule = void 0;
var mobx_keystone_1 = require("mobx-keystone");
var driverSchedule_1 = require("../constants/driverSchedule");
var api = __importStar(require("../services/api"));
var models_1 = require("../utils/models");
var ScheduleRow_1 = require("./ScheduleRow");
var User_1 = require("./User");
var DriverSchedule = /** @class */ (function (_super) {
    __extends(DriverSchedule, _super);
    function DriverSchedule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRefId = function () { return "".concat(_this.id, "-").concat(_this.hasProposedSchedule); };
        _this.etaTracker = null;
        // get isPending(): boolean {
        //   return this.status === LOAD_STATUS.PENDING_ACCEPTANCE;
        // }
        // get isAssigned(): boolean {
        //   return this.status === LOAD_STATUS.PICKUP_ACCEPTED;
        // }
        // get isActive(): boolean {
        //   return ACTIVE_LOAD_STATUSES.includes(this.status);
        // }
        // get pickupWindow() {
        //   return this.pickup.isValid && this.pickup.current.pickupWindow
        //     ? this.pickup.current.pickupWindow
        //     : undefined;
        // }
        // get pickupLocation() {
        //   return this.pickup.isValid ? this.pickup.current.location : null;
        // }
        // get dropoffWindow() {
        //   return this.dropoff.isValid && this.dropoff.current.dropoffWindow
        //     ? this.dropoff.current.dropoffWindow
        //     : undefined;
        // }
        // get dropoffLocation() {
        //   return this.dropoff.isValid ? this.dropoff.current.location : null;
        // }
        // get shipper() {
        //   return this.pickup.isValid ? this.pickup.current.contact : null;
        // }
        // get receiver() {
        //   return this.dropoff.isValid ? this.dropoff.current.contact : null;
        // }
        // get dispatcher() {
        //   return this.order.isValid ? this.order.current.dispatcher : null;
        // }
        // isPickupLocation = (location: Location) =>
        //   this.pickupLocation &&
        //   this.pickupLocation.isValid &&
        //   this.pickupLocation.current === location;
        // _getDocument = (documentType: DOCUMENT_TYPE, documents: LoadDocument[]) =>
        //   documents.find(document => document.documentType === documentType);
        // getDocument = (documentType: DOCUMENT_TYPE) =>
        //   this._getDocument(documentType, Array.from(this.documents));
        // getDocumentForCarousel = (documentType: DOCUMENT_TYPE) => {
        //   const document = this.getDocument(documentType);
        //   if (document) {
        //     return document.photos
        //       .filter(photo => !!photo.photo)
        //       .map(photo => ({
        //         src: photo.photo.fullSize,
        //         thumbnail: photo.photo.thumbnail || '',
        //       }));
        //   }
        //   return [];
        // };
        // pickupOnTimeStatus(now?: Moment) {
        //   const time = now ? now : moment();
        //   let eta;
        //   let checkin;
        //   let windowStart;
        //   let windowEnd;
        //   switch (this.status) {
        //     case LOAD_STATUS.UNASSIGNED:
        //     case LOAD_STATUS.PENDING_ACCEPTANCE:
        //     case LOAD_STATUS.ASSIGNED:
        //     case LOAD_STATUS.DECLINED:
        //     case LOAD_STATUS.ACTIVE:
        //       return ON_TIME_STATUS.PROCESSING;
        //     case LOAD_STATUS.DELIVERED:
        //       return ON_TIME_STATUS.DELIVERED;
        //     case LOAD_STATUS.COMPLETED:
        //       return ON_TIME_STATUS.COMPLETED;
        //   }
        //   if (!this.etas || !this.etas.pickupEta || !this.pickupWindow) {
        //     return ON_TIME_STATUS.UNKNOWN;
        //   }
        //   switch (this.status) {
        //     case LOAD_STATUS.PICKUP_ACCEPTED:
        //     case LOAD_STATUS.PICKUP_GOING:
        //       eta = moment(this.etas.pickupEta.eta);
        //       windowStart = moment(this.pickupWindow.lower);
        //       windowEnd = moment(this.pickupWindow.upper);
        //       if (time > windowEnd) {
        //         return ON_TIME_STATUS.PICKUP_MISSED;
        //       } else if (eta < windowStart) {
        //         return ON_TIME_STATUS.PICKUP_EARLY;
        //       } else if (eta > windowEnd) {
        //         return ON_TIME_STATUS.PICKUP_AT_RISK;
        //       }
        //       return ON_TIME_STATUS.PICKUP_ON_TIME;
        //     case LOAD_STATUS.PICKUP_ARRIVED:
        //       return ON_TIME_STATUS.PICKUP_ARRIVED;
        //     case LOAD_STATUS.PICKUP_CHECKIN:
        //       checkin = moment(this.pickupCheckedinOn!);
        //       if (time.diff(checkin, 'minutes') > 120) {
        //         return ON_TIME_STATUS.PICKUP_DETENTION;
        //       }
        //       return ON_TIME_STATUS.PICKUP_CHECKEDIN;
        //     default:
        //       return ON_TIME_STATUS.PICKED_UP;
        //   }
        // }
        // dropoffOnTimeStatus(now?: Moment) {
        //   const time = now ? now : moment();
        //   let eta;
        //   let checkin;
        //   let windowStart;
        //   let windowEnd;
        //   switch (this.status) {
        //     case LOAD_STATUS.UNASSIGNED:
        //     case LOAD_STATUS.PENDING_ACCEPTANCE:
        //     case LOAD_STATUS.ASSIGNED:
        //     case LOAD_STATUS.DECLINED:
        //     case LOAD_STATUS.ACTIVE:
        //       return ON_TIME_STATUS.PROCESSING;
        //     case LOAD_STATUS.DELIVERED:
        //       return ON_TIME_STATUS.DELIVERED;
        //     case LOAD_STATUS.COMPLETED:
        //       return ON_TIME_STATUS.COMPLETED;
        //   }
        //   if (!this.etas || !this.etas.dropoffEta || !this.dropoffWindow) {
        //     return ON_TIME_STATUS.UNKNOWN;
        //   }
        //   switch (this.status) {
        //     case LOAD_STATUS.PICKUP_GOING:
        //     case LOAD_STATUS.PICKUP_ARRIVED:
        //     case LOAD_STATUS.PICKUP_CHECKIN:
        //     case LOAD_STATUS.DROPOFF_ACCEPTED:
        //     case LOAD_STATUS.DROPOFF_GOING:
        //       eta = moment(this.etas.dropoffEta.eta);
        //       windowStart = moment(this.dropoffWindow.lower);
        //       windowEnd = moment(this.dropoffWindow.upper);
        //       if (time > windowEnd) {
        //         return ON_TIME_STATUS.DROPOFF_MISSED;
        //       } else if (eta < windowStart) {
        //         return ON_TIME_STATUS.DROPOFF_EARLY;
        //       } else if (eta > windowEnd) {
        //         return ON_TIME_STATUS.DROPOFF_AT_RISK;
        //       }
        //       return ON_TIME_STATUS.DROPOFF_ON_TIME;
        //     case LOAD_STATUS.DROPOFF_ARRIVED:
        //       return ON_TIME_STATUS.DROPOFF_ARRIVED;
        //     case LOAD_STATUS.DROPOFF_CHECKIN:
        //       checkin = moment(this.dropoffCheckedinOn!);
        //       if (time.diff(checkin, 'minutes') > 120) {
        //         return ON_TIME_STATUS.DROPOFF_DETENTION;
        //       }
        //       return ON_TIME_STATUS.DROPOFF_CHECKEDIN;
        //     default:
        //       return ON_TIME_STATUS.COMPLETED;
        //   }
        // }
        // @computed
        // get onTimeStatus() {
        //   const now = moment();
        //   switch (this.status) {
        //     case LOAD_STATUS.UNASSIGNED:
        //     case LOAD_STATUS.PENDING_ACCEPTANCE:
        //     case LOAD_STATUS.DECLINED:
        //       return ON_TIME_STATUS.PROCESSING;
        //     case LOAD_STATUS.ASSIGNED:
        //     case LOAD_STATUS.ACTIVE:
        //       return ON_TIME_STATUS.LOAD_STARTED;
        //     case LOAD_STATUS.DELIVERED:
        //       return ON_TIME_STATUS.DELIVERED;
        //     case LOAD_STATUS.COMPLETED:
        //       return ON_TIME_STATUS.COMPLETED;
        //   }
        //   if (!this.etas || !this.etas.pickupEta || !this.etas.dropoffEta) {
        //     return ON_TIME_STATUS.UNKNOWN;
        //   }
        //   switch (this.status) {
        //     case LOAD_STATUS.PICKUP_ACCEPTED:
        //     case LOAD_STATUS.PICKUP_GOING:
        //     case LOAD_STATUS.PICKUP_ARRIVED:
        //     case LOAD_STATUS.PICKUP_CHECKIN:
        //       return this.pickupOnTimeStatus(now);
        //     case LOAD_STATUS.DROPOFF_ACCEPTED:
        //     case LOAD_STATUS.DROPOFF_GOING:
        //     case LOAD_STATUS.DROPOFF_ARRIVED:
        //     case LOAD_STATUS.DROPOFF_CHECKIN:
        //       return this.dropoffOnTimeStatus(now);
        //   }
        //   return ON_TIME_STATUS.UNKNOWN;
        // }
        // @asDate('loadStartedOn')
        // loadStartedOnDate?: Date;
        // @asDate('pickupStartedOn')
        // pickupStartedOnDate?: Date;
        // @asDate('pickupArrivedOn')
        // pickupArrivedOnDate?: Date;
        // @asDate('pickupCheckedinOn')
        // pickupCheckedinOnDate?: Date;
        // @asDate('pickupDepartedOn')
        // pickupDepartedOnDate?: Date;
        // @asDate('dropoffArrivedOn')
        // dropoffArrivedOnDate?: Date;
        // @asDate('dropoffCheckedinOn')
        // dropoffCheckedinOnDate?: Date;
        // @asDate('dropoffCompletedOn')
        // dropoffCompletedOnDate?: Date;
        //TODO: Send truck details from API
        // get truck() {
        //   if (this.isPickupStatus && this.pickupLeg && this.pickupLeg.isValid) {
        //     return this.pickupLeg.current.truck;
        //   } else if (
        //     this.isDropoffStatus &&
        //     this.dropoffLeg &&
        //     this.dropoffLeg.isValid
        //   ) {
        //     return this.dropoffLeg.current.truck;
        //   } else if (this.pickupLeg && this.pickupLeg.isValid) {
        //     return this.pickupLeg.current.truck;
        //   }
        //   return null;
        // }
        // get organization() {
        //   return this.order.isValid ? this.order.current.organization : null;
        // }
        // get currentETA() {
        //   if (!this.etas) {
        //     return null;
        //   }
        //   return this.isPickupStatus ? this.etas.pickupEta : this.etas.dropoffEta;
        // }
        // @modelAction
        // setPickupHasNoDamages(value: boolean) {
        //   this.pickupHasNoDamages = value;
        // }
        // @modelAction
        // setDropoffHasNoDamages(value: boolean) {
        //   this.dropoffHasNoDamages = value;
        // }
        _this.updateStatus = (0, mobx_keystone_1._async)(function (nextStatus) {
            var rootStore, error_, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rootStore = (0, mobx_keystone_1.getRootStore)(this);
                        if (!rootStore || !rootStore.token)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        data = {
                            row: this.currentRowRef && this.currentRowRef.current.id,
                            status: nextStatus,
                        };
                        console.log('[DEBUG] UPDATE SCHEDULE ROW STATUS data', data);
                        return [4 /*yield*/, api.updateScheduleRowStatus(rootStore.token, this.id, data)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log('[DEBUG] error', error_1.message);
                        error_ = (0, models_1.getError)(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, error_ || (0, models_1.getSuccess)()];
                }
            });
        });
        return _this;
        // @modelFlow
        // updateETAs = _async(function*(this: DriverSchedule, force?: boolean) {
        //   const rootStore = getRootStore<RootStore>(this);
        //   if (!rootStore) return;
        //   const { currentGPSPosition } = rootStore;
        //   if (force || currentGPSPosition) {
        //     let fn;
        //     if (rootStore.token) {
        //       fn = api.fetchLoadETA(
        //         rootStore.token!,
        //         this.id,
        //         rootStore.currentGPSPosition,
        //       );
        //       // } else {
        //       //   fn = api.fetchLoadETAByManifestLinkId(
        //       //     rootStore.token!,
        //       //     this.driverScheduleLinkId,
        //       //     rootStore.currentGPSPosition,
        //       //   );
        //     }
        //     // let entities: ETASet;
        //     // try {
        //     //   ({
        //     //     response: { entities },
        //     //   } = yield fn);
        //     // } catch (error) {
        //     //   console.log('[DEBUG][Load.updateETAs] error', error);
        //     //   return;
        //     // }
        //     // // if (entities) {
        //     // //   this.etas = entities;
        //     // // }
        //   }
        //   if (currentGPSPosition) {
        //     console.log('[DEBUG]', 'Checking if nearby or can depart');
        //     yield this.checkNearbyDeparted();
        //   }
        // });
        //   @modelFlow
        //   checkNearbyDeparted = _async(function*(this: DriverSchedule) {
        //     const rootStore = getRootStore<RootStore>(this);
        //     if (!rootStore) return;
        //     if (!this.etas || !this.etas.pickupEta || !this.etas.dropoffEta) return;
        //     if (!rootStore.currentCoordinatesJson) return;
        //     const { currentCoordinatesJson } = rootStore;
        //     let distance = this.etas.pickupEta.distance;
        //     let duration = moment.duration(this.etas.pickupEta.duration);
        //     let arriveTransition = { fn: LOAD_TRANSITION.PICKUP_ARRIVE };
        //     let nearbyTransition = {
        //       fn: LOAD_TRANSITION.PICKUP_NEARBY,
        //       params: { currentPosition: currentCoordinatesJson },
        //     };
        //     let notified = this.pickupNearbyNotified;
        //     if (this.status === LOAD_STATUS.DROPOFF_GOING) {
        //       distance = this.etas.dropoffEta.distance;
        //       duration = moment.duration(this.etas.dropoffEta.duration);
        //       arriveTransition = { fn: LOAD_TRANSITION.DROPOFF_ARRIVE };
        //       nearbyTransition = {
        //         fn: LOAD_TRANSITION.DROPOFF_NEARBY,
        //         params: { currentPosition: currentCoordinatesJson },
        //       };
        //       notified = this.dropoffNearbyNotified;
        //     }
        //     console.log('[DEBUG][Load.checkNearbyDeparted]', {
        //       LOAD_NEARBY_THRESHOLD,
        //       LOAD_ARRIVED_THRESHOLD,
        //       LOAD_DEPARTED_THRESHOLD,
        //       load: this.id,
        //       status: this.status,
        //       distance,
        //       duration,
        //       arriveTransition,
        //       nearbyTransition,
        //       notified,
        //     });
        //     if (
        //       this.status === LOAD_STATUS.PICKUP_GOING ||
        //       this.status === LOAD_STATUS.DROPOFF_GOING
        //     ) {
        //       if (duration <= LOAD_ARRIVED_THRESHOLD) {
        //         yield this.updateStatus(arriveTransition);
        //         return;
        //       }
        //       if (duration <= LOAD_NEARBY_THRESHOLD && !notified) {
        //         yield this.updateStatus(nearbyTransition);
        //         return;
        //       }
        //     }
        //   });
    }
    DriverSchedule_1 = DriverSchedule;
    // onAttachedToRootStore() {
    //   if (this.isActive) {
    //     this.updateETAs(true);
    //     this.etaTracker = setInterval(() => {
    //       if (!this.isActive && this.etaTracker) {
    //         clearInterval(this.etaTracker as number);
    //       }
    //       this.updateETAs();
    //     }, ETA_INTERVAL + Math.random() * 1000);
    //   } else {
    //     console.log('[DEBUG] Not getting ETA of', this.status, 'load', this.id);
    //   }
    //   return () => {
    //     if (this.etaTracker) {
    //       clearInterval(this.etaTracker as number);
    //     }
    //   };
    // }
    DriverSchedule.prototype.update = function (data, forceUpdate) {
        var rootStore = (0, mobx_keystone_1.getRootStore)(this);
        if (!rootStore) {
            return;
        }
        var result = DriverSchedule_1._formatData(rootStore, data);
        if (result) {
            // const { driverSchedule } = result; //, documents
            Object.assign(this, result);
            // if (documents) {
            //   const oldDocuments = Array.from(this.documents);
            //   documents.forEach(document => {
            //     const oldDocument = oldDocuments.find(d => d.id === document.id);
            //     if (oldDocument) {
            //       Object.assign(oldDocument, document);
            //     } else {
            //       this.documents.add(new LoadDocument(document));
            //     }
            //   });
            // }
        }
    };
    Object.defineProperty(DriverSchedule.prototype, "transitions", {
        get: function () {
            if (this.rows.items.length === 0) {
                return null;
            }
            // const hasNextStop =
            //   this.rows.items.filter(row => row.current.status === ROW_STATUS.INACTIVE)
            //     .length > 0;
            return this.currentRowRef && !!this.currentRowRef.isValid
                ? (0, driverSchedule_1.getTransitions)(this.currentRowRef.current.status, this.hasNextStop)
                : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DriverSchedule.prototype, "hasNextStop", {
        get: function () {
            return (this.rows.items.filter(function (row) { return !!row.isValid && row.maybeCurrent.status === driverSchedule_1.ROW_STATUS.INACTIVE; }).length > 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DriverSchedule.prototype, "displayStatus", {
        get: function () {
            return this.currentRowRef && !!this.currentRowRef.isValid
                ? (0, driverSchedule_1.getDisplayStatus)(this.currentRowRef.current.status)
                : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DriverSchedule.prototype, "currentRowRef", {
        get: function () {
            var rows = Array.from(this.rows.items).sort(function (a, b) {
                return ((a && a.isValid ? a.current.rowNumber : 0) -
                    (b && b.isValid ? b.current.rowNumber : 0));
            });
            if (rows.length === 0) {
                return null;
            }
            var nextActiveRow = rows[0];
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].current.status === driverSchedule_1.ROW_STATUS.ACTIVE ||
                    rows[i].current.status === driverSchedule_1.ROW_STATUS.ARRIVED) {
                    return rows[i];
                }
                else if (rows[i].current.status === driverSchedule_1.ROW_STATUS.INACTIVE) {
                    nextActiveRow = rows[i];
                    break;
                }
            }
            if (nextActiveRow.current.status === driverSchedule_1.ROW_STATUS.INACTIVE) {
                return nextActiveRow;
            }
            return rows[rows.length - 1];
        },
        enumerable: false,
        configurable: true
    });
    var DriverSchedule_1;
    DriverSchedule._formatData = function (rootStore, data) {
        var driverSchedule = data;
        try {
            driverSchedule.hasProposedSchedule = data.hasProposedSchedule;
            if (data.driver) {
                if (!rootStore.users.has("".concat(data.driver))) {
                    rootStore.fetchUser(data.driver);
                }
                driverSchedule.driver = (0, User_1.userRef)("".concat(data.driver));
            }
            else {
                driverSchedule.driver = null;
            }
            if (data.rows) {
                var rows = data.rows
                    .map(function (stop) {
                    if (data.hasProposedSchedule) {
                        if (rootStore.proposedDriverScheduleRows.has("".concat(stop.id))) {
                            var row = rootStore.proposedDriverScheduleRows.get("".concat(stop.id));
                            if (row) {
                                row.update(stop, data.hasProposedSchedule);
                                return (0, ScheduleRow_1.scheduleRowRef)("".concat(row.id, "-").concat(row.isProposed));
                            }
                        }
                        else {
                            var newStop = ScheduleRow_1.ScheduleRow.create(rootStore, stop, data.hasProposedSchedule);
                            if (newStop) {
                                rootStore.proposedDriverScheduleRows.set("".concat(newStop.id), newStop);
                                return (0, ScheduleRow_1.scheduleRowRef)("".concat(newStop.id, "-").concat(newStop.isProposed));
                            }
                        }
                        return null;
                    }
                    else {
                        if (rootStore.driverScheduleRows.has("".concat(stop.id))) {
                            var row = rootStore.driverScheduleRows.get("".concat(stop.id));
                            if (row) {
                                row.update(stop, data.hasProposedSchedule);
                                return (0, ScheduleRow_1.scheduleRowRef)("".concat(row.id, "-").concat(row.isProposed));
                            }
                        }
                        else {
                            var newStop = ScheduleRow_1.ScheduleRow.create(rootStore, stop, data.hasProposedSchedule);
                            if (newStop) {
                                rootStore.driverScheduleRows.set("".concat(newStop.id), newStop);
                                return (0, ScheduleRow_1.scheduleRowRef)("".concat(newStop.id, "-").concat(newStop.isProposed));
                            }
                        }
                        return null;
                    }
                })
                    .filter(function (stop) { return !!stop; });
                driverSchedule.rows = new mobx_keystone_1.ArraySet({ items: rows });
            }
            return driverSchedule;
        }
        catch (error) {
            console.warn('[WARN] error', error);
            return null;
        }
    };
    DriverSchedule.create = function (rootStore, data) {
        var result = DriverSchedule_1._formatData(rootStore, data);
        if (result) {
            var newDriverSchedule = new DriverSchedule_1(result);
            return newDriverSchedule;
        }
        else {
            return null;
        }
    };
    __decorate([
        mobx_keystone_1.modelAction
    ], DriverSchedule.prototype, "update", null);
    __decorate([
        mobx_keystone_1.modelFlow
    ], DriverSchedule.prototype, "updateStatus", void 0);
    DriverSchedule = DriverSchedule_1 = __decorate([
        (0, mobx_keystone_1.model)('doc-mate/DriverSchedule')
    ], DriverSchedule);
    return DriverSchedule;
}((0, mobx_keystone_1.Model)({
    id: (0, mobx_keystone_1.prop)(),
    driver: (0, mobx_keystone_1.prop)(),
    rows: (0, mobx_keystone_1.prop)(function () { return (0, mobx_keystone_1.arraySet)(); }),
    hasProposedSchedule: (0, mobx_keystone_1.prop)(),
    forceUpdate: (0, mobx_keystone_1.prop)(false),
})));
exports.DriverSchedule = DriverSchedule;
exports.driverScheduleRef = (0, mobx_keystone_1.rootRef)('doc-mate/DriverScheduleRef', {
    getId: function (target) {
        if (target instanceof DriverSchedule) {
            return "".concat(target.id, "-").concat(target.hasProposedSchedule);
        }
    },
});
