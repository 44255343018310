import { createTheme, Theme } from '@material-ui/core';
import { brown } from '@material-ui/core/colors';
import {
  ON_ASSIGNMENT_STATUS,
  ON_TIME_STATUS,
} from 'doc-mate-store/lib/constants/load';

export const colors = {
  primary: {
    1100: '#08212d',
    1000: '#0b2c3c',
    900: '#0d374b',
    800: '#10425a',
    700: '#124d69',
    600: '#155878',
    500: '#2d6a89',
    400: '#5285a3',
    300: '#6a97b4',
    200: '#9fbdd0',
    100: '#c8d9e4',
  },
  textPrimary: {
    900: '#0D374B',
    600: '#155878',
    400: '#4D85A7',
  },
  secondary: {
    1000: '#4c3705',
    900: '#735207',
    800: '#996d09',
    700: '#bf880c',
    600: '#e5a40e',
    500: '#f2b52a',
    400: '#f4c14d',
    300: '#f6ce71',
    200: '#f9da95',
    100: '#fbe6b8',
  },
  success: {
    1100: '#061d10',
    1000: '#03da20',
    900: '#145730',
    800: '#1a7440',
    700: '#219150',
    600: '#27ae60',
    500: '#35d277',
    400: '#5ddb92',
    300: '#86e4ad',
    200: '#aeedc9',
    100: '#d7f6e4',
  },
  danger: {
    1000: '#520a0a',
    900: '#7b0f0f',
    800: '#a41414',
    700: '#ce1818',
    600: '#e62e2e',
    500: '#eb5757',
    400: '#ee7373',
    300: '#f28f8f',
    200: '#f5abab',
    100: '#f8c7c7',
  },
  early: {
    1100: '#2a0c45',
    1000: '#3e1268',
    900: '#53198a',
    800: '#681fad',
    700: '#7d25cf',
    600: '#9140dd',
    500: '#9b51e0',
    400: '#a25de2',
    300: '#af74e6',
    200: '#bc8bea',
    100: '#caa2ee',
  },
  unassigned: {
    1000: '#093269',
    900: '#0b3a7a',
    800: '#0e4b9d',
    700: '#115bc0',
    600: '#146ce3',
    500: '#2f80ed',
    400: '#4b91ef',
    300: '#5999f1',
    200: '#74aaf3',
    100: '#90bbf5',
  },
  risk: {
    1100: '#623207',
    1000: '#0d3a20',
    900: '#8a460a',
    800: '#d86e10',
    700: '#ec7811',
    600: '#f18e36',
    500: '#f2994a',
    400: '#f4a762',
    300: '#f5b47a',
    200: '#f7c292',
    100: '#fad6b7',
  },
};

export const onTimeStatusColors = (theme: Theme) => ({
  [ON_TIME_STATUS.UNKNOWN]: colors.primary[800],
  [ON_TIME_STATUS.PROCESSING]: theme.palette.grey[500],
  [ON_TIME_STATUS.LOAD_STARTED]: colors.primary[800],
  [ON_TIME_STATUS.PICKUP_EARLY]: colors.early[600],
  [ON_TIME_STATUS.PICKUP_ON_TIME]: colors.success[600],
  [ON_TIME_STATUS.PICKUP_AT_RISK]: colors.risk[600],
  [ON_TIME_STATUS.PICKUP_ARRIVED]: colors.primary[800],
  [ON_TIME_STATUS.PICKUP_MISSED]: theme.palette.error.main,
  [ON_TIME_STATUS.PICKUP_CHECKEDIN]: colors.primary[800],
  [ON_TIME_STATUS.PICKUP_DETENTION]: brown[500],
  [ON_TIME_STATUS.DROPOFF_EARLY]: colors.early[600],
  [ON_TIME_STATUS.DROPOFF_ON_TIME]: colors.success[600],
  [ON_TIME_STATUS.DROPOFF_AT_RISK]: colors.risk[600],
  [ON_TIME_STATUS.DROPOFF_ARRIVED]: colors.primary[800],
  [ON_TIME_STATUS.DROPOFF_MISSED]: theme.palette.error.main,
  [ON_TIME_STATUS.DROPOFF_CHECKEDIN]: colors.primary[800],
  [ON_TIME_STATUS.DROPOFF_DETENTION]: brown[500],
  [ON_TIME_STATUS.PICKED_UP]: colors.primary[800],
  [ON_TIME_STATUS.DELIVERED]: colors.primary[800],
  [ON_TIME_STATUS.COMPLETED]: colors.primary[800],
  [ON_ASSIGNMENT_STATUS.UNKNOWN]: colors.primary[800],
  [ON_ASSIGNMENT_STATUS.ASSIGNMENT_UNASSIGNED]: theme.palette.error.main,
  [ON_ASSIGNMENT_STATUS.ASSIGNMENT_AT_RISK]: colors.risk[600],
  [ON_ASSIGNMENT_STATUS.ASSIGNMENT_ASSIGNED]: colors.primary[800],
});

export const onTimeChipStatusColors = (theme: Theme) => ({
  ...onTimeStatusColors(theme),
  [ON_TIME_STATUS.PICKUP_EARLY]: colors.primary[800],
  [ON_TIME_STATUS.PICKUP_ON_TIME]: colors.success[600],
  [ON_TIME_STATUS.PICKUP_MISSED]: theme.palette.error.main,
  [ON_TIME_STATUS.DROPOFF_EARLY]: colors.primary[800],
  [ON_TIME_STATUS.DROPOFF_ON_TIME]: colors.success[600],
  [ON_TIME_STATUS.DROPOFF_MISSED]: theme.palette.error.main,
});

const theme = createTheme({
  overrides: {
    MuiBadge: {
      colorSecondary: {
        color: 'white',
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiInputLabel: {
      formControl: {
        fontSize: 12,
        textTransform: 'uppercase',
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary[500],
    },
    secondary: {
      main: '#e5a40e',
    },
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: '2.25rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1.0625rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '0.85rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
    button: {
      fontSize: '0.75rem',
    },
  },
});

export default theme;
