import React, { useEffect, useRef } from 'react';

type Props = {
  latitude: number;
  longitude: number;
  label: string;
  map?: any;
  maps?: any;
  title?: string;
};

const GenericMarker: React.FC<Props> = ({
  latitude,
  longitude,
  label,
  map,
  maps,
  title,
}) => {
  const markerRef = useRef<any>(null);

  useEffect(() => {
    if (map && maps) {
      const infowindow = new maps.InfoWindow({
        content: title,
      });
      markerRef.current = new maps.Marker({
        clickable: true,
        position: {
          lat: latitude,
          lng: longitude,
        },
        label,
        map,
        title,
      });
      markerRef.current.addListener('click', () => {
        infowindow.open(map, markerRef.current);
      });
    }
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
      }
    };
  }, [label, latitude, longitude, map, maps, title]);

  return <div />;
};

export default GenericMarker;
