import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';

type Props = {
  className?: string;
  freeSolo?: boolean;
  fullWidth?: boolean;
  isLoading: boolean;
  isClearable?: boolean;
  label?: string;
  name: string;
  onChange: (name: string, value: string) => void;
  options: string[];
  required?: boolean;
  value: string;
};

// Supporting freeSolo option of Autocomplete with an array of objects as options
// is complicated because the new value is only passed as a string instead of the objects
// this causes issues with the typing

const SimpleAutocompleteValidator: React.FC<Props> = ({
  className,
  freeSolo = false,
  fullWidth = false,
  isLoading,
  isClearable = false,
  label,
  name,
  onChange,
  options,
  required = false,
  value,
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    if (value || isClearable) {
      onChange(name, value || '');
    }
  };

  const validators = required ? ['required'] : [];

  const handleRenderInput = (params: any) => (
    <TextValidator
      {...params}
      errorMessages={['This field is required.']}
      label={label}
      name={name}
      size="small"
      validators={validators}
      value={value}
      variant="outlined"
      required={required}
    />
  );

  return (
    <Autocomplete
      autoComplete
      autoSelect
      disableClearable={!isClearable}
      className={className}
      disabled={isLoading}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      onChange={handleChange}
      options={options}
      renderInput={handleRenderInput}
      value={value || null}
    />
  );
};

export default SimpleAutocompleteValidator;
