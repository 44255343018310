import { makeStyles, Popover, Tooltip } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { useStores } from 'doc-mate-store/lib/hooks';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  cell: {
    value: [string, string, number, boolean, boolean];
  };
};

const Link: React.FC<Props> = props => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const { cell } = props;
  const {
    value: [text, _, manifestId, isDispatcher, isDocmate], // eslint-disable-line  @typescript-eslint/no-unused-vars
  } = cell;
  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);

  const handleEdit = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      rootStore.setUpdateManifestId(manifestId);
    },
    [rootStore, manifestId],
  );

  const handleCopy = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      rootStore.setCopyManifestId(manifestId);
    },
    [rootStore, manifestId],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigator.clipboard.writeText(text);
      setAnchorEl(event.currentTarget);
      setCopy(true);
    },
    [text],
  );

  return (
    <>
      <div className={classes.root}>
        <span className={classes.link} onClick={handleClick}>
          {text}
        </span>
        {isDispatcher && (
          <span className={classes.iconContainer}>
            {isDocmate && (
              <span className={classes.icon} onClick={handleEdit}>
                <Tooltip title="Edit Manifest">
                  <EditIcon
                    aria-label="Edit Manifest"
                    color="primary"
                    fontSize="inherit"
                  />
                </Tooltip>
              </span>
            )}
            <span className={classes.icon} onClick={handleCopy}>
              <Tooltip title="Copy Manifest">
                <FileCopyOutlined
                  aria-label="Copy Manifest"
                  color="primary"
                  fontSize="inherit"
                />
              </Tooltip>
            </span>
          </span>
        )}
      </div>
      <Popover
        id={text}
        open={copy}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popUpText}>Copied</div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    overflow: 'hidden',
    display: 'flex',
  },
  link: {
    overflow: 'hidden',
    paddingRight: theme.spacing(0.25),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconContainer: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default React.memo(Link);
