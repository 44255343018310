"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACCESSORIAL_DESCRIPTION_CHOICES = exports.ACCESSORIAL_DESCRIPTION = void 0;
var ACCESSORIAL_DESCRIPTION;
(function (ACCESSORIAL_DESCRIPTION) {
    ACCESSORIAL_DESCRIPTION["BIF"] = "BIF - FUEL SURCHARGE";
    ACCESSORIAL_DESCRIPTION["BIO"] = "BIO - OUT OF ROUTE MILES";
    ACCESSORIAL_DESCRIPTION["BIS"] = "BIS - STOP OFF CHARGE";
    ACCESSORIAL_DESCRIPTION["BOF"] = "BOF - FUEL SURCHARGE";
    ACCESSORIAL_DESCRIPTION["BOO"] = "BOO - OUT OF ROUTE MILES";
    ACCESSORIAL_DESCRIPTION["BRG"] = "BRG - BRIDGE FEE";
    ACCESSORIAL_DESCRIPTION["COD"] = "COD - CASH ON DELIVERY";
    ACCESSORIAL_DESCRIPTION["CSX"] = "CSX - CONTAINER RETURN";
    ACCESSORIAL_DESCRIPTION["DET"] = "DET - DETENTION";
    ACCESSORIAL_DESCRIPTION["ECT"] = "ECT - EAST CHICAGO TARPING/SPOTTING";
    ACCESSORIAL_DESCRIPTION["ECW"] = "ECW - EAST CHICAGO PLASTIC WRAP";
    ACCESSORIAL_DESCRIPTION["ESC"] = "ESC - ESCORT SERVICE";
    ACCESSORIAL_DESCRIPTION["FLU"] = "FLU - FORK LIFT UNLOAD";
    ACCESSORIAL_DESCRIPTION["FUE"] = "FUE - FUEL SURCHARGE";
    ACCESSORIAL_DESCRIPTION["GRD"] = "GRD - GYPSUM RETURN DUNNAGE";
    ACCESSORIAL_DESCRIPTION["GYR"] = "GYR - GYPSUM RETURN MATERIAL";
    ACCESSORIAL_DESCRIPTION["HAZ"] = "HAZ - HAZMAT CHARGE";
    ACCESSORIAL_DESCRIPTION["HND"] = "HND - HAND UNLOADING";
    ACCESSORIAL_DESCRIPTION["HTR"] = "HTR - HEATER SERVICE";
    ACCESSORIAL_DESCRIPTION["INS"] = "INS - INSURANCE SURCHARGE";
    ACCESSORIAL_DESCRIPTION["JOB"] = "JOB - JOB SITE DELIVERY";
    ACCESSORIAL_DESCRIPTION["LAY"] = "LAY - LAYOVER CHARGE";
    ACCESSORIAL_DESCRIPTION["LIC"] = "LIC - SPECIAL LICENSE";
    ACCESSORIAL_DESCRIPTION["LMP"] = "LMP - HAND UNLOAD TRAILER";
    ACCESSORIAL_DESCRIPTION["LOD"] = "LOD - LOADING CHARGE";
    ACCESSORIAL_DESCRIPTION["MIN"] = "MIN - MINIMUM CHARGE";
    ACCESSORIAL_DESCRIPTION["MIS"] = "MIS - MISCELLANEOUS CHARGE";
    ACCESSORIAL_DESCRIPTION["NOL"] = "NOL - NO LOAD";
    ACCESSORIAL_DESCRIPTION["OND"] = "OND - OVER NIGHT DELIVERY";
    ACCESSORIAL_DESCRIPTION["OUT"] = "OUT - OUT OF ROUTE MILES";
    ACCESSORIAL_DESCRIPTION["OVW"] = "OVW - OVERWIDTH CHARGE";
    ACCESSORIAL_DESCRIPTION["PAL"] = "PAL - EMPTY PALLETS";
    ACCESSORIAL_DESCRIPTION["PEC"] = "PEC - PLASTIC EAST CHICAGO";
    ACCESSORIAL_DESCRIPTION["PRE"] = "PRE - PREMIUM CHARGE";
    ACCESSORIAL_DESCRIPTION["PRR"] = "PRR - PLASTIC RIVER ROUGE";
    ACCESSORIAL_DESCRIPTION["PRT"] = "PRT - PERMIT";
    ACCESSORIAL_DESCRIPTION["PTR"] = "PTR - PARTIAL TARP";
    ACCESSORIAL_DESCRIPTION["REB"] = "REB - REBAR";
    ACCESSORIAL_DESCRIPTION["REC"] = "REC - RECONSIGNMENT CHARGE";
    ACCESSORIAL_DESCRIPTION["RED"] = "RED - REDELIVERY CHARGE";
    ACCESSORIAL_DESCRIPTION["RET"] = "RET - RETURN CHARGE";
    ACCESSORIAL_DESCRIPTION["RRT"] = "RRT - RIVER ROUGE TARPING & SPOTTING";
    ACCESSORIAL_DESCRIPTION["RRW"] = "RRW - RIVER ROUGE PLASTIC WRAP";
    ACCESSORIAL_DESCRIPTION["SAH"] = "SAH - STORAGE AND HANDLING";
    ACCESSORIAL_DESCRIPTION["SHU"] = "SHU - SHUTTLE SERVICE";
    ACCESSORIAL_DESCRIPTION["SOC"] = "SOC - STOP OFF CHARGE";
    ACCESSORIAL_DESCRIPTION["SPC"] = "SPC - SPECIAL DELIVERY";
    ACCESSORIAL_DESCRIPTION["SPT"] = "SPT - SPOTTING CHARGE";
    ACCESSORIAL_DESCRIPTION["STL"] = "STL - SHILOH TABLE TRAILER LANSING";
    ACCESSORIAL_DESCRIPTION["STP"] = "STP - SHILOH TABLE TRAILER PONTIAC";
    ACCESSORIAL_DESCRIPTION["SUL"] = "SUL - SELF UNLOADER CHARGE";
    ACCESSORIAL_DESCRIPTION["TBL"] = "TBL - TABLE TRAILER CHARGE";
    ACCESSORIAL_DESCRIPTION["TOL"] = "TOL - TOLLS";
    ACCESSORIAL_DESCRIPTION["TRP"] = "TRP - TARPING CHARGE";
    ACCESSORIAL_DESCRIPTION["VAN"] = "VAN - OUTBOUND VAN SHUTTLE FEE";
    ACCESSORIAL_DESCRIPTION["WKE"] = "WKE - WEEKEND DELIVERY";
    ACCESSORIAL_DESCRIPTION["WRP"] = "WRP - SHRINK/PLASTIC WRAP";
    ACCESSORIAL_DESCRIPTION["TWODD"] = "2DD - TWO DOOR DELIVERY";
    ACCESSORIAL_DESCRIPTION["TWODR"] = "2DR - TWO DOOR PICKUP";
    ACCESSORIAL_DESCRIPTION["TWOPP"] = "2PP - TWO PLANT PICKUP";
    ACCESSORIAL_DESCRIPTION["UNKNOWN"] = "UNKNOWN";
})(ACCESSORIAL_DESCRIPTION = exports.ACCESSORIAL_DESCRIPTION || (exports.ACCESSORIAL_DESCRIPTION = {}));
exports.ACCESSORIAL_DESCRIPTION_CHOICES = (_a = {},
    _a[ACCESSORIAL_DESCRIPTION.BIF] = 'BIF - FUEL SURCHARGE',
    _a[ACCESSORIAL_DESCRIPTION.BIO] = 'BIO - OUT OF ROUTE MILES',
    _a[ACCESSORIAL_DESCRIPTION.BIS] = 'BIS - STOP OFF CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.BOF] = 'BOF - FUEL SURCHARGE',
    _a[ACCESSORIAL_DESCRIPTION.BOO] = 'BOO - OUT OF ROUTE MILES',
    _a[ACCESSORIAL_DESCRIPTION.BRG] = 'BRG - BRIDGE FEE',
    _a[ACCESSORIAL_DESCRIPTION.COD] = 'COD - CASH ON DELIVERY',
    _a[ACCESSORIAL_DESCRIPTION.CSX] = 'CSX - CONTAINER RETURN',
    _a[ACCESSORIAL_DESCRIPTION.DET] = 'DET - DETENTION',
    _a[ACCESSORIAL_DESCRIPTION.ECT] = 'ECT - EAST CHICAGO TARPING/SPOTTING',
    _a[ACCESSORIAL_DESCRIPTION.ECW] = 'ECW - EAST CHICAGO PLASTIC WRAP',
    _a[ACCESSORIAL_DESCRIPTION.ESC] = 'ESC - ESCORT SERVICE',
    _a[ACCESSORIAL_DESCRIPTION.FLU] = 'FLU - FORK LIFT UNLOAD',
    _a[ACCESSORIAL_DESCRIPTION.FUE] = 'FUE - FUEL SURCHARGE',
    _a[ACCESSORIAL_DESCRIPTION.GRD] = 'GRD - GYPSUM RETURN DUNNAGE',
    _a[ACCESSORIAL_DESCRIPTION.GYR] = 'GYR - GYPSUM RETURN MATERIAL',
    _a[ACCESSORIAL_DESCRIPTION.HAZ] = 'HAZ - HAZMAT CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.HND] = 'HND - HAND UNLOADING',
    _a[ACCESSORIAL_DESCRIPTION.HTR] = 'HTR - HEATER SERVICE',
    _a[ACCESSORIAL_DESCRIPTION.INS] = 'INS - INSURANCE SURCHARGE',
    _a[ACCESSORIAL_DESCRIPTION.JOB] = 'JOB - JOB SITE DELIVERY',
    _a[ACCESSORIAL_DESCRIPTION.LAY] = 'LAY - LAYOVER CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.LIC] = 'LIC - SPECIAL LICENSE',
    _a[ACCESSORIAL_DESCRIPTION.LMP] = 'LMP - HAND UNLOAD TRAILER',
    _a[ACCESSORIAL_DESCRIPTION.LOD] = 'LOD - LOADING CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.MIN] = 'MIN - MINIMUM CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.MIS] = 'MIS - MISCELLANEOUS CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.NOL] = 'NOL - NO LOAD',
    _a[ACCESSORIAL_DESCRIPTION.OND] = 'OND - OVER NIGHT DELIVERY',
    _a[ACCESSORIAL_DESCRIPTION.OUT] = 'OUT - OUT OF ROUTE MILES',
    _a[ACCESSORIAL_DESCRIPTION.OVW] = 'OVW - OVERWIDTH CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.PAL] = 'PAL - EMPTY PALLETS',
    _a[ACCESSORIAL_DESCRIPTION.PEC] = 'PEC - PLASTIC EAST CHICAGO',
    _a[ACCESSORIAL_DESCRIPTION.PRE] = 'PRE - PREMIUM CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.PRR] = 'PRR - PLASTIC RIVER ROUGE',
    _a[ACCESSORIAL_DESCRIPTION.PRT] = 'PRT - PERMIT',
    _a[ACCESSORIAL_DESCRIPTION.PTR] = 'PTR - PARTIAL TARP',
    _a[ACCESSORIAL_DESCRIPTION.REB] = 'REB - REBAR',
    _a[ACCESSORIAL_DESCRIPTION.REC] = 'REC - RECONSIGNMENT CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.RED] = 'RED - REDELIVERY CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.RET] = 'RET - RETURN CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.RRT] = 'RRT - RIVER ROUGE TARPING & SPOTTING',
    _a[ACCESSORIAL_DESCRIPTION.RRW] = 'RRW - RIVER ROUGE PLASTIC WRAP',
    _a[ACCESSORIAL_DESCRIPTION.SAH] = 'SAH - STORAGE AND HANDLING',
    _a[ACCESSORIAL_DESCRIPTION.SHU] = 'SHU - SHUTTLE SERVICES',
    _a[ACCESSORIAL_DESCRIPTION.SOC] = 'SOC - STOP OFF CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.SPC] = 'SPC - SPECIAL DELIVERY',
    _a[ACCESSORIAL_DESCRIPTION.SPT] = 'SPT - SPOTTING CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.STL] = 'STL - SHILOH TABLE TRAILER LANSING',
    _a[ACCESSORIAL_DESCRIPTION.STP] = 'STP - SHILOH TABLE TRAILER PONTIAC',
    _a[ACCESSORIAL_DESCRIPTION.SUL] = 'SUL - SELF UNLOADER CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.TBL] = 'TBL - TABLE TRAILER CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.TOL] = 'TOL - TOLLS',
    _a[ACCESSORIAL_DESCRIPTION.TRP] = 'TRP - TARPING CHARGE',
    _a[ACCESSORIAL_DESCRIPTION.VAN] = 'VAN - OUTBOUND VAN SHUTTLE FEE',
    _a[ACCESSORIAL_DESCRIPTION.WKE] = 'WKE - WEEKEND DELIVERY',
    _a[ACCESSORIAL_DESCRIPTION.WRP] = 'WRP - SHRINK/PLASTIC WRAP',
    _a[ACCESSORIAL_DESCRIPTION.TWODD] = '2DD - TWO DOOR DELIVERY',
    _a[ACCESSORIAL_DESCRIPTION.TWODR] = '2DR - TWO DOOR PICKUP',
    _a[ACCESSORIAL_DESCRIPTION.TWOPP] = '2PP - TWO PLANT PICKUP',
    _a[ACCESSORIAL_DESCRIPTION.UNKNOWN] = 'UNKNOWN',
    _a);
