import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import TruckIcon from '../../icons/Truck.svg';
import { DriverDetails } from './types';

type Props = {
  details: DriverDetails;
};
const DriverInfo: React.FC<Props> = props => {
  const { details } = props;
  const classes = useStyles();
  return (
    <div className={classes.driverInfo}>
      {details.photo ? (
        <img className="driver-photo" alt="driver" src={details.photo} />
      ) : (
        <div className="no-photo"></div>
      )}
      <div className="name-container">
        <span className="driver-name">{details.fullName}</span>
      </div>
      <div className="truck-number">
        <img src={TruckIcon} alt="Truck" />
        <span>{details.truckNumber}</span>
      </div>
      <div className="driver-details-container">
        <div className="driver-contact-info">
          <div>
            <div className="info-label">MOBILE</div>
            <div className="info-value">{details.phoneNumber}</div>
          </div>
          <div>
            <div className="info-label">CDL NO.</div>
            <div className="info-value">{details.cdlNumber}</div>
          </div>
          <div>
            <div className="info-label">EMAIL</div>
            <div className="info-value">{details.email}</div>
          </div>
        </div>
        <div className="driver-address">
          <div className="info-label">HOME ADDRESS</div>
          <div className="info-value">{details.address}</div>
        </div>
        <div className={classes.clearfix}></div>
      </div>
      <div className="driver-revenue-container">
        <div className="row header">
          <div></div>
          {/* Disable until computations are updated 
            <div>REVENUE PER WEEK</div> 
          */}
          <div>LOADS PER WEEK</div>
        </div>
        {details.revenue.map((revenueData, index) => {
          return (
            <div key={index} className="row entry">
              <div>{revenueData.week}</div>
              {/* Disable until computations are updated 
                <div>${revenueData.revenue.toFixed(2)}</div>
              */}
              <div>{revenueData.loads}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  driverInfo: {
    width: 450,
    backgroundColor: '#155878',
    position: 'absolute',
    left: 0,
    marginLeft: '75%',
    color: 'white',
    padding: '29px 30px',
    borderRadius: 5,
    'z-index': 10,
    '& img.driver-photo': {
      height: 50,
      width: 50,
      borderRadius: 25,
    },
    '& div.no-photo': {
      height: 50,
      width: 50,
      borderRadius: 25,
      backgroundColor: 'grey',
      display: 'inline-block',
    },
    '& .name-container': {
      display: 'inline-block',
      width: '50%',
      borderRadius: 25,
      fontWeight: 700,
      paddingLeft: 10,
      paddingTop: 6,
      verticalAlign: 'top',

      '& .driver-name': {
        fontSize: 16,
      },
    },
    '& .truck-number': {
      display: 'inline-block',
      position: 'absolute',
      top: 40,
      '& img': {
        height: 26,
      },
      '& span': {
        display: 'inline-block',
        fontSize: 26,
        position: 'relative',
        top: -4,
      },
    },
    '& .driver-details-container': {
      width: '100%',
      paddingTop: 15,
      paddingBottom: 10,
      whiteSpace: 'break-spaces',
      '& .driver-contact-info': {
        display: 'block',
        float: 'left',
        width: '45%',
        marginRight: '5%',
      },
      '& .driver-address': {
        display: 'inline-block',
        float: 'left',
        width: '45%',
        marginRight: '5%',
      },
      '& .info-label': {
        display: 'inline-block',
        width: '30%',
        color: '#C8D9E4',
        fontSize: 10,
        verticalAlign: 'top',
      },
      '& .info-value': {
        display: 'inline-block',
        width: '70%',
        fontWeight: 500,
        fontSize: 12,
      },
    },
    '& .driver-revenue-container': {
      width: '100%',
      paddingTop: 10,
      whiteSpace: 'break-spaces',
      borderTop: '1px solid #FFFFFF',

      '& .row': {
        marginBottom: 5,
        '& div:nth-child(1)': {
          display: 'inline-block',
          width: '20%',
        },
        '& div:nth-child(2)': {
          display: 'inline-block',
          width: '40%',
        },
        '& div:nth-child(3)': {
          display: 'inline-block',
          width: '40%',
        },
        '&.header': {
          fontWeight: 700,
          fontSize: 12,
        },
        '&.entry': {
          fontSize: 12,
          fontWeight: 500,
          '& div:nth-child(1)': {
            color: '#C8D9E4',
          },
        },
      },
    },
  },
  clearfix: {
    clear: 'both',
  },
}));

export default observer(DriverInfo);
