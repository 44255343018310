import {
  Button,
  darken,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { colors } from '../../../themes';

type Props = {
  loading: boolean;
  onConfirm: (description: string) => void;
  onCancel: () => void;
};

const OrderCancellationConfirmation: React.FC<Props> = props => {
  const { loading, onConfirm, onCancel } = props;
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(true);

  const handleConfirm = useCallback(() => {
    onConfirm(description);
  }, [description, onConfirm]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setDescription(value);
    },
    [setDescription],
  );
  return (
    <Dialog
      aria-labelledby="driver-confirm-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          // Set 'open' to false, however you would do that with your particular code.
          setOpen(false);
        }
      }}
      fullWidth
      maxWidth="xs"
      className={classes.dialog}
    >
      <DialogTitle disableTypography className={classes.header}>
        <span className="linked-text"> Confirm Cancellation</span>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.main}>
        <div className="driver-text">
          This action will cancel the load and remove it from the assigned
          driver's schedule. All linked loads and orders will be cancelled.
        </div>

        <ValidatorForm className={classes.form} onSubmit={handleConfirm}>
          <TextValidator
            multiLine
            name="loadDescription"
            className={classes.input}
            disabled={loading}
            label="Description"
            onChange={handleChange}
            size="large"
            value={description}
            variant="outlined"
          />
        </ValidatorForm>

        <div className={classes.actions}>
          <Button
            disabled={loading}
            className={`${classes.actionBtn} ${classes.cancelBtn}`}
            onClick={onCancel}
          >
            Discard
          </Button>
          <Button
            disabled={loading}
            className={`${classes.actionBtn} ${classes.saveBtn}`}
            onClick={handleConfirm}
          >
            Cancel Order
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paperWidthXs': {
      maxWidth: 500,
    },
  },
  header: {
    fontSize: 18,
    color: '#4f4f4f',
    fontWeight: 700,
    textAlign: 'center',
    padding: theme.spacing(1),

    '& .linked-text': {
      textTransform: 'uppercase',
    },
  },
  main: {
    padding: theme.spacing(1, 0, 0),
    alignItems: 'center',

    '& .driver-text': {
      textAlign: 'center',
      fontSize: 16,
      padding: theme.spacing(0.5, 3),
      color: '#4f4f4f',
      fontWeight: 500,
      '& .driver-name': {
        color: '#155878',
      },
    },
    '& .name-container': {
      textAlign: 'center',
      padding: theme.spacing(1, 2),
      fontSize: 16,
      color: '#4f4f4f',
      '& .name-value': {
        fontWeight: 700,
      },
    },
  },

  actions: {
    alignItems: 'center',
    borderTop: '1px solid #bdbdbd',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2),
  },
  actionBtn: {
    borderRadius: 5,
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 6),
  },
  cancelBtn: {
    backgroundColor: colors.danger[500],
    color: 'white',

    '&:hover': {
      backgroundColor: darken(colors.danger[500], 0.15),
    },
  },
  saveBtn: {
    backgroundColor: colors.textPrimary[400],
    color: 'white',

    '&:hover': {
      backgroundColor: darken(colors.textPrimary[400], 0.15),
    },
  },
  input: {
    marginLeft: '45px',
    width: 400,
  },
  radioInput: {
    alignSelf: 'center',
    margin: theme.spacing(0, 2.5, 1.5, 1),
  },
  form: {
    alignItems: 'center',
    alignSelf: 'center',
  },
}));

export default observer(OrderCancellationConfirmation);
