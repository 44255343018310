import {
  Button,
  darken,
  IconButton,
  // IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LOAD_STOP_TYPE } from 'doc-mate-store/lib/constants/load';
// import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { colors } from '../../themes';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import LoadStopFormContainer from './LoadStopFormContainer';
import OrderForm from './OrderForm';
import { OrderFormData } from './types';

type Props = {
  idW: string;
  isLoading: boolean;
  isAllOrderDetailsOpen: boolean;
  load: OrderFormData;
  locations: AutocompleteFieldOption[];
  // loadStops: LoadStopFormData[];
  loadTypes: string[];
  quantityUnits: string[];
  weightUnits: string[];
  distanceUnits: string[];
  onRemoveOrder: (id: string) => void;
  onOrderChange: (id: string, name: string, value: any) => void;
  onLoadStopChange: (
    ordewW: string,
    id: string,
    name: string,
    value: any,
  ) => void;
  onLoadStopAdd: (orderW: string) => void;
  onLoadStopRemove: (orderW: string, id: string) => void;
};

const OrderDetailForm: React.FC<Props> = ({
  idW,
  isLoading,
  isAllOrderDetailsOpen,
  load,
  // loadStops,
  loadTypes,
  quantityUnits,
  weightUnits,
  distanceUnits,
  onRemoveOrder,
  onOrderChange,
  onLoadStopChange,
  onLoadStopAdd,
  onLoadStopRemove,
}) => {
  const classes = useStyles();

  const [orderDetailsOpen, setOrderDetailsOpen] = useState(true);
  const shipperRef = useRef<HTMLDivElement>(null);
  const consigneeRef = useRef<HTMLDivElement>(null);
  const loadStops = useMemo(() => load.stops, [load.stops]);

  const handleAddLoadStop = useCallback(() => {
    onLoadStopAdd(idW);
  }, [idW, onLoadStopAdd]);

  const handleExpandOrderDetails = () => {
    setOrderDetailsOpen(!orderDetailsOpen);
  };

  const handleRemoveOrder = useCallback(() => {
    onRemoveOrder(idW);
  }, [onRemoveOrder, idW]);

  if (!loadStops) {
    return null;
  }
  const midStops = loadStops.filter(stop => !stop.isCustomer);
  const shipper = loadStops.find(
    stop => stop.isCustomer && stop.stopType === LOAD_STOP_TYPE.PICKUP,
  );
  const consignee = loadStops.find(
    stop => stop.isCustomer && stop.stopType === LOAD_STOP_TYPE.DROPOFF,
  );
  return (
    <div className={classes.loadContainer}>
      <div className={classes.loadHeader}>
        <Typography className={classes.loadName} variant="h6">
          Order # {load.orderNumber}
        </Typography>
        {load.orderNumber !== '1' && (
          <IconButton
            aria-label="remove"
            className={classes.removeBtn}
            onClick={handleRemoveOrder}
            size="small"
          >
            <Typography className={classes.removeName} variant="body2">
              Remove
            </Typography>
          </IconButton>
        )}
        <Button
          className={classes.expandButton}
          disabled={isLoading}
          onClick={handleExpandOrderDetails}
        >
          {orderDetailsOpen ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </Button>
      </div>
      <div>
        <OrderForm
          key={idW}
          idW={idW || ''}
          isLoading={isLoading}
          load={load}
          loadTypes={loadTypes}
          quantityUnits={quantityUnits}
          weightUnits={weightUnits}
          distanceUnits={distanceUnits}
          onOrderChange={onOrderChange}
        />
      </div>
      {isAllOrderDetailsOpen && orderDetailsOpen && (
        <div>
          <div className={classes.loadDetailRow}>
            <div className={classes.loadStopSection}>
              <div className={classes.loadStopContent} ref={shipperRef}>
                <LoadStopFormContainer
                  key={`${idW}-${shipper!.idW}`}
                  loadStop={shipper}
                  orderW={idW}
                  isShipper
                  isLoading={isLoading}
                  onLoadStopChange={onLoadStopChange}
                />
              </div>
            </div>
          </div>
          {loadStops.length > 2 &&
            midStops.map(midStop => (
              <div className={classes.loadDetailRow}>
                <div className={classes.loadStopSection}>
                  <div className={classes.loadStopContent} ref={shipperRef}>
                    <LoadStopFormContainer
                      key={`${idW}-${midStop.idW}`}
                      loadStop={midStop}
                      orderW={idW}
                      isLoading={isLoading}
                      onLoadStopChange={onLoadStopChange}
                      onLoadStopRemove={onLoadStopRemove}
                      removable
                    />
                  </div>
                </div>
              </div>
            ))}
          <div className={classes.loadDetailRow}>
            <div className={classes.loadLineLeft} />
            <Button
              className={classes.addLoadStopButton}
              disabled={isLoading}
              onClick={handleAddLoadStop}
            >
              <AddBoxOutlinedIcon className={classes.addIcon} />
              Add Stop
            </Button>
            <div className={classes.loadLineRight} />
          </div>
          <div className={classes.loadDetailRow}>
            <div className={classes.loadStopSection}>
              <div className={classes.loadStopContent} ref={consigneeRef}>
                <LoadStopFormContainer
                  key={`${idW}-${consignee!.idW}`}
                  loadStop={consignee}
                  isConsignee
                  orderW={idW}
                  isLoading={isLoading}
                  onLoadStopChange={onLoadStopChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadContainer: {
    flex: 1,
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  loadHeader: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#fcf0d4',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
  },
  loadName: {
    color: colors.textPrimary[600],
    textTransform: 'uppercase',
    fontWeight: 500,
    flex: 1,
  },
  loadId: {
    color: 'black',
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
  },
  loadDetail: {
    padding: theme.spacing(2, 1, 1),
  },
  loadDetailRow: {
    // paddingTop: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  loadDetailBottomRow: {
    paddingBottom: 8,
  },
  inputStart: {
    margin: theme.spacing(0, 0, 1.5, 1),
    maxWidth: 120,

    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputEnd: {
    margin: theme.spacing(0, 1, 1.5, 0),

    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  input: {
    margin: theme.spacing(0, 1, 1.5),
    minWidth: 110,
  },
  radioInput: {
    margin: theme.spacing(0, 2.5, 1.5, 1),
  },
  inputLabel: {
    color: colors.textPrimary[600],
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
  },
  radioGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
  addDataEntryFieldButton: {
    paddingLeft: theme.spacing(3),
    backgroundColor: 'white',
    borderRadius: 0,
    color: colors.primary[600],
    fontSize: theme.typography.body1.fontSize,
    justifyContent: 'flex-start',

    '&:hover': {
      backgroundColor: darken('#c8d9e4', 0.15),
    },
  },
  addLoadStopButton: {
    backgroundColor: 'white',
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 700,
    justifyContent: 'flex-start',
    textAlign: 'center',
    color: '#4f4f4f',

    '&:hover': {
      backgroundColor: darken('#c8d9e4', 0.15),
    },
  },
  loadLineLeft: {
    width: theme.spacing(3),
    borderTop: '1px solid #bdbdbd',
    height: 0.5,
    position: 'relative',
    top: 20,
  },
  loadLineRight: {
    flex: 1,
    display: 'flex',
    borderTop: '1px solid #bdbdbd',
    height: 0.5,
    position: 'relative',
    top: 20,
  },
  expandButton: {
    // flex: 1,
    borderRadius: 3,
    color: colors.primary[600],
    fontSize: theme.typography.h6.fontSize,
    justifyContent: 'flex-end',
  },
  loadStopSection: {
    // paddingTop: theme.spacing(3),
  },
  loadStopHeader: {
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 3),
  },
  loadStopHeaderTitle: {
    color: colors.textPrimary[600],
    textTransform: 'uppercase',
  },
  loadStopContent: {
    padding: theme.spacing(2, 3, 2),
  },
  removeBtn: {
    color: colors.primary[600],
    marginLeft: theme.spacing(3),
  },
  removeName: {
    textTransform: 'uppercase',
    color: '#4F4F4F',
    textDecorationLine: 'underline',
  },
}));

export default React.memo(OrderDetailForm);
